@use '../../utilities/_variables.scss' as var;

.slide-card {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 29em !important;

  &--social-wall {
    border: 3px solid transparent;
    &:hover, &--active {
      box-shadow: 8px 8px 10px rgba(60, 118, 119, 0.2784313725);
      border: 3px solid var.$color-1;
    }
  }

  &__root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__top-btn-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    margin-bottom: 0.25rem;
  }

  &__img_wrapper{
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__title {
    font-family: var.$font-main;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* color: #333244; */
    margin-bottom: 8px;
    margin-top: 8px;
    word-break: break-word;
    word-wrap: break-word;
  }

  &__text {
    font-family: var.$font-main;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #7d8592;
    margin-bottom: 10px;
  }
}