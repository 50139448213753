// Utilities
@use "./utilities/variables" as var;
@use "./utilities/mixins.scss" as *;
@use "./utilities/suneditor.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

/*@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  src: local('Poppins'), url('../fonts/Poppins-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url('../fonts/Poppins-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  src: local('Poppins'), url('../fonts/Poppins-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  src: local('Poppins'), url('../fonts/Poppins-SemiBold.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 400;
  src: local('Poppins'), url('../fonts/Poppins-Italic.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins-Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  src: local('Poppins-Bold'), url('../fonts/Poppins-Bold.ttf') format('ttf');
}*/
:root {
  --brand-color: #12aeb4;
  --opaque-brand-color: #8fd0d5;
  --opaque-brand-color-light: #a0dbdd;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-image: url("../images/body-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
}

input.form-control,
select.form-control,
input.form-control:focus,
select.form-control:focus {
  background-color: #ebf8ff;
  color: #222;
  border-color: #ebf8ff;
  font-size: 14px;
  line-height: 21px;
  height: 52px;
}
select.form-control {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

.navbar-search-block .form-control-navbar,
.navbar-search-block .form-control-navbar:focus {
  height: 31px;
}

.errormessage,
.errorMessage {
  color: red;
  font-size: 10px;
}

.pointer {
  cursor: pointer;
}

#login .custom-control-label::before,
#register .custom-control-label::before {
  border-color: #00aeb3;
}
.react-datepicker-popper {
  z-index: 1050; 
}

#login .custom-checkbox .custom-control-label::before,
#register .custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

#login .custom-control-input:checked ~ .custom-control-label::before,
#register .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00aeb3;
  background-color: #00aeb3;
}

.btn-info {
  background-color: #00aeb3;
  width: 184px;
  height: 52px;
}

.btn-google {
  border-color: #d6d9dc;
  background-color: #ffffff;
  color: #3b4045;
}

.btn-google:hover,
.btn-google:focus {
  border-color: #babfc4;
  background-color: #f8f9f9;
  color: #232629;
  box-shadow: 0 0 4px rgba(35, 38, 41, 0.1019607843);
}

.btn-facebook {
  border-color: transparent;
  background-color: #385499;
  color: #ffffff;
}

.btn-facebook:hover,
.btn-facebook:focus {
  background-color: #314a86;
  color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 116, 204, 0.1490196078);
}

#banner,
#login,
#register,
#forgot,
#create-password,
#account-verification,
#welcome,
#tour,
#membership,
#multiple-membership,
#user-membership-login,
#user-welcome {
  height: 100vh;
}

#login .card,
#register .card,
#forgot .card,
#create-password .card,
#account-verification .card,
#welcome .card,
#tour .card,
#membership .card,
#multiple-membership .card,
#user-membership-login .card,
#user-welcome .card {
  box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0;
}

#login .card-body {
  padding: 42px 67px;
}

#login .login-title,
#register .register-title {
  color: #172b4d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-bottom: 0px;
}

#login .login-text {
  color: #7a869a;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 12px;
}

#login .login-msg,
#register .register-msg {
  color: #7a869a;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 25px;
}

#login #otp input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 50px;
  height: 52px;
  border-radius: 16px;
  background-color: #fff;
  border-color: #c7c7c7;
  color: #31373e;
  font-size: 23px;
  line-height: 36px;
}

#login #otp input.form-control:focus,
#login #otp input.form-control.active {
  background-color: #fff;
  border-color: #00aeb3;
  box-shadow: none;
}

#login .login-or {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 22px;
  margin-bottom: 20px;
}

#login .login-msg2 {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 17px;
}

#login .login-msg2 .join {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

#login .login-msg3 {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 17px;
}

#login .login-msg3 .resend {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

#login input[type="password"],
#register input[type="password"] {
  border-right: none;
}

#login .input-group-text,
#register .input-group-text,
#forgot .input-group-text {
  background-color: #ebf8ff;
  border-left: none;
  border-color: #ebf8ff;
  text-align: left;
}

#login .form-check .form-check-label,
#register .form-check .form-check-label {
  color: #5a5a5a;
  font-size: 13px;
  line-height: 22px;
}

#login .forgot-password {
  color: #00aeb3;
  font-size: 13px;
  line-height: 22px;
}

#login .custom-control-label,
#register .custom-control-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #5a5a5a;
}

#login .btn-info {
  margin-top: 16px;
  margin-bottom: 13px;
}

#register .card-body {
  padding: 42px 67px 30px;
}

#register .btn-info {
  margin-top: 40px;
  margin-bottom: 35px;
}

#register .footer-text {
  color: #5a5a5a;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 0px;
}

#forgot .card-body {
  padding: 51px 67px 60px;
}

#forgot .forgot-title {
  color: #172b4d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 23px;
}

#forgot .forgot-msg {
  color: #7a7a7a;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 15px;
}

#forgot .btn-info {
  margin-top: 52px;
}

#create-password .card-body {
  padding: 51px 67px 60px;
}

#create-password .create-title {
  color: #172b4d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 23px;
}

#create-password .input-group-text {
  background-color: #ebf8ff;
  border-left: none;
  border-color: #ebf8ff;
}

#create-password .btn-info {
  margin-top: 52px;
}

#account-verification .card-body {
  padding: 51px 67px 60px;
}

#account-verification .verification-title {
  color: #172b4d;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 18px;
}

#account-verification .verification-msg {
  color: #7a7a7a;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 20px;
}

#account-verification .verification-msg a {
  color: #00aeb3;
}

#account-verification input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 50px;
  height: 52px;
  border-radius: 16px;
  background-color: #fff;
  border-color: #c7c7c7;
  color: #31373e;
  font-size: 23px;
  line-height: 36px;
}

#account-verification input.form-control:focus {
  background-color: #fff;
  border-color: #00aeb3;
  box-shadow: none;
}

#account-verification .btn-info {
  margin-top: 51px;
}

#verifiedModal .modal-body {
  padding: 0px 44px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
}

#verifiedModal .modal-content {
  border-radius: 14px;
}

#verifiedModal .modal-body img {
  margin-top: -42px;
  width: 115px;
}

#verifiedModal .title {
  color: #00aeb3;
  font-size: 21px;
  line-height: 41px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 18px;
}

#verifiedModal .text {
  color: #342a2a;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 35px;
}

#welcome .card-body {
  padding: 88px 92px 49px;
}

#welcome .welcome-title {
  color: #172b4d;
  font-size: 26px;
  line-height: 41px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 16px;
}

#welcome .welcome-text {
  color: #7a869a;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#welcome .company-info {
  background-color: #7284cc;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  padding: 11px;
  margin: 8px auto 39px;
}

#welcome .company-info .company-name {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

#welcome .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.87px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

#tour .card-body {
  padding: 30px 45px 49px;
}

#tour .tour-title {
  color: #37474f;
  font-size: 26px;
  line-height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 41px;
  margin-bottom: 34px;
}

#tour .tour-text {
  color: #828386;
  font-size: 17px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 39px;
}

#tour .tour-slider .owl-nav {
  position: absolute;
  top: -30px;
  right: -25px;
}

#tour .tour-slider .owl-nav [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

#tour .tour-slider .owl-nav [class*="owl-"]:hover {
  background: none;
}

#tour .tour-slider .owl-dots .owl-dot.active span {
  background: #8fd0d5;
  width: 20px;
  height: 12px;
}

#membership .card-body {
  padding: 27px 49px 65px 49px;
}

#membership .membership-subtitle {
  color: #192d46;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 21px;
}

#membership .membership-title {
  color: #333244;
  font-size: 24px;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
}

#membership .membership-text {
  color: #707070;
  font-size: 15px;
  line-height: 23px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 23px;
}

#membership .card-body label {
  color: #506278;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#membership .card-body label span {
  color: #bebebe;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#membership .card-body .btn-preview {
  background-color: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  color: #fff;
  height: 57px;
}

#membership .card-body .color-picker {
  width: 65px;
}

#membership .card {
  background-image: url("../images/pen.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right bottom;
}

#preview_img {
  background-color: #ebf7f6;
  height: 194px;
  border-radius: 4px;
}

#choose-file,
#choose-file1 {
  display: none;
  visibility: hidden;
}

.upload-file {
  position: absolute;
  right: 0px;
  top: 10px;
}

/* Dashboard css */
body.sidebar-mini {
  background-image: none;
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-header {
  background-color: #8fd0d5;
  margin-left: 265px;
  padding-left: 35px;
}

.sidebar-mini.sidebar-collapse .main-header {
  margin-left: 2.5rem !important;
}

.main-sidebar .company-info {
  background-color: #7284cc;
  padding: 11px 5px;
  margin: 8px 20px 62px;
  display: flex;
}

.main-sidebar .company-info .company-name {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

.main-sidebar .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.13px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.user-name {
  line-height: 12px;
  color: #fff;
  margin-bottom: 0px;
}

.nav-sidebar .dashboard {
  background-image: url("../images/sidebar/home.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .step {
  background-image: url("../images/sidebar/3step.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .member {
  background-image: url("../images/sidebar/member.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .library {
  background-image: url("../images/sidebar/library.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .content-library {
  background-image: url("../images/sidebar/content-lib.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .photo-library {
  background-image: url("../images/sidebar/photo-lib.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .audio-library {
  background-image: url("../images/sidebar/audio-lib.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .video-library {
  background-image: url("../images/sidebar/video-lib.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .message {
  background-image: url("../images/sidebar/socialwall.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .courses {
  background-image: url("../images/sidebar/courses.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .challenges {
  background-image: url("../images/sidebar/challenges.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .event {
  background-image: url("../images/sidebar/event.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .socialwall {
  background-image: url("../images/sidebar/socialwall.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .membership {
  background-image: url("../images/sidebar/membership.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .email {
  background-image: url("../images/sidebar/email.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .settings {
  background-image: url("../images/sidebar/settings.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .infoportal {
  background-image: url("../images/sidebar/infoportal.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .info-portal {
  background-image: url("../images/sidebar/info-portal.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .support {
  background-image: url("../images/sidebar/support-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
  display: none;
}

.nav-sidebar .logout {
  background-image: url("../images/sidebar/logout.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .dashboard,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .dashboard,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .dashboard,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .dashboard:focus {
  background-image: url("../images/sidebar/home-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .step,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .step,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .step,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .step:focus {
  background-image: url("../images/sidebar/3step-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .member,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .member,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .member,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .member:focus {
  background-image: url("../images/sidebar/member-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .library,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .library,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .library,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .library:focus {
  background-image: url("../images/sidebar/library-hover.svg") !important;
}

[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item.menu-open
  .content-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item:hover
  .content-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-link.active
  .content-library,
[class*="sidebar-dark-"] .nav-sidebar .nav-treeview .nav-item .message:focus {
  background-image: url("../images/sidebar/content-hover.svg") !important;
}

[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item.menu-open
  .photo-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item:hover
  .photo-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-link.active
  .photo-library,
[class*="sidebar-dark-"] .nav-sidebar .nav-treeview .nav-item .message:focus {
  background-image: url("../images/sidebar/photo-hov.svg") !important;
}

[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item.menu-open
  .audio-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item:hover
  .audio-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-link.active
  .audio-library,
[class*="sidebar-dark-"] .nav-sidebar .nav-treeview .nav-item .message:focus {
  background-image: url("../images/sidebar/audio-hover.svg") !important;
}

[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item.menu-open
  .video-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item:hover
  .video-library,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-link.active
  .video-library,
[class*="sidebar-dark-"] .nav-sidebar .nav-treeview .nav-item .message:focus {
  background-image: url("../images/sidebar/video-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .message,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .message,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .message,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .message:focus {
  background-image: url("../images/sidebar/socialwall-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .courses,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .courses,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .courses,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .courses:focus {
  background-image: url("../images/sidebar/courses-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .challenges,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .challenges,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .challenges,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .challenges:focus {
  background-image: url("../images/sidebar/challenges-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .event,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .event,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .event,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .event:focus {
  background-image: url("../images/sidebar/event-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .socialwall,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .socialwall,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .socialwall,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .socialwall:focus {
  background-image: url("../images/sidebar/socialwall-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .membership,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .membership,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .membership,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .membership:focus {
  background-image: url("../images/sidebar/membership-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .email,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .email,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .email,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .email:focus {
  background-image: url("../images/sidebar/email-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .settings,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .settings,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .settings,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .settings:focus {
  background-image: url("../images/sidebar/settings-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .infoportal,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .infoportal,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .infoportal,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .infoportal:focus {
  background-image: url("../images/sidebar/infoportal-hover.svg") !important;
}

[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item.menu-open
  .info-portal,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item:hover
  .info-portal,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-link.active
  .info-portal,
[class*="sidebar-dark-"]
  .nav-sidebar
  .nav-treeview
  .nav-item
  .info-portal:focus {
  background-image: url("../images/sidebar/info-portal-hover.svg") !important;
}

[class*="sidebar-dark-"] .nav-sidebar .nav-item.menu-open .logout,
[class*="sidebar-dark-"] .nav-sidebar .nav-item:hover .logout,
[class*="sidebar-dark-"] .nav-sidebar .nav-link.active .logout,
[class*="sidebar-dark-"] .nav-sidebar .nav-item .logout:focus {
  background-image: url("../images/sidebar/logout-hover.svg") !important;
}

.sidebar-mini.sidebar-collapse .main-sidebar .nav-sidebar .support {
  display: block;
}

.sidebar-mini .main-sidebar .collapse-sidebar {
  width: 95%;
  color: #fff;
  text-align: right;
  font-size: 20px;
}
.notification.dropdown-menu.dropdown-menu-lg.dropdown-menu-right {
  max-width: max-content;
  margin-top: 9px;
}
.notification.dropdown-menu-right {
  right: -10rem !important;
}

.box-header {
  // background-color:var(--opaque-brand-color-light);
  background-color: whitesmoke;
  border-color: rgb(212, 210, 210);
  // border-color:var(--brand-color);
  border-style: solid;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-width: 1px;
  margin: 0;
  padding: 8px 16px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-header .btn-sm {
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
}
.check {
  width: 12px;
  height: 12px;
  border: 1px solid #00aeb3;
  border-radius: 2px;
  background-color: white;
}
.mark-as-read {
  width: 33px;
  height: 27px;
  background: #fff;
  border-radius: 15px;
  color: #00aeb3;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00aeb3;
}
.mark-as-read > svg {
  height: 16px;
  width: 16px;
}
.for-dropdown {
  position: absolute;
  top: 4%;
  right: 1px;
  visibility: hidden;
}
.mark-as-read-for-all {
  position: absolute;
  top: 30%;
  right: 1px;
  transform: translateY(-50%);
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.allnotification-container:hover .mark-as-read-for-all {
  visibility: visible;
}
.allnotification-container:hover .time {
  visibility: hidden;
}
.mark-as-read-for-all .mark-as-read {
  visibility: inherit;
}
.notification-container:hover .for-dropdown {
  visibility: visible;
}
.notification-container:hover .time {
  visibility: hidden;
}
.notification-box {
  display: flex;
  flex-direction: row;
}
.notification-box span {
  font-weight: bold;
  font-size: 1.3em;
}
.notification-box a {
  font-weight: bold;
  font-size: 1em;
}
.notification-container {
  display: flex;
}
.allnotification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.allnotification-content {
  display: flex;
  justify-content: space-between;
}
.notification-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.notification-content span {
  flex: 1 0 50%;
}
.notification-content span:last-child {
  text-align: right;
}

.sidebar-mini.sidebar-collapse .main-sidebar .collapse-sidebar {
  width: 85%;
}

.navbar-expand .navbar-nav .nav-link {
  color: #fff;
}

.navbar-expand .navbar-nav .nav-link i {
  font-size: 25px;
}
.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-container > * {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}
.download-link {
  background-color: #e4feff;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
}

.download-link span {
  color: #7d8592;
  font-size: 15px;
  line-height: 23px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.salespage-download-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0.5rem 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
}
.salespage-download-link span:nth-child(1) {
  font-size: small;
}
.daterange input {
  width: 55%;
  background-image: url("../images/dashboard/Path 177.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 26px;
}

.calendar.card,
.active-users.card {
  box-shadow: 0px 3px 22px #e2e9f2;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}

.events.card,
.activity.card,
.messages.card {
  @include wisdome-shadow;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}

.calendar .day {
  background-color: rgba(18, 174, 180, 0.1568627451);
  color: #12aeb4;
  border-radius: 4px;
  padding: 25px 10px;
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 84px;
  height: 97px;
  margin: 0 10px;
  margin-bottom: 10px;
}

.calendar .day span:first-child {
  font-size: 16px;
  line-height: 25px;
}

.calendar .day span:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
}

.calendar .day.active {
  background-color: #12aeb4;
  color: #fff;
}

.calendar .dots {
  background-color: #a0dbdd;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0px 4px;
  font-size: 6px;
}

.calendar .dots.active {
  background-color: #12aeb4;
}

.events .card-title,
.activity .card-title,
.active-users .card-title,
.calendar .card-title,
.messages .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.events .card-tools,
.activity .card-tools,
.active-users .card-tools {
  color: #12aeb4;
}

.event-odd {
  background-color: #f2feff;
}

.event-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 19px;
}

.event-list p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #707070;
}

.activity-list .media {
  margin-bottom: 24px;
}

.activity-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  color: #333244;
  margin-bottom: 0px;
}

.activity-list p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #707070;
  margin-bottom: 0px;
}

.activity-list .activity-status {
  background-color: #f2feff;
  border-radius: 14px;
  padding: 21px 30px;
}

.activity-list .activity-status p {
  color: #0a1629;
  font-size: 16px;
  line-height: 24px;
}

.messages .search {
  background-color: #fff;
  margin-bottom: 32px;
}

.messages .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #12aeb4;
}

.messages .messages-list {
  max-height: 890px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

.messages-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.messages-list::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.messages-list .media {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
}

.messages-list .media:hover {
  background-color: #f4f9fd;
}

.messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #333244;
}

.messages .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #7d8592;
}

.messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #91929e;
}

.messages .msg-count {
  background-color: #12aeb4;
  border: 2px solid #ffffff;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

/*3steps main page css*/
.steps-mainpage .margin-left {
  margin-left: 6%;
}

.steps-mainpage .welcome-info {
  @include wisdome-shadow;
  border-radius: 6px;
}

.steps-mainpage .welcome-info h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
}

.steps-mainpage .welcome-info p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #000000;
}

.steps-mainpage .welcome-info .company-logo {
  background-color: #8fd0d5;
}

.steps-mainpage .plus-icon,
.steps-mainpage .plus-selected {
  position: absolute;
  right: -15px;
  top: -15px;
}

.steps-mainpage .edit,
.steps-mainpage .engage,
.steps-mainpage .promote {
  @include wisdome-shadow;
  border: 1px solid #fff;
  border-radius: 6px;
  margin-bottom: 24px;
  min-height: 242px;
}

.steps-mainpage .edit:hover,
.steps-mainpage .engage:hover,
.steps-mainpage .promote:hover {
  border: 1px solid #12aeb4;
  border-radius: 6px;
}

.steps-mainpage .edit .plus-selected,
.steps-mainpage .engage .plus-selected,
.steps-mainpage .promote .plus-selected {
  display: none;
}

.steps-mainpage .edit:hover .plus-icon,
.steps-mainpage .engage:hover .plus-icon,
.steps-mainpage .promote:hover .plus-icon {
  display: none;
}

.steps-mainpage .edit:hover .plus-selected,
.steps-mainpage .engage:hover .plus-selected,
.steps-mainpage .promote:hover .plus-selected {
  display: block;
}

.steps-mainpage .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #12aeb4;
  margin-bottom: 11px;
}

.steps-mainpage .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7a869a;
  margin-bottom: 11px;
}

.steps-mainpage .edit-list,
.steps-mainpage .engage-list,
.steps-mainpage .promote-list {
  @include wisdome-shadow;
  background-color: #a0dbdd;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 20px;
}

.steps-mainpage .edit-list .title,
.steps-mainpage .engage-list .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12aeb4;
  margin-bottom: 1px;
}

.steps-mainpage .edit-list .text,
.steps-mainpage .engage-list .text {
  color: #ffffff;
  margin-bottom: 0px;
}

.steps-mainpage .promote-list .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #172b4d;
  margin-bottom: 6px;
}

.steps-mainpage .promote-list .link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #12aeb4;
}

.steps-mainpage .promote-list .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #fff;
}

.promote-list .text ol {
  list-style-position: outside;
  padding-left: 12px;
  padding-top: 20px;
}

.steps-mainpage .promote-list:last-child {
  padding: 17px 20px 7px 34px;
}

.steps-mainpage .edit-list:hover,
.steps-mainpage .engage-list:hover,
.steps-mainpage .promote-list:hover {
  background-color: #333244;
}

.steps-mainpage .edit-list:hover .title,
.steps-mainpage .engage-list:hover .title,
.steps-mainpage .promote-list:hover .title,
.steps-mainpage .edit-list:hover .text,
.steps-mainpage .engage-list:hover .text,
.steps-mainpage .promote-list:hover .text,
.steps-mainpage .promote-list:hover .link {
  color: #fff !important;
}

/* Admin create membership level modal */
#createMembershipLevelModal .modal-content,
#membershipBillingOptionsModal .modal-content,
#renewalAndSuceessModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
}

#createMembershipLevelModal .modal-body,
#membershipBillingOptionsModal .modal-body {
  padding: 39px 39px 42px 42px;
}

#createMembershipLevelModal .modal-body .go-back,
#membershipBillingOptionsModal .modal-body .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
  margin-bottom: 22px;
  cursor: pointer;
}

#createMembershipLevelModal .form-group,
#membershipBillingOptionsModal .form-group {
  margin-bottom: 28px;
}

#createMembershipLevelModal .form-control,
#membershipBillingOptionsModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #3c3d43;
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  border-radius: 4px;
  padding-left: 35px;
}

#createMembershipLevelModal input.form-control,
#membershipBillingOptionsModal input.form-control {
  height: 58px;
}

#createMembershipLevelModal .upload-file {
  position: absolute;
  right: 32px;
  top: auto;
  margin-top: 25px;
}

#createMembershipLevelModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 53px;
}

#createMembershipLevelModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#createMembershipLevelModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bebebe;
}

#createMembershipLevelModal .access {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  border-radius: 4px;
  padding: 8px 35px 40px;
}

#createMembershipLevelModal .access-level {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 10px;
}

#createMembershipLevelModal .btn-custom,
#membershipBillingOptionsModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 425px;
  height: 57px;
  margin-top: 76px;
}

/* admin membership level list */
#createMembershipLevelModal .subscription-info {
  background: #ffffff;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1411764706);
  border: 1px solid #12aeb4;
  border-radius: 15px;
}

#createMembershipLevelModal .subscription-info .thumbnail-img {
  width: 96px;
  /* height: 127px; */
}

.member-action-close {
  position: absolute;
  right: 2%;
}

.member-action-close img {
  width: 1.7em;
  height: 1.7em;
}

.custom-member-checkbox {
  width: 1.2em;
  height: 1.2em;
}

.custom-member-checkbox:checked {
  accent-color: rgba(151, 32, 230, 0.993);
}

#createMembershipLevelModal .subscription-info .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  margin-bottom: 0px;
}

#createMembershipLevelModal .subscription-info .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #060136;
}

/* member billing */
#membershipBillingOptionsModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 11px;
}

#membershipBillingOptionsModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 0px;
}

#membershipBillingOptionsModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 0px;
  word-break: break-word;
  word-wrap: break-word;
}

#membershipBillingOptionsModal .frequency-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  background: #ebf7f6;
  border-radius: 4px;
  padding: 12px 20px 20px 20px;
  text-align: center;
}

#membershipBillingOptionsModal .frequency-btn:hover,
#membershipBillingOptionsModal .frequency-btn.active {
  color: #ffffff;
  background: #12aeb4;
  cursor: pointer;
}

#membershipBillingOptionsModal .price-info,
#createMembershipLevelModal .hover-info {
  position: relative;
  display: inline-block;
}

#membershipBillingOptionsModal .price-info .info-icon,
#createMembershipLevelModal .hover-info .info-icon {
  cursor: pointer;
}

#membershipBillingOptionsModal .info-hover,
#createMembershipLevelModal .info-hover {
  visibility: hidden;
  position: absolute;
  top: 35%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #c9c9c9;
  background-color: rgba(0, 0, 0, 0.7215686275);
  border-radius: 25px;
  padding: 29px 11px 23px 17px;
  width: 474px;
  text-align: justify;
}

#membershipBillingOptionsModal .price-info:hover .info-hover,
#createMembershipLevelModal .hover-info:hover .info-hover {
  visibility: visible;
  z-index: 2;
}

#membershipBillingOptionsModal input.price-input {
  padding-left: 12px;
  width: 100px;
}

#membershipBillingOptionsModal .price-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin-right: 8px;
  margin-bottom: 0px;
}

/* renewal and success modal */
#renewalAndSuceessModal .modal-header {
  padding: 39px 39px 0px 48px;
}

#renewalAndSuceessModal .modal-header .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
  cursor: pointer;
}

#renewalAndSuceessModal .modal-body {
  padding: 12px 39px 42px 48px;
}

#renewalAndSuceessModal .nav-pills .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3c3d43;
  background-color: #ebf7f6;
  border-radius: 4px;
  height: 58px;
  padding: 16px;
}

#renewalAndSuceessModal .nav-pills .nav-link.active {
  color: #fff;
  background-color: #12aeb4;
}

#renewalAndSuceessModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 0px;
}

#renewalAndSuceessModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 17px;
}

#renewalAndSuceessModal .form-group {
  margin-bottom: 30px;
}

#renewalAndSuceessModal textarea.form-control:focus {
  height: auto;
}

#renewalAndSuceessModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#renewalAndSuceessModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bebebe;
}

#renewalAndSuceessModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #342e2e;
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  border-radius: 4px;
  padding-left: 35px;
}

#renewalAndSuceessModal #success-messages select {
  appearance: none;
}

#renewalAndSuceessModal .dropdown-img {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-bottom: 6px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info .info {
  visibility: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  color: #024f6c;
  background: rgba(160, 219, 221, 0.4588235294);
  border: 1px solid #12aeb4;
  border-radius: 8px;
  padding: 5px;
  width: 230px;
  position: absolute;
  bottom: 0px;
  right: 16px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info:hover .info {
  visibility: visible;
}

#renewalAndSuceessModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 425px;
  height: 57px;
}

/* popup message modal */
#popupMessageModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
}

#popupMessageModal .modal-body {
  padding: 61px 46px 51px 46px;
}

#popupMessageModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 65px;
}

#popupMessageModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  border: 1px solid #d8d8d8;
  border-radius: 30px;
  width: 320px;
  height: 85px;
}

/* reconfirm modal */
#reConfirmModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
}

#reConfirmModal .modal-body {
  padding: 26px 70px 51px 70px;
  text-align: center;
}

#reConfirmModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #12aeb4;
  margin-bottom: 18px;
}

#reConfirmModal .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #655a5a;
  margin-bottom: 18px;
}

#reConfirmModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 42px;
}

#reConfirmModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #bebebe;
  border-radius: 9px;
  width: 362px;
  height: 57px;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 10px;
}

#reConfirmModal .btn-custom:hover,
#reConfirmModal .btn-custom:focus,
#reConfirmModal .btn-custom:active {
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
}

/* setup guide modal */
#setupGuide .modal-content,
#stepsSetup .modal-content {
  border: 1px solid #d8d8d8;
  border-radius: 31px;
}

#setupGuide p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#setupGuide .btn-setup {
  background: #12aeb4;
  border: 1px solid #d8d8d8;
  color: #fff;
  padding: 29px;
  border-radius: 25px;
  width: 320px;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 16px;
}

#stepsSetup .company-logo {
  background-color: #8fd0d5;
  margin: 0 38% 0 auto;
  padding: 10px;
}

#stepsSetup .step-count {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
}

#stepsSetup .step-count a {
  padding: 10px;
  border-radius: 50%;
  color: #12aeb4;
  background-color: #c7ebeb;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 40px;
  height: 40px;
}

#stepsSetup .step-count a.active {
  background-color: #12aeb4;
  border: 3px solid #c7ebeb;
  color: #fff;
  padding: 4px;
}

#stepsSetup .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 15px;
}

#stepsSetup .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 2px;
}

#stepsSetup .title1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 0px;
}

#stepsSetup .text1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #3c3d43;
  margin-bottom: 10px;
}

#stepsSetup form {
  padding: 0px 36px;
}

#stepsSetup form label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

#stepsSetup form .form-group {
  margin-bottom: 30px;
}

#stepsSetup form .form-group .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2f3332;
  padding-left: 35px;
}

#stepsSetup form .form-group .input-group-text {
  color: #12aeb4;
}

#stepsSetup form .form-check-label a {
  color: #12aeb4;
}

#stepsSetup form .btn-custom {
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
}

.choose-subs-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.choose-subs-div img:nth-child(1) {
  position: absolute;
  left: 0;
  top: -4em;
  background-color: white !important;
  width: 12em !important;
  height: 12em !important;
}

.choose-subs-div p {
  font-size: 12px !important;
  text-align: center;
  font-weight: 200 !important;
  color: #12aeb4;
  width: 80%;
  margin: 0 auto;
}

#stepsSetup .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00aeb3;
  cursor: pointer;
}

#stepsSetup .go-back img {
  width: 2em;
  height: auto;
  margin-right: 0.7em;
}

#stepsSetup .uplaod-picture label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #aaaaaa;
  margin-top: 19px;
  cursor: pointer;
}

#stepsSetup .subscription img {
  width: 205px;
  background: #c7ebeb;
  border-radius: 15px;
  padding: 29px;
}

#stepsSetup .subscription .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 31px;
  color: #192d46;
  margin-bottom: 12px;
}

#stepsSetup .subscription .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #054143;
}

#stepsSetup .plan-info {
  background: #ffffff;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1411764706);
  border: 1px solid #12aeb4;
  border-radius: 15px;
}

#stepsSetup .plan-info.active {
  background: #ffffff;
  box-shadow: 0px 3px 29px rgba(18, 174, 180, 0.2117647059);
  border: 1px solid #dedede;
  border-radius: 15px;
}

#stepsSetup .plan-info.selected,
#stepsSetup .plan-info:hover {
  border: 1px solid #0d7174;
}

#stepsSetup .plan-info .card-body {
  padding: 30px;
}

#stepsSetup .plan-info .plan-name span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
}

#stepsSetup .plan-info.active .plan-name span {
  color: #000000;
}

#stepsSetup .plan-info.selected .plan-name span,
#stepsSetup .plan-info.selected .plan-type span,
#stepsSetup .plan-info:hover .plan-name span,
#stepsSetup .plan-info:hover .plan-type span {
  color: #0d7174;
}

#stepsSetup .plan-info .plan-name span:last-child {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  color: #054143;
  background: #c7ebeb;
  border-radius: 15px;
  padding: 6px 29px;
}

#stepsSetup .plan-info .plan-type {
  text-align: right;
}

#stepsSetup .plan-info .plan-type span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
}

#stepsSetup .plan-info .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2d3142;
  margin-bottom: 15px;
}

#stepsSetup .plan-info.active .text,
#stepsSetup .plan-info.selected .text,
#stepsSetup .plan-info:hover .text {
  color: #5a5a5a;
}

#stepsSetup .plan-info .btn-custom {
  background: #12aeb4;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
  width: 330px;
  height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #ffffff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#stepsSetup .plan-info .btn-outline-custom {
  background: transparent;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border: 1px solid #12aeb4;
  border-radius: 9px;
  width: 330px;
  height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #12aeb4;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#stepsSetup .plan-info.selected .btn-custom,
#stepsSetup .plan-info.selected .btn-outline-custom,
#stepsSetup .plan-info:hover .btn-custom,
#stepsSetup .plan-info:hover .btn-outline-custom {
  background: #0d7174;
  color: #fff;
}

#stepsSetup .modal-dialog-scrollable .modal-body {
  height: 890px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

#stepsSetup .payment .title {
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#stepsSetup .payment .text {
  font-size: 18px;
  line-height: 27px;
  color: #00aeb3;
}

#paymentSuccessfull .modal-content,
#landingPage .modal-content {
  border: 1px solid #d8d8d8;
  border-radius: 15px;
}

#paymentSuccessfull .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0c0a08;
  margin-bottom: 24px;
}

#paymentSuccessfull .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #a6a7ae;
  margin-bottom: 93px;
}

#landingPage .modal-content {
  box-shadow: 0px 3px 24px #2d3142;
  border: 1px solid #e8e8e8;
}

#landingPage .title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55px;
  color: #172b4d;
  margin-bottom: 62px;
}

#landingPage .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-top: 68px;
  margin-bottom: 49px;
}

#landingPage .sub-title2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 49px;
}

#landingPage .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #2d3142;
  margin-bottom: 34px;
}

#landingPage .btn-custom {
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
}

#multiple-membership .card-body {
  padding: 38px;
}

#multiple-membership .multiple-membership-logo {
  margin-bottom: 31px;
}

#multiple-membership .multiple-membership-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172b4d;
  margin-bottom: 8.59px;
}

#multiple-membership .multiple-membership-msg {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7a869a;
  margin-bottom: 17.41px;
}

#multiple-membership .company-info,
#user-membership-login .company-info,
#user-welcome .company-info {
  border: 1px solid #e3e3e3;
  border-radius: 35px;
  padding: 15px 31px;
  margin: 8px auto 20px;
  height: 128px;
}

.membership-box-color {
  background-color: #7284cc !important;
}

.membership-box-color2 {
  background-color: #f2a32d !important;
}

#multiple-membership .company-info .company-name,
#user-membership-login .company-info .company-name,
#user-welcome .company-info .company-name {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

#multiple-membership .company-info .company-name small,
#user-membership-login .company-info .company-name small,
#user-welcome .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.31px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

#multiple-membership .custom-control-label::before {
  width: 35px;
  height: 35px;
  top: 0;
  background-color: #e3e3e3;
  border: 1px solid #fcfcfc;
}

#multiple-membership .custom-control-label::after {
  width: 35px;
  height: 35px;
  top: 0;
}

#multiple-membership
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232f449a' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

#multiple-membership .btn-info {
  margin-top: 46px;
  margin-bottom: 30px;
}

#user-membership-login .card-body,
#user-welcome .card-body {
  padding: 50px 38px;
}

#user-membership-login .user-membership-login-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172b4d;
  margin-bottom: 22.5px;
}

#user-membership-login .user-membership-login-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7a869a;
  margin-bottom: 17.41px;
}

#user-membership-login .input-group {
  margin-bottom: 22px;
}

#user-membership-login .input-group.input2 {
  margin-bottom: 27px;
}

#user-membership-login input[type="text"] {
  background-color: #fff;
  border: 1px solid #2f4a92;
  border-left: 0;
  height: 46px;
}

#user-membership-login input[type="password"] {
  background-color: #fff;
  border: 1px solid #2f4a92;
  border-radius: 0px;
  border-left: 0;
  border-right: 0;
  height: 46px;
}

#user-membership-login .input-group-prepend .input-group-text {
  background-color: #fff;
  border-right: none;
  border-color: #2f4a92;
}

#user-membership-login .input-group-append .input-group-text {
  background-color: #fff;
  border-left: none;
  border-color: #2f4a92;
}

#user-membership-login .custom-control-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2f4a92;
  padding-top: 0.25rem;
}

#user-membership-login .forgot-password {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #232323;
}

#user-membership-login .btn-info {
  background-color: #2f4a92;
  border-radius: 5px;
  width: 184px;
  height: 52px;
  margin-top: 40px;
  margin-bottom: 30px;
}

#user-welcome .user-welcome-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172b4d;
  margin-top: 52.98px;
  margin-bottom: 48.59px;
}

#user-welcome .user-welcome-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7a869a;
  margin-bottom: 17.41px;
}

#user-welcome .user-welcome-text.p2 {
  margin-bottom: 50px;
}

#user-welcome .btn-info {
  background-color: #2f4a92;
  border-radius: 5px;
  width: 184px;
  height: 52px;
  margin-top: 102.41px;
  margin-bottom: 30px;
}

/*Admin audio library page css start*/
.admin-audio-library.content-wrapper,
.admin-video-library.content-wrapper {
  background-color: #fff;
}

.admin-audio-library .title,
.admin-video-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-audio-library .title a,
.admin-video-library .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.admin-audio-library .playlist .title,
.admin-video-library .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.admin-audio-library .playlist .name,
.admin-video-library .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.admin-audio-library .playlist .left-img,
.admin-video-library .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.admin-audio-library .playlist .right-img,
.admin-video-library .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.admin-audio-library .audio-folders,
.admin-video-library .video-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-audio-library .audio-folders .title,
.admin-video-library .video-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-audio-library .audio-folders label,
.admin-video-library .video-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-audio-library .audio-folders .form-control,
.admin-video-library .video-folders .form-control {
  width: 220px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #ebf7f6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-audio-library .audio-folders .react-datepicker-wrapper,
.admin-video-library .video-folders .react-datepicker-wrapper,
.admin-content-library .content-folders .react-datepicker-wrapper {
  width: auto !important;
}

.react-datepicker__input-container input:hover {
  cursor: pointer;
}

.admin-audio-library .audio-folders .btn-custom,
.admin-video-library .video-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  background: #c7ebeb;
  border-radius: 6px;
  width: 150px;
  height: 42px;
}

.admin-audio-library .audio-folders .btn-custom:hover,
.admin-video-library .video-folders .btn-custom:hover {
  background-color: #12aeb4;
  color: #fff;
}

.admin-audio-library .audio-folders .audio-folders-slider,
.admin-video-library .video-folders .video-folders-slider {
  margin-top: 40px;
  margin-bottom: 30px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-stage-outer,
.admin-video-library .video-folders .video-folders-slider .owl-stage-outer {
  padding: 30px 0px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-stage,
.admin-video-library .video-folders .video-folders-slider .owl-stage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.admin-audio-library .audio-folders .audio-folders-slider .item,
.admin-video-library .video-folders .video-folders-slider .item,
.admin-video-library .video-folders .inside-video-folder .item {
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  cursor: pointer;
  height: 100%;
}

.admin-audio-library .audio-folders .audio-folders-slider .item:hover,
.admin-video-library .video-folders .video-folders-slider .item:hover {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1803921569);
  /* border-radius: 7px; */
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  transform: scale(1.05);
}

.admin-audio-library
  .audio-folders
  .audio-folders-slider
  .owl-nav
  [class*="owl-"],
.admin-video-library
  .video-folders
  .video-folders-slider
  .owl-nav
  [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-prev,
.admin-video-library .video-folders .video-folders-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-next,
.admin-video-library .video-folders .video-folders-slider .owl-next {
  position: absolute;
  top: 130px;
  right: 0px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-prev img,
.admin-video-library .video-folders .video-folders-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-next img,
.admin-video-library .video-folders .video-folders-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-audio-library .audio-folders .audio-folders-slider .img-over,
.admin-video-library .video-folders .video-folders-slider .img-over,
.admin-video-library .video-folders .inside-video-folder .img-over {
  margin-bottom: 21px;
  border-radius: 13px 13px 0px 0px;
}

.admin-audio-library .audio-folders .audio-folders-slider .arrows-img,
.admin-video-library .video-folders .video-folders-slider .arrows-img {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  color: #fff;
}

.admin-video-library .video-folders .video-folders-slider .drag-img {
  position: absolute;
  bottom: -18px;
  right: 0px;
  width: 40px;
  color: #fff;
}

.admin-audio-library .audio-folders .audio-folders-slider .slide-anchor,
.admin-video-library .video-folders .video-folders-slider .slide-anchor {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  padding-left: 20px;
}

.admin-audio-library .audio-folders .audio-folders-slider .slide-title,
.admin-video-library .video-folders .video-folders-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
  padding-right: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.admin-audio-library .audio-folders .audio-folders-slider .slide-text,
.admin-video-library .video-folders .video-folders-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  padding-left: 20px;
}

.admin-audio-library .audio-list #audioLibraryTab,
.admin-video-library .video-list #videoLibraryTab {
  border: none;
  align-items: center;
}

.admin-audio-library .audio-list .nav-item,
.admin-video-library .video-list .nav-item {
  width: 196px;
  margin-right: 35px;
}

.admin-audio-library .audio-list .nav-link,
.admin-video-library .video-list .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8d9296;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-audio-library .audio-list .nav-link.active,
.admin-video-library .video-list .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #12aeb4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12aeb4;
}

.admin-audio-library .audio-list .search-library,
.admin-video-library .video-list .search-library {
  position: relative;
}

.admin-audio-library .audio-list .search-library input,
.admin-video-library .video-list .search-library input {
  @include wisdome-shadow;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.admin-audio-library .audio-list .search-icon,
.admin-video-library .video-list .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.admin-audio-library .audio-list .search-icon .fa-search,
.admin-video-library .video-list .search-icon .fa-search {
  font-size: 39px;
  color: #7e828f;
}

.admin-audio-library .audio-list #audioLibraryTabContent,
.admin-video-library .video-list #videoLibraryTabContent {
  @include wisdome-shadow;
  background: #ffffff;
  border-radius: 4px;
  padding: 8px 22px 4px 48px;
  margin-top: 45px;
  margin-bottom: 100px;
}

.admin-audio-library
  .audio-list
  #audioLibraryTabContent
  .table
  tr:first-child
  td,
.admin-video-library
  .video-list
  #videoLibraryTabContent
  .table
  tr:first-child
  td {
  border-top: none;
}

.admin-audio-library .audio-list #audioLibraryTabContent .table td,
.admin-video-library .video-list #videoLibraryTabContent .table td {
  vertical-align: baseline;
  border-top: 1px solid #cccfcf;
}

.admin-audio-library .audio-list #audioLibraryTabContent .table-title,
.admin-video-library .video-list #videoLibraryTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
  word-break: break-word;
  word-wrap: break-word;
}

.admin-audio-library .audio-list #audioLibraryTabContent .views,
.admin-video-library .video-list #videoLibraryTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-video-library .video-list .after-search {
  margin-top: 20px;
}

.admin-video-library .video-list .after-search .sort-by select {
  width: 60%;
  display: inline-block;
  margin-left: 10px;
}

.admin-video-library .video-list .after-search .video-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-video-library .video-list #videoLibraryTabContent .video-banner {
  border: 1px solid #707070;
  width: 124px;
  height: 82px;
  padding: 10px 0px;
  position: relative;
}

.admin-video-library
  .video-list
  #videoLibraryTabContent
  .video-banner
  .video-reel {
  position: absolute;
  right: -40px;
}

.admin-video-library .video-folders .inside-video-folder {
  margin-bottom: 40px;
}

.admin-video-library .video-folders .inside-video-folder .arrows-img {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 40px;
  color: #fff;
}

.admin-video-library .video-folders .inside-video-folder .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
}

.admin-video-library .video-folders .inside-video-folder .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
  padding-left: 20px;
}

.admin-video-library .video-folders .video-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  color: #060136;
  margin-bottom: 10px;
}

/* Admin audio select folder and sub folder */
#selectFolderModal .modal-content,
#selectAudioSubFolderModal .modal-content,
#selectVideoSubFolderModal .modal-content,
#insideFolderModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#selectFolderModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#selectFolderModal .select-icon {
  padding: 60px 0px;
}

#selectFolderModal .folder .fa-folder {
  padding: 56.5px 68px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12aeb4;
  background-color: #c7ebeb;
}

#selectFolderModal .audio .fa-microphone {
  padding: 56.5px 77.38px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12aeb4;
  background-color: #c7ebeb;
}

#selectFolderModal .folder .fa-folder:hover,
#selectFolderModal .audio .fa-microphone:hover,
#selectFolderModal .folder .fa-folder:active,
#selectFolderModal .audio .fa-microphone:active {
  background-color: #12aeb4;
  color: #fff;
}

#selectFolderModal .icon-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #a6a7ae;
  margin-bottom: 0px;
}

#selectFolderModal .select-folder,
#selectAudioSubFolderModal .folder,
#selectVideoSubFolderModal .folder,
#insideFolderModal .folder {
  padding-left: 18px;
  padding-right: 10px;
}

#selectFolderModal .select-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3c3d43;
  margin-bottom: 23px;
  margin-left: 26px;
}

#selectFolderModal .select-folder .scroll,
#selectAudioSubFolderModal .select-folder .scroll,
#selectVideoSubFolderModal .select-folder .scroll,
#insideFolderModal .select-folder .scroll {
  max-height: 620px;
  overflow-y: scroll;
  border-right: 2px solid #d8d8d8;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar,
#insideFolderModal .folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar-thumb,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar-thumb,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar-thumb,
#insideFolderModal .folder .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar-track,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar-track,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar-track,
#insideFolderModal .folder .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#selectFolderModal .select-folder .card,
#selectAudioSubFolderModal .folder .card,
#selectVideoSubFolderModal .folder .card,
#insideFolderModal .folder .card {
  background: #f2ffff;
  border-radius: 9px;
  margin: 15px;
}

#selectFolderModal .select-folder .card:hover,
#selectAudioSubFolderModal .folder .card:hover,
#selectVideoSubFolderModal .folder .card:hover,
#insideFolderModal .folder .card:hover,
#selectFolderModal .select-folder .card.active,
#selectAudioSubFolderModal .folder .card.active,
#selectVideoSubFolderModal .folder .card.active,
#insideFolderModal .folder .card.active {
  background: #ffffff;
  box-shadow: 5px 5px 18px rgba(41, 168, 174, 0.5411764706);
}

#selectFolderModal .select-folder .card .card-body,
#selectAudioSubFolderModal .folder .card .card-body,
#selectVideoSubFolderModal .folder .card .card-body,
#insideFolderModal .folder .card .card-body {
  padding: 22px 20px;
}

#selectFolderModal .select-folder .audio-img,
#selectAudioSubFolderModal .folder .audio-img,
#selectVideoSubFolderModal .folder .video-img,
#insideFolderModal .folder .audio-img {
  width: 149px;
  height: 124px;
}

#selectFolderModal .select-folder .card .card-title,
#selectAudioSubFolderModal .folder .card .card-title,
#selectVideoSubFolderModal .folder .card .card-title,
#insideFolderModal .folder .card .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  word-break: break-word;
  overflow-wrap: break-word;
}

#selectFolderModal .select-folder .card .card-text,
#selectAudioSubFolderModal .folder .card .card-text,
#selectVideoSubFolderModal .folder .card .card-text,
#insideFolderModal .folder .card .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929aa5;
  word-break: break-word;
  overflow-wrap: break-word;
}

#selectFolderModal .select-folder .card .card-body .page-info,
#selectAudioSubFolderModal .folder .card .card-body .page-info,
#selectVideoSubFolderModal .folder .card .card-body .page-info,
#insideFolderModal .folder .card .card-body .page-info {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #00aeb3;
}

#selectFolderModal .select-folder .btn-custom,
#selectAudioSubFolderModal .folder .btn-custom,
#selectVideoSubFolderModal .folder .btn-custom,
#insideFolderModal .folder .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 32px;
  margin-bottom: 51px;
}

/* select sub folder and aduio inside modal */
#selectAudioSubFolderModal .go-back,
#selectVideoSubFolderModal .go-back,
#insideFolderModal .go-back,
#addLessonDetailsModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
}

#selectAudioSubFolderModal .folder .title,
#selectVideoSubFolderModal .folder .title,
#insideFolderModal .folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3c3d43;
  margin-left: 26px;
  margin-top: 22px;
  margin-bottom: 0px;
}

#selectAudioSubFolderModal .folder .scroll,
#selectVideoSubFolderModal .folder .scroll,
#insideFolderModal .folder .scroll {
  max-height: 210px;
  overflow-y: scroll;
  border-right: 2px solid #d8d8d8;
}

#insideFolderModal .folder .scroll {
  padding: 10px;
  max-height: 516px;
}

#insideFolderModal .folder .uploaded-file {
  padding: 15px 0px;
  margin-left: 0px;
  margin-right: 0px;
  border-bottom: 1px solid #d8d8d8;
}

#insideFolderModal .folder .uploaded-file .file-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#insideFolderModal .folder .uploaded-file .file-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929aa5;
  margin-bottom: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
}

/*Audio preview modal*/
#audioPreviewModal .modal-content,
#videoPreviewModal .modal-content {
  box-shadow: 0px 3px 24px #d7f1f2;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}

#audioPreviewModal .modal-title,
#videoPreviewModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-right: 100px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .audio-title,
#videoPreviewModal .video-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #060136;
  text-align: center;
  margin-bottom: 32px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .chat-like,
#videoPreviewModal .chat-like {
  padding: 0 80px;
  margin-top: 15px;
  margin-bottom: 45px;
}

#audioPreviewModal .file-name,
#videoPreviewModal .file-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  text-align: center;
  margin-bottom: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .player-description,
#videoPreviewModal .player-description {
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .cursor,
#videoPreviewModal .cursor {
  position: relative;
  border: 1px solid #e8e8e8;
  margin-bottom: 21px;
}

#audioPreviewModal .cursor .cursor-img,
#videoPreviewModal .cursor .cursor-img {
  position: absolute;
  top: -12px;
  right: 50px;
}

#audioPreviewModal .timing span,
#videoPreviewModal .timing span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7e7d88;
}

#audioPreviewModal .controls,
#videoPreviewModal .controls {
  padding: 0 80px;
  margin-top: 36px;
  margin-bottom: 44px;
}

#audioPreviewModal .event-description figure > img {
  height: 100% !important;
  width: 99% !important;
  object-fit: fill !important;
}

#audioPreviewModal .comments .leave-comment,
#videoPreviewModal .comments .leave-comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* color: #8F92A1; */
  background-color: #fff;
  box-shadow: 0px -3px 6px rgba(63, 83, 165, 0.1568627451);
  border: 1px solid #d8e0f0;
  border-radius: 16px;
  height: 38px;
}

#audioPreviewModal .comments .comment-img {
  cursor: pointer;
}

#audioPreviewModal .comments .title,
#videoPreviewModal .comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12aeb4;
}

#audioPreviewModal .messages-list,
#videoPreviewModal .messages-list {
  max-height: 340px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

#audioPreviewModal .messages-list .media,
#videoPreviewModal .messages-list .media {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
}

#audioPreviewModal .messages-list .media:hover,
#videoPreviewModal .messages-list .media:hover {
  background-color: #f4f9fd;
}

#audioPreviewModal .messages-list .user-img,
#videoPreviewModal .messages-list .user-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

#audioPreviewModal .messages-list .messanger-name,
#videoPreviewModal .messages-list .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 5px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .messages-list .msg-time,
#videoPreviewModal .messages-list .msg-time {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

#audioPreviewModal .messages-list .messanger-text,
#videoPreviewModal .messages-list .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* color: #91929E; */
  margin-bottom: 5px;
  word-break: break-word;
  overflow-wrap: break-word;
}

#audioPreviewModal .messages-list .msg-count,
#videoPreviewModal .messages-list .msg-count {
  background-color: #12aeb4;
  border: 2px solid #ffffff;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

#audioPreviewModal .messages-list .link,
#videoPreviewModal .messages-list .link {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #12aeb4;
  text-decoration: none;
  float: right;
}

/*Admin Audio modals  */
#wantToCreateModal .modal-content,
#createFolderModal .modal-content,
#addVideoDetailsModal .modal-content,
#addAudioDetailsModal .modal-content,
#addLessonDetailsModal .modal-content,
#addAudioModal .modal-content,
#addVideoModal .modal-content,
#createSubFolderModal .modal-content,
#uploadAudioModal .modal-content,
#uploadVideoModal .modal-content,
#uploadLessonModal .modal-content,
#selectSubFolderModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#wantToCreateModal .modal-title,
#createFolderModal .modal-title,
#addVideoDetailsModal .modal-title,
#addAudioDetailsModal .modal-title,
#addAudioModal .modal-title,
#addVideoModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  word-break: break-word;
  word-wrap: break-word;
}

#addLessonDetailsModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  padding-left: 40px;
}

#wantToCreateModal .modal-body,
#addAudioModal .modal-body,
#addVideoModal .modal-body {
  padding: 0px 0px;
}

#wantToCreateModal .folder .fa-folder,
#wantToCreateModal .folder .fa-file-alt,
#wantToCreateModal .folder .fa-book,
#addAudioModal .folder .fa-folder,
#addVideoModal .folder .fa-folder {
  padding: 56.5px 68px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12aeb4;
  background-color: #c7ebeb;
}

#wantToCreateModal .audio .fa-microphone,
#wantToCreateModal .audio .fa-file-alt,
#wantToCreateModal .audio .fa-file-audio,
#addAudioModal .audio .fa-microphone,
#wantToCreateModal .video .fa-video,
#selectFolderModal .video .fa-video,
#addVideoModal .video .fa-video {
  padding: 56.5px 77.38px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12aeb4;
  background-color: #c7ebeb;
}

#wantToCreateModal .video .fa-video,
#selectFolderModal .video .fa-video {
  padding: 56px 65px;
}

#wantToCreateModal .folder .fa-folder:hover,
#wantToCreateModal .folder .fa-file-alt:hover,
#wantToCreateModal .audio .fa-microphone:hover,
#addAudioModal .folder .fa-folder:hover,
#addVideoModal .folder .fa-folder:hover,
#addAudioModal .audio .fa-microphone:hover,
#wantToCreateModal .folder .fa-folder:active,
#wantToCreateModal .folder .fa-file-alt:active,
#wantToCreateModal .audio .fa-microphone:active,
#addAudioModal .folder .fa-folder:active,
#addAudioModal .audio .fa-microphone:active,
#wantToCreateModal .audio .fa-file-alt:hover,
#wantToCreateModal .audio .fa-file-alt:active,
#wantToCreateModal .video .fa-video:hover,
#wantToCreateModal .video .fa-video:active,
#selectFolderModal .video .fa-video:hover,
#selectFolderModal .video .fa-video:active,
#addVideoModal .video .fa-video:hover,
#addVideoModal .video .fa-video:active,
#wantToCreateModal .folder .fa-book:hover,
#wantToCreateModal .folder .fa-book:active,
#wantToCreateModal .audio .fa-file-audio:hover,
#wantToCreateModal .audio .fa-file-audio:active {
  background-color: #12aeb4;
  color: #fff;
}

#wantToCreateModal .audio .fa-file-alt:active,
#wantToCreateModal .audio .fa-file-alt.inactive:hover,
#wantToCreateModal .audio .fa-file-alt.inactive,
#wantToCreateModal .audio .fa-file-audio.inactive:hover,
#wantToCreateModal .audio .fa-file-audio.inactive:active,
#wantToCreateModal .folder .folder-img.inactive,
#wantToCreateModal .folder .folder-img.inactive:hover,
#wantToCreateModal .folder .folder-img.inactive:active {
  background-color: #c3cccc !important;
  color: #7e7e7e;
  cursor: pointer;
}

#wantToCreateModal .folder-img,
#selectFolderModal .folder-img {
  background-color: #c7ebeb;
  padding: 50px 70px;
  border-radius: 23px;
}

#wantToCreateModal .folder-img:hover,
#selectFolderModal .folder-img:hover,
#wantToCreateModal .folder-img.active,
#selectFolderModal .folder-img.active {
  background-color: #12aeb4;
  cursor: pointer;
}

#wantToCreateModal .folder-img .agenda-hover,
#wantToCreateModal .folder-img:hover .agenda,
#wantToCreateModal .folder-img.active .agenda,
#wantToCreateModal .folder-img .music-hover,
#wantToCreateModal .folder-img:hover .music,
#wantToCreateModal .folder-img.active .music,
#selectFolderModal .folder-img .agenda-hover,
#selectFolderModal .folder-img:hover .agenda,
#selectFolderModal .folder-img.active .agenda,
#selectFolderModal .folder-img .music-hover,
#selectFolderModal .folder-img:hover .music,
#selectFolderModal .folder-img.active .music {
  display: none;
}

#wantToCreateModal .folder-img:hover .agenda-hover,
#wantToCreateModal .folder-img:hover .music-hover,
#selectFolderModal .folder-img:hover .agenda-hover,
#selectFolderModal .folder-img:hover .music-hover,
#wantToCreateModal .folder-img.active .agenda-hover,
#wantToCreateModal .folder-img.active .music-hover,
#selectFolderModal .folder-img.active .agenda-hover,
#selectFolderModal .folder-img.active .music-hover {
  display: block;
}

#wantToCreateModal .audio .info-click {
  position: absolute;
  top: 2%;
  right: -28%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #c2c3cc;
  background-color: rgba(41, 35, 35, 0.7411764706);
  border-radius: 40px;
  padding: 20px;
  width: 331px;
  height: 83px;
}

#wantToCreateModal .title,
#addAudioModal .title,
#addVideoModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #a6a7ae;
  margin-bottom: 0px;
}

#createFolderModal .modal-header,
#createFolderModal .modal-body,
#addVideoDetailsModal .modal-header,
#addVideoDetailsModal .modal-body,
#addAudioDetailsModal .modal-header,
#addAudioDetailsModal .modal-body,
#addLessonDetailsModal .modal-header,
#addLessonDetailsModal .modal-body,
#createSubFolderModal .modal-header,
#createSubFolderModal .modal-body,
#uploadAudioModal .modal-header,
#uploadAudioModal .modal-body,
#uploadVideoModal .modal-header,
#uploadVideoModal .modal-body,
#uploadLessonModal .modal-header,
#uploadLessonModal .modal-body {
  padding: 41px 40px;
}

#createFolderModal label,
#createSubFolderModal label,
#addVideoDetailsModal label,
#addAudioDetailsModal label,
#addLessonDetailsModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#createFolderModal label small,
#createSubFolderModal label small,
#addVideoDetailsModal label small,
#addAudioDetailsModal label small,
#addLessonDetailsModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bebebe;
}

#addVideoDetailsModal .folder-name,
#addAudioDetailsModal .folder-name,
#addLessonDetailsModal .folder-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #bebebe;
  padding-left: 16px;
  padding-left: 40px;
}

#addVideoDetailsModal .notice,
#addAudioDetailsModal .notice,
#addLessonDetailsModal .notice {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #a6a7ae;
}

#createFolderModal .form-group,
#createSubFolderModal .form-group,
#addVideoDetailsModal .form-group,
#addAudioDetailsModal .form-group,
#addLessonDetailsModal .form-group {
  margin-bottom: 30px;
}

#createFolderModal .form-control,
#createSubFolderModal .form-control,
#addVideoDetailsModal .form-control,
#addAudioDetailsModal .form-control,
#addLessonDetailsModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 35px;
  height: 58px;
}

#createFolderModal .upload-file,
#createSubFolderModal .upload-file,
#addVideoDetailsModal .upload-file,
#addAudioDetailsModal .upload-file {
  position: absolute;
  right: 32px;
  top: auto;
  margin-top: 25px;
}

#createFolderModal .date,
#createSubFolderModal .date,
#addVideoDetailsModal .date,
#addVideoDetailsModal .select,
#addAudioDetailsModal .date,
#addAudioDetailsModal .select,
#addLessonDetailsModal .date,
#addLessonDetailsModal .select,
#createFolderModal .select {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#createFolderModal .access,
#createSubFolderModal .access,
#addVideoDetailsModal .access,
#addAudioDetailsModal .access,
#addLessonDetailsModal .access,
#addEventModal .access {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding: 8px 35px 40px;
}

#createFolderModal .access-level,
#createSubFolderModal .access-level,
#addVideoDetailsModal .access-level,
#addAudioDetailsModal .access-level,
#addLessonDetailsModal .access-level,
#addEventModal .access-level {
  padding: 20px 10px;
}

#createFolderModal .access-level,
#createSubFolderModal .access-level,
#addVideoDetailsModal .access-level,
#addAudioDetailsModal .access-level,
#addLessonDetailsModal .access-level,
#addEventModal .access-level {
  border-bottom: 1px solid #ccc;
}

#createFolderModal .btn-custom,
#createSubFolderModal .btn-custom,
#addVideoDetailsModal .btn-custom,
#addAudioDetailsModal .btn-custom,
#addLessonDetailsModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 282px;
  height: 57px;
  margin: 20px 0px 10px 0px;
}

.tooltip-top-member {
  visibility: hidden;
  width: 20em;
  background-color: rgb(46, 44, 44);
  color: #fff;
  height: fit-content;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -10em;
  left: 0;
  font-size: 16px;
  font-weight: 300;
}

.import-member:hover .tooltip-top-member {
  visibility: visible;
}

#createFolderModal .btn-custom:active,
#createSubFolderModal .btn-custom:active,
#addVideoDetailsModal .btn-custom:active,
#addAudioDetailsModal .btn-custom:active,
#addLessonDetailsModal .btn-custom:active {
  box-shadow: none;
}

#createSubFolderModal .go-back,
#uploadAudioModal .go-back,
#uploadVideoModal .go-back,
#uploadLessonModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
}

#createSubFolderModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 0px;
  padding-left: 40px;
}

#createSubFolderModal small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #bebebe;
  padding-left: 40px;
}

#addAudioModal .upload-audio-file,
#addVideoModal .upload-video-file,
#addVideoModal .upload-lesson-file {
  padding: 0px 50px 80px;
}

#addAudioModal .upload-audio-file label,
#addVideoModal .upload-video-file label,
#addVideoModal .upload-lesson-file label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 35px;
}

#uploadAudioModal .title,
#uploadVideoModal .title,
#uploadLessonModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 15px;
  padding-left: 40px;
}

#uploadAudioModal .subtitle,
#uploadVideoModal .subtitle,
#uploadLessonModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #3c3d43;
  margin-bottom: 0px;
  padding-left: 40px;
}

#uploadAudioModal small,
#uploadVideoModal small,
#uploadLessonModal small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bebebe;
  padding-left: 40px;
}

#uploadAudioModal .uploaded-file,
#uploadVideoModal .uploaded-file,
#uploadLessonModal .uploaded-file {
  border-bottom: 1px solid #dedede;
  padding-top: 31px;
  padding-bottom: 31px;
}

#uploadAudioModal .uploaded-file:last-child,
#uploadVideoModal .uploaded-file:last-child,
#uploadLessonModal .uploaded-file:last-child {
  border: none;
}

#uploadAudioModal .uploaded-file .name,
#uploadVideoModal .uploaded-file .name,
#uploadLessonModal .uploaded-file .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 17px;
}

#uploadAudioModal .uploaded-file .perc,
#uploadVideoModal .uploaded-file .perc,
#uploadLessonModal .uploaded-file .perc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #849292;
  float: right;
  margin-bottom: 17px;
}

#uploadAudioModal .uploaded-file .progress,
#uploadVideoModal .uploaded-file .progress,
#uploadLessonModal .uploaded-file .progress {
  height: 8px;
}

#uploadAudioModal .uploaded-file .progress-bar,
#uploadVideoModal .uploaded-file .progress-bar,
#uploadLessonModal .uploaded-file .progress-bar {
  background-color: #12aeb4;
  border-radius: 5px;
}

#audioAddedModal .modal-content,
#videoAddedModal .modal-content,
#lessonAddedModal .modal-content {
  border-radius: 20px;
}

#audioAddedModal .modal-body,
#videoAddedModal .modal-body {
  padding-left: 44px;
  padding-right: 44px;
}

#audioAddedModal .success-img,
#videoAddedModal .success-img,
#lessonAddedModal .success-img {
  margin-top: -100px;
  margin-left: -70px;
}

#audioAddedModal .title,
#videoAddedModal .title,
#lessonAddedModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0c0a08;
  margin-bottom: 24px;
}

#audioAddedModal .text,
#videoAddedModal .text,
#lessonAddedModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #a6a7ae;
  margin-bottom: 19px;
}

/*audio / video library grid view*/
.admin-video-library .video-folders .grid-view,
.admin-audio-library .audio-folders .grid-view {
  margin-top: 50px;
  padding-top: 25px;
  padding-right: 50px;
}

.admin-video-library .video-folders .grid-view.scroll,
.admin-audio-library .audio-folders .grid-view.scroll {
  max-height: 1120px;
  overflow-y: scroll;
  border-right: 1px solid #d8d8d8;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar-thumb,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar-track,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-video-library .video-folders .grid-view .folder,
.admin-audio-library .audio-folders .grid-view .folder {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  margin-bottom: 50px;
}

.admin-video-library .grid-view .folder .img-over,
.admin-audio-library .audio-folders .folder .img-over {
  width: 100%;
  margin-bottom: 19px;
  position: relative;
}

.admin-video-library .grid-view .folder .arrows-img,
.admin-audio-library .audio-folders .folder .arrows-img {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 30px;
  color: #fff;
}

.admin-video-library .grid-view .folder .folder-title,
.admin-audio-library .audio-folders .folder .folder-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
}

.admin-video-library .grid-view .folder .folder-text,
.admin-audio-library .audio-folders .folder .folder-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
  padding-left: 20px;
}

/*audio / video list view*/
.admin-video-library .video-folders .list-view,
.admin-audio-library .audio-folders .list-view {
  margin-top: 50px;
  padding-top: 25px;
  padding-right: 50px;
}

.admin-video-library .video-folders .list-view.scroll,
.admin-audio-library .audio-folders .list-view.scroll {
  max-height: 894px;
  overflow-y: scroll;
  border-right: 1px solid #d8d8d8;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar-thumb,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar-track,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-video-library .video-folders .list-view .table tr td,
.admin-audio-library .audio-folders .list-view .table tr td {
  vertical-align: middle;
}

.admin-video-library .video-folders .list-view .table tr td:first-child,
.admin-audio-library .audio-folders .list-view .table tr td:first-child {
  width: 54px;
  height: 54px;
  padding-left: 0px;
}

.admin-video-library .video-folders .list-view .table-title,
.admin-audio-library .audio-folders .list-view .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.admin-video-library .video-folders .list-view .table-text,
.admin-audio-library .audio-folders .list-view .table-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
}

/* content page setting */
.content-page-setting.content-wrapper {
  background-color: #fff;
}

.content-page-setting section {
  padding-left: 45px;
  padding-right: 97px;
}

.content-page-setting .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
  margin-top: 64px;
  margin-bottom: 22px;
}

.content-page-setting .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
  margin-left: 4px;
}

.content-page-setting .breadcrumb,
.content-edit-template .breadcrumb,
.content-choose-template .breadcrumb {
  background-color: transparent;
}

.content-page-setting .breadcrumb-item a,
.content-page-setting .breadcrumb-item,
.content-edit-template .breadcrumb-item a,
.content-edit-template .breadcrumb-item,
.content-choose-template .breadcrumb-item a,
.content-choose-template .breadcrumb-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.content-page-setting .breadcrumb-item + .breadcrumb-item::before,
.content-choose-template .breadcrumb-item + .breadcrumb-item::before,
.content-edit-template .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #12aeb4;
  content: ">";
}

.content-page-setting label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

.content-page-setting label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a6a7ae;
  padding-left: 5px;
}

.content-page-setting .form-control {
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  font-size: 16px;
  line-height: 25px;
}

.content-page-setting input.form-control {
  height: 58px;
}

.content-page-setting .date {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.content-page-setting .access .scroll {
  max-height: 160px;
  overflow-y: scroll;
  border-right: 2px solid #d8d8d8;
}

.content-page-setting .access .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-page-setting .access .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.content-page-setting .access .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content-page-setting .access .level span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
}

.content-page-setting .access .level span small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #12aeb4;
}

.content-page-setting .access {
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  padding: 11px 15px 40px 35px;
}

.content-page-setting .source {
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  padding: 11px 20px 40px 35px;
}

.content-page-setting .link,
.content-page-setting .create,
.content-page-setting .level {
  padding: 15px 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #242424;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
}

.content-page-setting .upload-file {
  position: absolute;
  right: 0px;
  top: auto;
}

.content-page-setting #preview_img {
  background-color: #ebf7f6;
  height: 230px;
}

.content-page-setting .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  margin-top: 92px;
  margin-bottom: 16px;
  height: 57px;
  width: 797px;
}

.content-page-setting .msg {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #a6a7ae;
  margin-bottom: 23px;
  text-align: center;
}

/* content library want to create modal */
#wantToCreateModal .select-folder,
#selectSubFolderModal .folder {
  padding-left: 30px;
  padding-right: 16px;
}

#wantToCreateModal .select-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3c3d43;
  margin-bottom: 23px;
  margin-left: 26px;
}

#wantToCreateModal .select-folder .scroll {
  max-height: 620px;
  overflow-y: scroll;
  border-right: 2px solid #d8d8d8;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar-thumb,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar-track,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#wantToCreateModal .select-folder .card,
#selectSubFolderModal .folder .card {
  background: #f2ffff;
  border-radius: 9px;
  margin: 15px 26px;
}

#wantToCreateModal .select-folder .card:hover,
#selectSubFolderModal .folder .card:hover,
#wantToCreateModal .select-folder .card.active,
#selectSubFolderModal .folder .card.active {
  background: #ffffff;
  box-shadow: 5px 5px 18px rgba(41, 168, 174, 0.5411764706);
}

#wantToCreateModal .select-folder .card .card-body,
#selectSubFolderModal .folder .card .card-body {
  padding: 23px 21px 24px 34px;
}

#wantToCreateModal .select-folder .card .card-title,
#selectSubFolderModal .folder .card .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  word-break: break-word;
  overflow-wrap: break-word;
}

#wantToCreateModal .select-folder .card .card-text,
#selectSubFolderModal .folder .card .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929aa5;
  word-break: break-word;
  overflow-wrap: break-word;
}

#wantToCreateModal .select-folder .card .card-body .page-info,
#selectSubFolderModal .folder .card .card-body .page-info {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #00aeb3;
}

#wantToCreateModal .select-folder .btn-custom,
#selectSubFolderModal .folder .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 32px;
  margin-bottom: 51px;
}

/* select sub folder modal */
#selectSubFolderModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
  cursor: pointer;
}

#selectSubFolderModal .folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3c3d43;
  margin-left: 26px;
  margin-top: 22px;
  margin-bottom: 0px;
}

#selectSubFolderModal .folder .scroll {
  max-height: 210px;
  overflow-y: scroll;
  border-right: 2px solid #d8d8d8;
}

/*content library css start*/
.content-wrapper.admin-content-library {
  background-color: #fff;
  margin-right: 30px;
}

.admin-content-library .hover-info {
  position: relative;
  display: block;
}

.admin-content-library .hover-info .info-icon {
  cursor: pointer;
}

.admin-content-library .info-hover {
  visibility: hidden;
  position: absolute;
  top: 35%;
  right: 2%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #c9c9c9;
  background-color: rgba(0, 0, 0, 0.7215686275);
  border-radius: 25px;
  padding: 29px 11px 23px 17px;
  width: 474px;
  text-align: justify;
}

.admin-content-library .hover-info:hover .info-hover {
  visibility: visible;
  z-index: 2;
}

.admin-content-library .content-folders .hover-info {
  display: inline-block;
}

.admin-content-library .content-folders .info-hover {
  top: 35%;
  left: 25px;
  right: 0;
}

.admin-content-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-content-library .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.admin-content-library .readinglist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.admin-content-library .readinglist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.admin-content-library .readinglist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.admin-content-library .readinglist .right-img {
  position: absolute;
  right: 0px;
  top: 5px;
  z-index: 1;
  width: 52px;
  height: 78px;
}

.admin-content-library .content-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-content-library .content-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-content-library .content-folders .title small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-content-library .content-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.admin-content-library .content-folders .sort,
.admin-content-library .content-folders .date {
  width: 220px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #ebf7f6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-content-library .content-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  background: #c7ebeb;
  border-radius: 6px;
  width: 150px;
  height: 42px;
}

.admin-content-library .content-folders .btn-custom:hover {
  background-color: #12aeb4;
  color: #fff;
}

.admin-content-library .content-folders .content-slider {
  margin-top: 60px;
  margin-bottom: 45px;
}

.admin-content-library .content-folders .content-slider .main-img {
  position: relative;
}

.admin-content-library .content-folders .content-slider .img-over {
  margin-bottom: 21px;
  height: 10rem;
  width: 100%;
  object-fit: cover;
}

.admin-content-library .content-folders .content-slider .arrows-img {
  position: absolute;
  bottom: 0;
  right: -18px;
  width: 40px;
  height: 44px;
}

.admin-content-library .content-folders .content-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 9px;
  word-wrap: break-word;
}

.admin-content-library .content-folders .content-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
}

.admin-content-library
  .content-folders
  .content-slider
  .owl-nav
  [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-content-library .content-folders .content-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-content-library .content-folders .content-slider .owl-next {
  position: absolute;
  top: 130px;
  right: -15px;
}

.admin-content-library .content-folders .content-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-content-library .content-folders .content-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-content-library .content-folders .content-slider .item {
  cursor: pointer;
}

.admin-content-library .content-folders .folder-info {
  margin-right: 20px;
  width: 200px;
}

.admin-content-library .content-folders .folder-info .main-img {
  width: 193px;
  height: 200px;
}

.admin-content-library .content-folders .folder-info .folder-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #333244;
}

.admin-content-library .content-folders .folder-detail {
  margin-bottom: 4px;
}

.admin-content-library .content-folders.filter img {
  cursor: pointer;
}

.admin-content-library .content-list #contentLibraryTab {
  border: none;
  align-items: center;
}

.admin-content-library .content-list .nav-item {
  width: 196px;
  margin-right: 35px;
}

.admin-content-library .content-list .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8d9296;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-content-library .content-list .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #12aeb4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12aeb4;
}

.admin-content-library .content-list .search-content {
  position: relative;
}

.admin-content-library .content-list .search-content input {
  @include wisdome-shadow;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.admin-content-library .content-list .search-icon {
  position: absolute;
  width: 44px;
  height: 44px;
  inset: 7px 7px 7px auto;
}

.admin-content-library .content-list .search-icon .fa-search {
  font-size: 39px;
  color: #7e828f;
}

.admin-content-library .content-list #contentLibraryTabContent {
  background: #ffffff;
  box-shadow: 0px 3px 17px #c7dcda;
  border-radius: 4px;
  padding: 8px 22px 4px 48px;
  margin-top: 45px;
  margin-bottom: 100px;
}

.admin-content-library
  .content-list
  #contentLibraryTabContent
  .table
  tr:first-child
  td {
  border-top: none;
}

.admin-content-library
  .content-list
  #contentLibraryTabContent
  .table
  td:first-child {
  border-top: none;
  padding: 0;
  padding-bottom: 11px;
}

.admin-content-library .content-list #contentLibraryTabContent .table td {
  vertical-align: baseline;
  border-top: 1px solid #cccfcf;
}

.admin-content-library .content-list #contentLibraryTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-content-library .content-list #contentLibraryTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

/*content-library list view*/
.admin-content-library .list-view {
  margin-top: 38px;
  margin-bottom: 60px;
}

.admin-content-library .list-view .table tr:first-child td {
  border-top: none;
}

.admin-content-library .list-view .table td {
  vertical-align: middle;
  border-top: 1px solid #cccfcf;
}

.admin-content-library .list-view .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table .heading {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.admin-content-library .list-view .table .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: right;
}

/* content library select template */
.content-choose-template {
  background: #ffffff;
  border-radius: 87px;
  margin: 90px;
  padding: 69px 120px 164px;
}

.content-choose-template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #1c1818;
  text-align: center;
  margin-bottom: 32px;
}

.content-choose-template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #7b6f6f;
  text-align: center;
  margin-bottom: 81px;
}

.content-choose-template .template {
  border: 1px solid #707070;
  border-radius: 43px;
  padding: 37px;
  margin-bottom: 41px;
}

.content-choose-template .template .image {
  display: flex;
  align-items: center;
  background-color: #ebf7f6;
  min-height: 250px;
}

.content-choose-template .template .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #12aeb4;
  text-align: center;
  margin-bottom: 32px;
}

.content-choose-template .template .content .progress {
  background-color: #e5e5e5;
  border-radius: 1px;
  box-shadow: none;
}

/* content choose template 2 */
.content-choose-template .content {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #655a5a;
}

.content-choose-template .video {
  position: relative;
}

.content-choose-template .video .video-icon {
  position: absolute;
  top: 30%;
  left: 40%;
}

/* content edit template */
.content-edit-template {
  /* text-align: center; */
  padding: 68px 214px 200px 172px;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.content-edit-template .go-back,
.content-choose-template .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
  cursor: pointer;
  margin-bottom: 10px;
}

.content-edit-template .go-back a,
.content-choose-template .go-back a {
  color: #00aeb3;
}

.content-edit-template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #1c1818;
  margin-bottom: 32px;
}

.content-edit-template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #7b6f6f;
  margin-bottom: 80px;
}

.content-edit-template .template {
  background-color: #ffffff;
  border: 2px solid #12aeb4;
  border-radius: 43px;
  padding: 45px 51px 72px;
  min-height: 324px;
}
.content-edit-template .template .content img {
  max-width: 100%;
  height: auto;
}

.content-edit-template .template .image {
  display: flex;
  align-items: center;
  background-color: #ebf7f6;
}

.content-edit-template .template .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #12aeb4;
  margin-bottom: 32px;
}

.content-edit-template .template .content .progress {
  background-color: #e5e5e5;
  border-radius: 1px;
  box-shadow: none;
}

.content-edit-template .upload-media {
  display: flex;
  align-items: center;
  padding: 33px 51px;
}

.content-edit-template .upload-media .image {
  display: flex;
  align-items: center;
  background-color: #ebf7f6;
  height: 315px;
  margin-bottom: 14px;
}

.content-edit-template .upload-media .media-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #7b6f6f;
  margin-bottom: 0px;
}

.content-edit-template .upload-media .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  border-radius: 9px;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  width: 295px;
  height: 57px;
}

.content-edit-template .text-area {
  background-color: #ffffff;
  border: 2px solid #12aeb4;
  border-radius: 43px;
  padding: 45px 51px 72px;
}

.content-edit-template .text-area textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  /* color: #B7B5B5; */
  border: none;
}

.content-edit-template .text-area textarea:focus {
  background-color: transparent;
  height: auto;
}

.content-edit-template .btn-custom1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  border-radius: 9px;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  width: 798px;
  height: 57px;
  margin-top: 133px;
}

.content-edit-template .upload-media .dropdown-menu {
  background: #ededed;
  border-radius: 36px;
  border: none;
  width: 458px;
  padding: 18px 23px 11px 46px;
  top: -60px !important;
  left: 16px !important;
}

.content-edit-template .upload-media .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #655a5a;
  border-bottom: 1px solid #655a5a;
}

.content-edit-template .upload-media .dropdown-item:hover,
.content-edit-template .upload-media .dropdown-item:active {
  color: #12aeb4;
  background-color: transparent;
}

.content-edit-template .upload-media .dropdown-item:last-child {
  border: none;
}

/* content library upload photo modal */
#contentLibraryUploadPhoto .modal-content,
#contentLibraryUploadVideo .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#contentLibraryUploadPhoto .go-back,
#contentLibraryUploadVideo .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00aeb3;
}

#contentLibraryUploadPhoto .title,
#contentLibraryUploadVideo .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 29px;
}

#contentLibraryUploadPhoto .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 11px;
}

#contentLibraryUploadPhoto .modal-header,
#contentLibraryUploadVideo .modal-header {
  padding: 41px 40px;
}

#contentLibraryUploadPhoto .modal-body,
#contentLibraryUploadVideo .modal-body {
  padding: 13px 40px 41px 40px;
}

#contentLibraryUploadPhoto .uploaded-file .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 17px;
}

#contentLibraryUploadPhoto .uploaded-file .perc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #849292;
  float: right;
  margin-bottom: 17px;
}

#contentLibraryUploadPhoto .uploaded-file .progress {
  height: 8px;
}

#contentLibraryUploadPhoto .uploaded-file .progress-bar {
  background-color: #12aeb4;
  border-radius: 5px;
}

#contentLibraryUploadPhoto .btn-custom,
#contentLibraryUploadVideo .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 295px;
  height: 57px;
  margin-top: 40px;
}

/* content library upload video modal */
#contentLibraryUploadVideo .content-video {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  border-radius: 4px;
  padding: 8px 23px 35px;
}

#contentLibraryUploadVideo .content-video .add-video {
  padding: 10px;
}

#contentLibraryUploadVideo .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #3b4747;
  margin-bottom: 8px;
}

#contentLibraryUploadVideo .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #8d9296;
  background: #ebf7f6;
  border-radius: 4px;
}

#contentLibraryUploadVideo textarea {
  height: 106px;
  padding-right: 55px;
}

#contentLibraryUploadVideo .insert-code {
  position: relative;
}

#contentLibraryUploadVideo .right-img {
  position: absolute;
  top: 45px;
  right: 10px;
}

/* content library final page preview */
.content-final-preview.content-wrapper {
  background-color: #fff;
}

.content-final-preview section {
  padding-left: 45px;
  padding-right: 75px;
}

.content-final-preview .heading {
  margin-top: 49px;
}

.content-final-preview .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
  margin-bottom: 22px;
}

.content-final-preview .heading a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12aeb4;
  background: #ebfeff;
  border-radius: 30px;
  padding: 10px 20px;
  width: 215px;
  height: 59px;
  margin-right: 28px;
}

.content-final-preview .breadcrumb {
  background-color: transparent;
}

.content-final-preview .breadcrumb-item a,
.content-final-preview .breadcrumb-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.content-final-preview .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #12aeb4;
  content: ">";
}

.content-final-preview .content-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 17px;
}

.content-final-preview .create-content {
  border-radius: 4px;
  padding: 30px 25px 50px 17px;
  margin-right: 25px;
}

.content-final-preview .create-content-scroll {
  max-height: 1440px;
  overflow-y: scroll;
  border-right: 1px solid #e6ebf5;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content-final-preview .create-content .template {
  background-color: #fff;
  border-radius: 32px;
  padding: 22px 30px;
  margin-bottom: 21px;
  position: relative;
}

.content-final-preview .template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #060136;
  margin-bottom: 29px;
}

.content-final-preview .template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  margin-bottom: 23px;
  word-break: break-word;
  word-wrap: break-word;
}
.content-final-preview .template .text img {
  max-width: 100%;
  height: auto;
}

.content-final-preview .template .arrow {
  position: absolute;
  top: -20px;
  right: -15px;
}

.content-final-preview .template .edit {
  position: absolute;
  right: -13px;
  bottom: 60px;
  width: 40px;
  height: 32px;
  cursor: pointer;
}

.content-final-preview .template .delete {
  position: absolute;
  right: -13px;
  bottom: 20px;
  width: 40px;
  height: 32px;
  cursor: pointer;
}

.content-final-preview .template .link {
  margin-bottom: 30px;
}

.content-final-preview .template .link a {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #12aeb4;
  text-decoration: underline;
}

.content-final-preview .template .download {
  background: #c7ebeb;
  border-radius: 5px;
  padding: 14px 20px;
  margin-bottom: 59px;
  position: relative;
}

.content-final-preview .template .download a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #12aeb4;
}

.content-final-preview .template .download .download-icon {
  position: absolute;
  top: -5px;
  right: 20px;
}

.content-final-preview .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  max-width: 600px;
  height: 57px;
  margin-top: 56px;
}

/*member page css start*/
.admin-members .membership-search {
  position: relative;
}

.admin-members .membership-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding-right: 64px;
}

.admin-members .top-buttons .search-icon {
  position: absolute !important;
  top: 10% !important;
}

.admin-members .search-icon {
  position: absolute;
  right: 14px;
  top: 4px;
}

.admin-members .member-download-link {
  text-align: center;
}

.admin-members .member-download-link .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #172b4d;
  margin-bottom: 0px;
}

.admin-members .member-download-link .link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #12aeb4;
  margin-bottom: 0px;
}

.admin-members .notification {
  position: relative;
  margin-right: 30px;
}

.admin-members .notification .oval {
  position: absolute;
  top: 8px;
  right: -4px;
}

.admin-members .user-info .dropdown-toggle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #172b4d;
  margin-bottom: 0px;
}

.admin-members .user-info .dropdown-toggle::after {
  border: none;
}

.admin-members .back-to {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
  margin-bottom: 0px;
}

.admin-members .top-header {
  margin-top: 7px;
  margin-bottom: 21px;
}

.admin-members .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0a1629;
  margin-bottom: 0px;
}

.admin-members .top-header .calendar {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  background: "#A0DBDD";
  border-radius: 14px;
  width: 280px;
  height: 48px;
  padding-left: 40px;
  background-image: url("../images/admin/icn-general-calendar.svg");
  background-repeat: no-repeat;
  background-position: 3% center;
  float: right;
}

.admin-members .top-buttons {
  padding-bottom: 13px;
  border-bottom: 1px solid #e6ebf5;
}

.admin-members .top-buttons .member-search {
  position: relative;
  margin-right: 15px;
}

.admin-members .top-buttons .member-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding-right: 64px;
  width: 324px;
  height: 56px;
  margin-bottom: 16px;
}

.admin-members .top-buttons .search-icon {
  position: absolute;
  width: 42px;
  inset: 5px 7px 5px auto;
}

.admin-members .top-buttons .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 212px;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.admin-members .select-all {
  margin-top: 35px;
  margin-bottom: 19px;
}

.admin-members .select-all span {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7d8592;
}

.admin-members .select-all img {
  cursor: pointer;
}

.admin-members .member-list thead tr th {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
  padding: 10px 5px;
}

.admin-members .member-list .dropdown #member-option i {
  color: #fff;
  background: #a0dbdd;
  border-radius: 14px;
  font-size: 18px;
  padding: 13px 19px;
}

.admin-members .member-list .dropdown.show #member-option {
  background: rgba(160, 219, 221, 0.3803921569);
  box-shadow: 0px 10px 10px rgba(160, 219, 221, 0.3803921569);
  padding: 17px 5px 8px 5px;
  border-radius: 50px;
}

.admin-members .member-list .dropdown.show #member-option i {
  box-shadow: 0px 10px 10px rgba(160, 219, 221, 0.3803921569);
  font-size: 22px;
  padding: 10px 16px;
  border-radius: 50px;
}

.admin-members .member-list tbody tr {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.1019607843);
  border-radius: 24px;
}

.admin-members .member-list tbody tr td {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  vertical-align: middle;
  text-align: start;
  padding: 10px 5px;
}

.admin-members .member-list tbody tr td:last-child {
  width: 150px;
}

.admin-members .member-list tbody tr td .checkMember::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

.admin-members
  .member-list
  tbody
  tr
  td
  .checkInput:checked
  ~ .checkMember::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

.admin-members .member-list tbody tr td .checkMember::after {
  width: 20px;
  height: 20px;
}

.admin-members
  .member-list
  tbody
  tr
  td
  .custom-checkbox
  .checkInput:checked
  ~ .checkMember::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.admin-members .member-list tbody tr td .member-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 0px;
}

.admin-members .member-list tbody tr td .email {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #91929e;
  margin-bottom: 0px;
}

.admin-members .member-list tbody tr td .dropdown-menu {
  background: #dbf3f4;
  box-shadow: 5px 5px 10px rgba(34, 136, 133, 0.3607843137);
  border-radius: 25px;
  border: none;
  width: 200px;
  padding: 0px;
  margin: 3px 0px -3.5px 0px;
}

.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #19b0b6;
  letter-spacing: 0.38px;
  padding: 0.2rem 1.3rem;
}

.admin-members
  .member-list
  tbody
  tr
  td
  .dropdown-menu
  .dropdown-item:first-child:hover {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.admin-members
  .member-list
  tbody
  tr
  td
  .dropdown-menu
  .dropdown-item:last-child:hover {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item.active,
.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item:active {
  background-color: #ffffff;
}

.admin-members .member-list .pagination .number {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-right: 21px;
}

.admin-members .member-list .pagination .arrow-left,
.admin-members .member-list .pagination .arrow-right {
  color: #c9ccd1;
  margin-right: 14px;
  font-size: 16px;
}

.admin-members .member-list .pagination .active {
  color: #12aeb4;
}

.admin-members .chat-list {
  margin-top: 35px;
  margin-bottom: 25px;
}

.admin-members .chat-list .chat {
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 24px;
  width: 75px;
  height: 848px;
  float: right;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
}

.admin-members .chat-list .chat .user-image {
  width: 58px;
  height: 58px;
  margin: 4px 5px;
  border: 2px solid #ffffff;
  border-radius: 50px;
}

.admin-members .chat-list .chat .new-chat .chat-image {
  width: 42px;
  height: 42px;
  margin: 4px 5px;
  position: absolute;
  bottom: 0;
  right: 25px;
}

#filterMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#filterMembersModal .modal-header {
  border-bottom: none;
}

#filterMembersModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#filterMembersModal .modal-body {
  padding: 0;
}

#filterMembersModal .member-level {
  padding-top: 22px;
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 9px;
}

#filterMembersModal select,
#filterMembersModal input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 50px;
}

#filterMembersModal select {
  appearance: none;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#filterMembersModal .member-join {
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .member-join .fa-calendar {
  position: absolute;
  top: 45px;
  right: 12px;
  font-size: 21px;
  color: #12aeb4;
}

#filterMembersModal .member-renew {
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .member-renew .fa-calendar {
  position: absolute;
  top: 45px;
  right: 12px;
  font-size: 21px;
  color: #12aeb4;
}

#filterMembersModal .member-list {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 35px;
}

#filterMembersModal .member-list .custom-checkbox {
  margin-right: 19px;
}

#filterMembersModal .member-list .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

#filterMembersModal
  .member-list
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

#filterMembersModal .member-list .custom-control-label::after {
  width: 20px;
  height: 20px;
}

#filterMembersModal
  .member-list
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

#filterMembersModal .member-list .member-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-left: 10px;
  margin-bottom: 0px;
}

#filterMembersModal .member-list .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
}

#filterMembersModal .social-group {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 4px;
  position: relative;
}

#filterMembersModal .social-group .social-bg {
  background-color: #ebf7f6;
  border-radius: 4px;
  padding: 15px;
}

#filterMembersModal .social-group input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  border: 1px solid #12aeb4;
  border-radius: 20px;
  background-color: transparent;
}

#filterMembersModal .social-group .badge-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0a1629;
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 12px;
  border: 1px solid #12aeb4;
}

#filterMembersModal .social-group .badge-custom i {
  color: #12aeb4;
  font-size: 12px;
}

#filterMembersModal .match {
  padding-left: 30px;
  padding-right: 30px;
}

#filterMembersModal .match span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
}

#filterMembersModal .match img {
  width: 18px;
  height: 18px;
  margin-right: 9px;
}

#filterMembersModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 300px;
  height: 50px;
  margin-bottom: 50px;
}

#addFilesToBulkModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#addFilesToBulkModal .modal-header {
  border-bottom: 1px solid #e4e6e8;
}

#addFilesToBulkModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#addFilesToBulkModal .modal-body {
  padding: 0;
}

#addFilesToBulkModal .attach-file {
  padding-left: 30px;
  padding-right: 30px;
}

#addFilesToBulkModal .attach-file .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 15px;
}

#addFilesToBulkModal .attach-file .input-file {
  text-align: center;
}

#addFilesToBulkModal .audience {
  padding-left: 30px;
  padding-right: 30px;
}

#addFilesToBulkModal .audience .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
}

#addFilesToBulkModal .audience .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

#addFilesToBulkModal .audience select {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  -webkit-appearance: none;
  appearance: none;
}

#addFilesToBulkModal .audience .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
}

#addFilesToBulkModal .file-history {
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 16px;
}

#addFilesToBulkModal .file-history .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3b4747;
  margin-bottom: 19px;
}

#addFilesToBulkModal .file-history .file-icon i {
  font-size: 50px;
  margin-right: 28px;
  color: #12aeb4;
}

#addFilesToBulkModal .file-history .file-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #0a1629;
  margin-bottom: 10px;
}

#addFilesToBulkModal .file-history .date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  margin-bottom: 0px;
}

#addFilesToBulkModal .file-history .action-icon {
  font-size: 20px;
  text-align: center;
  color: #89d7da;
}

#addFilesToBulkModal .file-history .action-icon .fa-trash-alt {
  margin-left: 10px;
}

#addFilesToBulkModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 198px;
  height: 63px;
  margin-bottom: 34px;
}

#addFilesToBulkModal .audience .sub-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: #12aeb4;
  margin-top: 20px;
  margin-bottom: 0px;
}

/* import Members Modal */
#importMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#importMembersModal .modal-header {
  border-bottom: none;
}

#importMembersModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
}

#importMembersModal .modal-body {
  padding: 0;
}

#importMembersModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 287px;
  height: 48px;
  margin-top: 30px;
  margin-bottom: 20px;
}

#importMembersModal .instruction {
  padding-left: 42px;
  padding-right: 37px;
  padding-bottom: 18px;
}

#importMembersModal .instruction p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #7d8592;
  margin-bottom: 0px;
}

#importMembersModal .instruction p:nth-last-child(2) {
  margin-bottom: 30px;
}

#importMembersModal .instruction small {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #7d8592;
}

#importMembersModal .instruction2 {
  padding-left: 42px;
  padding-right: 37px;
  padding-top: 18px;
  padding-bottom: 2px;
}

#importMembersModal .instruction2 ul li {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #7d8592;
}

#importMembersModal .btn-custom2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 150px;
  height: 48px;
  margin-bottom: 13px;
}

/* add/edit Members Modal */
#addEditMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#addEditMembersModal .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

#addEditMembersModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
}

#addEditMembersModal .modal-body {
  padding: 0;
}

#addEditMembersModal .member-info {
  padding-top: 23px;
  padding-left: 30px;
  padding-right: 30px;
}

#addEditMembersModal .member-info img {
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#addEditMembersModal .member-info .upload-img {
  position: absolute;
  top: 0px;
  right: 30px;
  background: #d7f1f2;
  border-radius: 14px;
  padding: 8px 10px 8px 12px;
  margin-bottom: 0px;
  color: #12aeb4;
}

#addEditMembersModal .member-info .name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a1629;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0a1629;
  margin-bottom: 7px;
}

#addEditMembersModal .member-info label,
#addEditMembersModal .member-level label,
#addEditMembersModal .additional-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .form-control,
#addEditMembersModal .member-level .form-control,
#addEditMembersModal .additional-info .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

#addEditMembersModal .member-level {
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #E4E6E8; */
}

#addEditMembersModal .member-level select {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#addEditMembersModal .member-level .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

#addEditMembersModal
  .member-level
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

#addEditMembersModal .member-level .custom-control-label::after {
  width: 20px;
  height: 20px;
}

#addEditMembersModal
  .member-level
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

#addEditMembersModal .member-level .custom-control-label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #0a1629;
}

#addEditMembersModal .member-level .fa-link,
#addEditMembersModal .member-level .fa-calendar {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 21px;
  color: #12aeb4;
}

#addEditMembersModal .additional-info {
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

#addEditMembersModal .additional-info .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0a1629;
  margin-bottom: 11px;
}

#addEditMembersModal .additional-info .fa-map-marker-alt,
#addEditMembersModal .additional-info .fa-calendar {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 21px;
  color: #12aeb4;
}

#addEditMembersModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 300px;
  height: 48px;
  margin-bottom: 48px;
}

/*admin social wall*/
.content-wrapper.admin-social-wall {
  background-color: #fff;
}

.admin-social-wall .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0a1629;
}

.admin-social-wall .calendar {
  position: relative;
}

.admin-social-wall .calendar input {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #12aeb4;
  border-radius: 14px;
  background-color: #d7f1f2;
  padding-left: 50px;
  width: 60%;
}

.admin-social-wall .calendar:after {
  content: "\f133";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #459ce7;
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 20px;
}

.admin-social-wall #socialWallTab {
  border: none;
  align-items: center;
  margin-top: 30px;
}

.admin-social-wall #socialWallTab .nav-item {
  width: 196px;
  margin-right: 35px;
}

.admin-social-wall #socialWallTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8d9296;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-social-wall #socialWallTab .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #12aeb4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12aeb4;
}

.admin-social-wall #socialWallTab .search-library {
  position: relative;
}

.admin-social-wall #socialWallTab .search-library input {
  @include wisdome-shadow;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  /* color: #8D91A1; */
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.admin-social-wall #socialWallTab .search-icon {
  position: absolute;
  width: 44px;
  height: 44px;
  inset: 7px 7px 7px auto;
}

.admin-social-wall #socialWallTab .search-icon .fa-search {
  font-size: 39px;
  color: #7e828f;
}

.admin-social-wall #socialWallTab .btn-custom {
  background-color: #a0dbdd;
  border-radius: 14px;
  color: #fff;
  margin-left: 40px;
  width: 67px;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.admin-social-wall #socialWallTabContent {
  margin-top: 45px;
  margin-bottom: 100px;
}

.admin-social-wall #socialWallTabContent .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.admin-social-wall #socialWallTabContent .owl-item {
  padding: 18px;
}

.admin-social-wall #socialWallTabContent .item {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 16px;
  cursor: pointer;
  height: 100%;
}

.admin-social-wall #socialWallTabContent .item .img-over {
  max-height: 145px;
}

.admin-social-wall #socialWallTabContent .item:hover,
.admin-social-wall #socialWallTabContent .item.active {
  box-shadow: 8px 8px 10px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #12aeb4;
}

.admin-social-wall #socialWallTabContent .item .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.admin-social-wall #socialWallTabContent .item .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
}

.admin-social-wall #socialWallTabContent .item .slide-text .fa-history {
  color: #12aeb4;
  font-size: 25px;
}

.admin-social-wall #socialWallTabContent .owl-nav [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-social-wall #socialWallTabContent .owl-prev {
  position: absolute;
  top: 135px;
  left: -50px;
}

.admin-social-wall #socialWallTabContent .owl-next {
  position: absolute;
  top: 130px;
  right: -60px;
}

.admin-social-wall #socialWallTabContent .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-social-wall #socialWallTabContent .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-social-wall .post-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a1629;
  margin-bottom: 10px;
}

.admin-social-wall .post-desc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 14px;
}

.admin-social-wall .post-desc-list {
  margin-bottom: 30px;
}

.admin-social-wall .post-desc-list li {
  list-style-image: url("../images/admin/noun-chat-254985.svg");
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 14px;
}

.admin-social-wall .post-area {
  border: 1px solid #89d7da;
  border-radius: 12px;
  padding: 30px 42px;
  margin-bottom: 58px;
}

.admin-social-wall .post-area textarea.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #495057;
  border: none;
  border-radius: 5px;
  background-color: #f4f9fd;
  height: 200px;
  margin-bottom: 34px;
}

.admin-social-wall .post-area .fa-ellipsis-v,
.fa-ellipsis-v {
  float: right;
  color: #12aeb4;
  font-size: 22px;
}
.admin-social-wall .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}
.socialCreateModal .modal-dialog {
  max-width: 625px;
}
.socialCreateModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: black;
}
.admin-social-wall .post-area .dropdown-menu,
.admin-social-wall .posts .dropdown-menu,
.dropdown-menu {
  background: #ffffff;
  box-shadow: 5px 10px 10px #12aeb4;
  border: 1px solid #d6d6d6;
}

.admin-social-wall .post-area .dropdown-item,
.admin-social-wall .posts .dropdown-item,
.dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #12aeb4;
  padding: 5px 26px;
}

.admin-social-wall .post-area .dropdown-item:active,
.admin-social-wall .posts .dropdown-item:active {
  background-color: transparent;
}

.admin-social-wall .post-area .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  background-color: #12aeb4;
  border-radius: 12px;
  width: 246px;
  height: 71px;
  float: right;
}

.admin-social-wall .post-area .uploaded-img {
  position: relative;
  width: 239px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.admin-social-wall .post-area .uploaded-img:nth-child(4n + 0) {
  margin-right: 0px;
}

.admin-social-wall .post-area .cross {
  position: absolute;
  top: 5px;
  right: 5px;
}

.admin-social-wall .posts {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 12px;
  padding: 30px 42px;
  margin-bottom: 58px;
}

.admin-social-wall .posts .media-title,
#showPostModal .posts .media-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 8px;
}

.admin-social-wall .posts .comment-bg,
#showPostModal .posts .comment-bg {
  background-color: #d7f1f2;
  border-radius: 16px;
  padding: 10px 15px;
  margin-bottom: 26px;
}

.admin-social-wall .posts .media-title.comment-name,
#showPostModal .posts .media-title.comment-name {
  color: #12aeb4;
}

.admin-social-wall .posts .comment-bg .post-text,
#showPostModal .posts .comment-bg .post-text {
  border-bottom: 1px solid #89d7da;
}

.admin-social-wall .posts .comment-bg .row:last-child .post-text,
#showPostModal .posts .comment-bg .row:last-child .post-text {
  border-bottom: none;
  padding-bottom: 0px;
}

.admin-social-wall .posts .media-text,
#showPostModal .posts .media-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #6d7886;
}

.admin-social-wall .posts .fa-ellipsis-v,
#showPostModal .posts .fa-ellipsis-v {
  float: right;
  color: #12aeb4;
  font-size: 22px;
}

.admin-social-wall .posts .post-text,
#showPostModal .posts .post-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6d7886;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.admin-social-wall .posts .lg-img {
  width: 550px;
  height: 343px;
  margin-right: 17px;
  margin-bottom: 10px;
  cursor: pointer;
}

.admin-social-wall .posts .sm-img {
  width: 253px;
  height: 163px;
  margin-right: 17px;
  margin-bottom: 17px;
  cursor: pointer;
}

.admin-social-wall .posts .like,
.admin-social-wall .posts .comment,
#showPostModal .posts .like,
#showPostModal .posts .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #1e1f20;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.admin-social-wall .posts input.form-control,
#showPostModal .posts input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6d7886;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d7f1f2;
  border-radius: 14px;
  height: 34px;
}

.admin-social-wall .posts .submit-img,
#socialCreateModal .topic .submit-img {
  cursor: pointer;
}

#showPostModal #postCarousel {
  padding: 0 20px;
  margin-top: -90px;
}

#showPostModal .carousel-control-prev {
  left: -50px;
  border: none;
  background: transparent;
}

#showPostModal .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312AEB4' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

#showPostModal .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312AEB4' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

#showPostModal .carousel-control-next {
  right: -50px;
  border: none;
  background: transparent;
}

#showPostModal .owl-nav [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

#showPostModal .owl-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -25px;
}

#showPostModal .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -25px;
}

#showPostModal .social-modal-slider {
  margin-top: -90px;
}

#showPostModal .posts .scroll {
  max-height: 270px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
}

#showPostModal .posts .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#showPostModal .posts .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#showPostModal .posts .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*admin courses */
.content-wrapper.admin-courses,
.content-wrapper.admin-student-queries {
  background-color: #fff;
  margin-right: 30px;
}

.admin-courses .title,
.admin-student-queries .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-courses .title a,
.admin-student-queries .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.admin-courses .content-header .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 323px;
  height: 48px;
  float: right;
}

.admin-courses .courses-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-courses .courses-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-courses .courses-folders .title small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-courses .courses-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.admin-courses .courses-folders .sort,
.admin-courses .courses-folders .date {
  width: 18%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #ebf7f6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-courses .courses-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  background: #c7ebeb;
  border-radius: 6px;
  width: 150px;
  height: 42px;
  margin-bottom: 40px;
}

.admin-courses .courses-folders .btn-custom:hover {
  background-color: #12aeb4;
  color: #fff;
}

.admin-courses .courses-folders .courses-slider {
  margin-top: 60px;
  margin-bottom: 45px;
}

.admin-courses .courses-folders .courses-slider .main-img {
  position: relative;
}

.admin-courses .courses-folders .courses-slider .img-over {
  margin-bottom: 14px;
  height: 200px;
}

.admin-courses .courses-folders .courses-slider .arrows-img {
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 40px;
  height: 44px;
}

.admin-courses .courses-folders .courses-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 8px;
  word-break: break-word;
  word-wrap: break-word;
}

.admin-courses .courses-folders .courses-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 10px;
}

.admin-courses .courses-folders .courses-slider .owl-stage-outer {
  padding: 30px 0px;
}

.admin-courses .courses-folders .courses-slider .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.admin-courses .courses-folders .courses-slider .item {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 100%;
}

.admin-courses .courses-folders .courses-slider .owl-nav [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-courses .courses-folders .courses-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-courses .courses-folders .courses-slider .owl-next {
  position: absolute;
  top: 130px;
  right: -15px;
}

.admin-courses .courses-folders .courses-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-courses .courses-folders .courses-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-courses .courses-folders .courses-slider .item .edit-img {
  cursor: pointer;
}

.admin-courses .courses-list #coursesTab,
.admin-courses .courses-folders #coursesTab {
  border: none;
  align-items: center;
}

.admin-courses .courses-list .nav-item,
.admin-courses .courses-folders .nav-item {
  width: 200px;
  margin-right: 35px;
}

.admin-courses .courses-list .nav-link,
.admin-courses .courses-folders .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8d9296;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-courses .courses-list .nav-link.active,
.admin-courses .courses-folders .nav-link.active {
  color: #12aeb4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12aeb4;
}

.admin-courses .courses-folders #viewAll-tab,
.admin-courses .courses-list #viewAll-tab {
  color: #12aeb4;
}

.admin-courses .courses-folders .search-course,
.admin-courses .content-header .search-course {
  position: relative;
}
.admin-courses .courses-list .search-email input,
.admin-courses .courses-folders .search-course input {
  @include wisdome-shadow;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}
.admin-courses .email-creation .search-members input,
.admin-courses .courses-list .search-email input,
.admin-courses .content-header .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-courses .courses-folders .search-icon,
.admin-courses .content-header .search-icon {
  position: absolute;
  width: 44px;
  height: 44px;
  inset: 7px 7px 7px auto;
}

.admin-courses .courses-folders .search-icon .fa-search {
  font-size: 39px;
  color: #7e828f;
}

.admin-courses .courses-list #coursesTabContent {
  background: #ffffff;
  box-shadow: 0px 3px 17px #c7dcda;
  border-radius: 4px;
  padding: 8px 16px 4px 16px;
  margin-top: 45px;
  margin-bottom: 100px;
  max-height: 516px;
  overflow-y: scroll;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-courses .courses-list #coursesTabContent .table tr:first-child td {
  border-top: none;
}

.admin-courses .courses-list #coursesTabContent .table td:first-child {
  padding: 0;
  padding-bottom: 11px;
}

.admin-courses .courses-list #coursesTabContent .table td {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  vertical-align: baseline;
  border-top: 1px solid #cccfcf;
}

.admin-courses .courses-list #coursesTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #060136;
  margin-bottom: 0px;
}

/* .admin-courses .courses-list #coursesTabContent .table td:nth-child(2),
.admin-courses .courses-list #coursesTabContent .table td:nth-child(3) {
  word-break: break-word;
} */

.admin-courses .courses-list #coursesTabContent .btn-live,
.admin-courses .courses-list .dragDropLesson .btn-live {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  background-color: rgba(18, 174, 180, 0.4980392157);
  border-radius: 5px;
  padding: 4px 34px;
  cursor: pointer;
}

.admin-courses .courses-list #coursesTabContent .btn-live:hover {
  background-color: #12aeb4;
}

.admin-courses .courses-list #coursesTabContent .btn-live:focus {
  box-shadow: 0 0 0 0.15rem rgba(18, 174, 180, 0.4980392157);
}

.admin-courses .courses-list #coursesTabContent .delete-img,
.admin-courses .courses-list #coursesTabContent .edit-img {
  cursor: pointer;
}

.admin-courses .courses-list #coursesTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-courses .courses-list .student-queries,
.admin-student-queries .student-queries {
  background: #ffffff;
  box-shadow: 0px 3px 24px #d7f1f2;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;
}

.admin-courses .courses-list .student-queries .queries-icon {
  position: relative;
}

.admin-courses .courses-list .student-queries .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #12aeb4;
  background: #c0e0e2;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
  position: absolute;
  top: -35px;
  right: 20px;
}

.admin-courses .courses-list .student-queries .queries-icon .question-img {
  position: absolute;
  top: -30px;
}

.admin-courses .courses-list .student-queries .title,
.admin-student-queries .student-queries .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  padding-bottom: 25px;
}

.admin-courses .courses-list .student-queries .search-queries,
.admin-student-queries .student-queries .search-queries {
  position: relative;
  margin-bottom: 31px;
}

.admin-courses .courses-list .student-queries .search-queries input,
.admin-student-queries .student-queries .search-queries input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  background: #f1f1f1;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-courses .courses-list .student-queries .search-icon,
.admin-student-queries .student-queries .search-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.admin-courses .courses-list .student-queries .search-icon img,
.admin-student-queries .student-queries .search-icon img {
  width: 29px;
}

.admin-courses .courses-list .student-queries .sub-title,
.admin-student-queries .student-queries .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12aeb4;
  margin-bottom: 15px;
}

.admin-courses .courses-list .student-queries .messages-list,
.admin-student-queries .student-queries .messages-list {
  max-height: 510px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
  padding-right: 5px;
}

.admin-courses .courses-list .student-queries .messages-list::-webkit-scrollbar,
.admin-student-queries .student-queries .messages-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-courses
  .courses-list
  .student-queries
  .messages-list::-webkit-scrollbar-thumb,
.admin-student-queries
  .student-queries
  .messages-list::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-courses
  .courses-list
  .student-queries
  .messages-list::-webkit-scrollbar-track,
.admin-student-queries
  .student-queries
  .messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-courses .courses-list .student-queries .messages-list .media,
.admin-student-queries .student-queries .messages-list .media {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
}

.admin-courses .courses-list .student-queries .messages-list .media img,
.admin-student-queries .student-queries .messages-list .media img,
.admin-student-queries .message-detail .media img {
  width: 48px;
  height: 48px;
}

.admin-courses
  .courses-list
  .student-queries
  .messages-list
  .media
  .img-rounded,
.admin-student-queries .student-queries .messages-list .media .img-rounded,
.admin-student-queries .message-detail .media .img-rounded,
.admin-student-queries .message-detail .media .img-rounded {
  border-radius: 50px;
}

.admin-courses .courses-list .student-queries .messages-list .media:hover,
.admin-student-queries .student-queries .messages-list .media:hover {
  background-color: #f4f9fd;
  border-radius: 6px;
}

.admin-courses .courses-list .student-queries .messanger-name,
.admin-student-queries .student-queries .messanger-name,
.admin-student-queries .message-detail .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  color: #333244;
  margin-bottom: 5px;
}

.admin-courses .courses-list .student-queries .msg-time,
.admin-student-queries .student-queries .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #7d8592;
}

.admin-courses .courses-list .student-queries .messanger-text,
.admin-student-queries .student-queries .messanger-text,
.admin-student-queries .message-detail .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #91929e;
  margin-bottom: 5px;
}

.admin-courses .courses-list .student-queries .msg-count,
.admin-student-queries .student-queries .msg-count {
  background-color: red;
  border: 2px solid #ffffff;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

.admin-courses .course-detail .course-img {
  margin-top: 50px;
  margin-bottom: 20px;
  position: relative;
}

.admin-courses .course-detail .edit-img {
  position: absolute;
  top: -8px;
  right: -9px;
}

.admin-courses .course-detail .description-content.text > div > figure {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.admin-courses .course-detail .description-content.text > div > figure > img {
  height: 100% !important;
  width: 99% !important;
}

.admin-courses .course-detail .description-content.text > div > figure > iframe,
.admin-courses .course-detail .description-content.text > div > figure > video {
  height: 100% !important;
  width: 99% !important;
  background-color: black;
}

.admin-courses .course-detail .course-img .course-detail-img {
  height: 170px;
  border-radius: 10px;
}

.admin-courses .course-detail .course-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  background-color: #e6edf5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.admin-courses .course-detail .student-img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
}

.admin-courses .course-detail .student-img img:nth-child(1),
.admin-courses .course-detail .student-img img:nth-child(2) {
  margin-right: -14px;
}

.admin-courses .course-detail .price {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
}

.admin-courses .course-detail .price span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #0a1629;
}

.admin-courses .course-detail .course-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #7d8592;
  margin-top: 30px;
  margin-bottom: 60px;
}

#createFolderModal .scroll,
#addLessonDetailsModal .scroll,
#audioPreviewModal .scroll {
  max-height: 285px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding-right: 5px;
}

#createFolderModal .scroll::-webkit-scrollbar,
#addLessonDetailsModal .scroll::-webkit-scrollbar,
#audioPreviewModal .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#createFolderModal .scroll::-webkit-scrollbar-thumb,
#addLessonDetailsModal .scroll::-webkit-scrollbar-thumb,
#audioPreviewModal .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#createFolderModal .scroll::-webkit-scrollbar-track,
#addLessonDetailsModal .scroll::-webkit-scrollbar-track,
#audioPreviewModal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#selectFolderModal .select-folder .card .card-body .student-info,
#insideFolderModal .folder .card .card-body .student-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #7d8592;
  text-align: right;
}

#selectFolderModal
  .select-folder
  .card
  .card-body
  .student-info
  img:nth-child(1),
#insideFolderModal .folder .card .card-body .student-info img:nth-child(1),
#selectFolderModal
  .select-folder
  .card
  .card-body
  .student-info
  img:nth-child(2),
#insideFolderModal .folder .card .card-body .student-info img:nth-child(2) {
  margin-right: -7px;
}

#addLessonDetailsModal .direct-add-file {
  padding-right: 40px;
}

#addLessonDetailsModal .plus-icon {
  position: absolute;
  top: 20px;
  right: 12px;
}

#addLessonDetailsModal .uploaded-file {
  background: #c7ebeb;
  border-radius: 5px;
  padding: 4px 15px;
  position: relative;
}

#addLessonDetailsModal .uploaded-file span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #12aeb4;
}

#addLessonDetailsModal .cancel-file {
  position: absolute;
  top: -5px;
  right: 20px;
}

#uploadLessonModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 20px;
}

#addLessonDetailsModal .resources,
#audioPreviewModal .resources {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding: 10px;
}

#addLessonDetailsModal .resources .resourcesLists,
#audioPreviewModal .resources .resourcesLists {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  word-break: break-all;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

#addLessonDetailsModal .hover-info {
  position: relative;
  display: inline-block;
}

#addLessonDetailsModal .hover-info .info-icon {
  cursor: pointer;
}

#addLessonDetailsModal .info-hover {
  visibility: hidden;
  position: absolute;
  top: 35%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #c9c9c9;
  background-color: rgba(0, 0, 0, 0.7215686275);
  border-radius: 25px;
  padding: 29px 11px 23px 17px;
  width: 474px;
  text-align: justify;
}

#addLessonDetailsModal .hover-info:hover .info-hover {
  visibility: visible;
  z-index: 2;
}

/*student queries*/
.admin-student-queries .message-detail {
  background: #ffffff;
  box-shadow: 0px 3px 24px #d7f1f2;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 40px;
}

.admin-student-queries .message-detail .media img {
  width: 48px;
  height: 48px;
}

.admin-student-queries .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7d8592;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #e6ebf5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.admin-student-queries .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.admin-student-queries .message-detail .chat-messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 5px;
}

.admin-student-queries .message-detail .chat-messages .msg-time {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #7d8592;
}

.admin-student-queries .message-detail .chat-messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 5px;
}

.admin-student-queries .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15c0e6;
  background: rgba(21, 192, 230, 0.1019607843);
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.admin-student-queries .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.admin-student-queries .message-detail .chat-messages .attachment {
  border: 1px solid #d8dde6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.admin-student-queries .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0a1629;
  margin-bottom: 4px;
}

.admin-student-queries .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929e;
  margin-bottom: 0px;
}

.admin-student-queries .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.admin-student-queries .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  height: 36px;
}

/*messages*/
.admin-messages {
  background-color: #fff;
}

.admin-messages .top-header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.admin-messages .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  color: #0a1629;
  margin-bottom: 0px;
}

.admin-messages .top-header .calendar {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #12aeb4;
  background: #a0dbdd;
  border-radius: 14px;
  width: 358px;
  height: 60px;
  padding-left: 40px;
  background-image: url("../images/admin/icn-general-calendar.svg");
  background-repeat: no-repeat;
  background-position: 3% center;
  float: right;
}

.admin-messages .message-section {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.1019607843);
  border-radius: 24px;
  padding-top: 33px;
}

.admin-messages .student-queries {
  border-right: 1px solid #e8e8e8;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}

.admin-messages .student-queries .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #0a1629;
  padding-bottom: 25px;
}

.admin-messages .student-queries .search-queries {
  position: relative;
  margin-top: 31px;
  margin-bottom: 31px;
}

.admin-messages .student-queries .search-queries input {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-messages .student-queries .search-icon {
  position: absolute;
  top: 7px;
  right: 7px;
}

.admin-messages .student-queries .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #12aeb4;
  margin-bottom: 30px;
  padding-left: 20px;
}

.admin-messages .student-queries .messages-list {
  max-height: 510px;
  overflow-y: scroll;
  display: block;
  padding-right: 5px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-messages .student-queries .messages-list .media {
  padding: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(18, 174, 180, 0.1882352941);
  border-radius: 24px;
}

.admin-messages .student-queries .messages-list .media:hover,
.admin-messages .student-queries .messages-list .media.active {
  background: #ffffff;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.4901960784);
  border-radius: 24px;
}

.admin-messages .student-queries .messages-list .media img {
  width: 44px;
  height: 44px;
}

.admin-messages .student-queries .messages-list .media .img-rounded,
.admin-messages .message-detail .media .img-rounded {
  border-radius: 50px;
}

.admin-messages .student-queries .messanger-name,
.admin-messages .message-detail .messanger-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #0a1629;
  margin-bottom: 0px;
}

.admin-messages .student-queries .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #7d8592;
}

.admin-messages .student-queries .messanger-text,
.admin-messages .message-detail .messanger-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #91929e;
  margin-bottom: 0px;
}

.admin-messages .student-queries .msg-count {
  background-color: red;
  border: 2px solid #ffffff;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

.admin-messages .message-detail {
  padding: 0px 16px 16px;
  margin-bottom: 40px;
}

.admin-messages .message-detail .media img {
  width: 56px;
  height: 56px;
}

.admin-messages .chat-messages .media img {
  width: 48px;
  height: 48px;
}

.admin-messages .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7d8592;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #e6ebf5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.admin-messages .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.admin-messages .message-detail .chat-messages .messanger-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0a1629;
  margin-bottom: 5px;
}

.admin-messages .message-detail .chat-messages .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  color: #7d8592;
}

.admin-messages .message-detail .chat-messages .messanger-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(10, 22, 41, 0.7019607843);
  margin-bottom: 5px;
}

.admin-messages .message-detail .chat-messages .even .messanger-text {
  background-color: rgba(160, 219, 221, 0.231372549);
  box-shadow: 5px 5px 5px rgba(18, 174, 180, 0.1882352941);
  border-radius: 18px;
  padding: 10px;
  word-break: break-word;
  word-wrap: break-word;
  float: right;
  min-width: 50%;
}

.admin-messages .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15c0e6;
  background: rgba(21, 192, 230, 0.1019607843);
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.admin-messages .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.admin-messages .message-detail .chat-messages .attachment {
  border: 1px solid #d8dde6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.admin-messages .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0a1629;
  margin-bottom: 4px;
}

.admin-messages .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929e;
  margin-bottom: 0px;
}

.admin-messages .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.admin-messages .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background-color: transparent;
  border: none;
  height: 46px;
}

/*event*/
.admin-event {
  margin-left: 50px;
  margin-right: 50px;
}

.admin-event .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0a1629;
  margin-bottom: 22px;
}

.admin-event .top-buttons {
  margin-bottom: 40px;
}

.admin-event .top-buttons .member-search {
  position: relative;
  margin-right: 15px;
}

.admin-event .top-buttons .member-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding-right: 64px;
  width: 324px;
  height: 56px;
  margin-bottom: 16px;
}

.admin-event .top-buttons .search-icon {
  position: absolute;
  width: 40px;
  inset: 8px 8px 8px auto;
}

.admin-event .top-buttons .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 212px;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.admin-event .calendar {
  margin-bottom: 25px;
}

.admin-event .calendar .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #1d2445;
  margin-bottom: 32px;
}

.admin-event .calendar .day {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #a0dbdd;
  background-color: rgba(18, 174, 180, 0.1568627451);
  border-radius: 12px;
  padding: 15px 10px;
  text-align: center;
  width: 48px;
  height: 75px;
  margin: 0 5px;
  margin-bottom: 10px;
}

.admin-event .calendar .day.active {
  background-color: #12aeb4;
  color: #fff;
}

.admin-event .upcoming-event {
  @include wisdome-shadow;
  background: #ffffff;
  border-radius: 24px;
  padding: 16px;
  margin-bottom: 20px;
}

.admin-event .upcoming-event .title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  margin-bottom: 24px;
}

.admin-event .upcoming-event .event-list {
  border-left: 4px solid #12aeb4;
  padding-left: 20px;
  margin-bottom: 24px;
}

.admin-event .upcoming-event .event-list:last-child {
  margin-bottom: 0px;
}

.admin-event .upcoming-event .event-list .event-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 0px;
}

.admin-event .upcoming-event .event-list .event-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.27px;
  color: #343434;
  margin-bottom: 15px;
}

.admin-event .upcoming-event .event-list .event-title img,
.admin-event .upcoming-event .event-list .event-text {
  cursor: pointer;
}

.admin-event .upcoming-event .event-list .event-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.admin-event .upcoming-event .event-list .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #12aeb4;
  margin-bottom: 0px;
}

#addEventModal .modal-content,
#viewEventModal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
}

#addEventModal .modal-title,
#viewEventModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: #3c3d43;
}

#addEventModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 10px;
}

#addEventModal textarea.form-control,
#addEventModal textarea.form-control:focus {
  background-color: #ebf8ff;
  color: #222;
  border-color: #ebf8ff;
  font-size: 14px;
  line-height: 21px;
  height: 143px;
}

#addEventModal .upload-video-file {
  border: 2px dashed #82dadc;
  border-radius: 18px;
  background-color: #f2ffff;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 190px;
  position: relative;
}

#addEventModal .upload-video-file .upload-video-file__btn--delete {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background-color: #fff;
  border-radius: 12px;
  padding: 1.3em;
  margin: 0.5em 0.5em 0 0;
}

#addEventModal .upload-video-file label span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #bebebe;
}

#addEventModal .date {
  background-image: url("../images/admin/calendar-sm.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#addEventModal .time {
  background-image: url("../images/admin/icn-general-time-gray.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#addEventModal .attach-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
}

#addEventModal .attach-info .info {
  visibility: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #a6a7ae;
  width: 160px;
  position: absolute;
  top: -30px;
  left: 30px;
}

#addEventModal .attach-info:hover .info {
  visibility: visible;
}

#addEventModal .repeat,
#addEventModal .audience {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 15px;
  line-height: 23px;
  padding: 10px 16px;
}

#addEventModal .scroll {
  max-height: 214px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding-right: 5px;
}

#addEventModal .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

#addEventModal .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#addEventModal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#addEventModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 123px;
  height: 48px;
  margin: 20px 0px 10px 0px;
}

#viewEventModal .modal-header .edit {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #12aeb4;
  letter-spacing: -0.34px;
  cursor: pointer;
}
#viewEventModal .modal-header .share {
  border: 1px solid var(--brand-color);
  background: var(--brand-color);
  color: white;
  border-radius: 6px;
  padding: 1px 2px;
  margin-right: 0.5rem;
  cursor: pointer;
}

#viewEventModal .event-img {
  position: relative;
}

#viewEventModal .event-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#viewEventModal .event-img .event-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.53px;
  color: #ffffff;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

#viewEventModal .event-date,
#viewEventModal .event-type {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  padding-left: 2px;
  margin-bottom: 20px;
}

#viewEventModal .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #12aeb4;
}

#viewEventModal .participants .title,
#viewEventModal .event-description .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
}

#viewEventModal .event-description .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7886;
  word-break: break-word;
  margin-bottom: 20px;
}

#viewEventModal .participants img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #ccc;
}

#viewEventModal .participants .name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  text-align: center;
  margin-bottom: 0px;
}

#viewEventModal .comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
  letter-spacing: -0.27px;
  margin-top: 23px;
}

#viewEventModal .comments .comment-list {
  max-height: 330px;
  overflow-y: scroll;
  display: block;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

#viewEventModal .comments .comment-list .media {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

#viewEventModal .comments .comment-list .media:hover {
  background-color: #f4f9fd;
}

#viewEventModal .comments .comment-list .user-img {
  width: 26px;
  height: 26px;
}

#viewEventModal .comments .comment-list .comment-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #12aeb4;
  margin-bottom: 0px;
}

#viewEventModal .comments .comment-list .comment-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7886;
  margin-bottom: 0px;
}

#viewEventModal .comments .comment-list .date-time,
#viewEventModal .comments .comment-list .reply {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a7bad4;
}

#viewEventModal .comments .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding: 2px 6px;
  margin-top: 20px;
  margin-bottom: 15px;
}

#viewEventModal .comments .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  background-color: transparent;
  border: none;
  height: 34px;
}

#viewEventModal .comments .new-msg img:first-child {
  width: 20px;
}

#viewEventModal .comments .new-msg img:last-child {
  width: 34px;
}

.rbc-toolbar button {
  border-radius: 14px;
}

.rbc-toolbar button:hover {
  color: #fff;
  background-color: #8fd0d5;
  border-color: #8fd0d5;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:focus {
  color: #fff;
  background-color: #8fd0d5;
  border-color: #8fd0d5;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #fff;
  background-color: #8fd0d5;
  border-color: #8fd0d5;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #56afb4;
}

.rbc-show-more {
  color: #56afb4;
}

.rbc-month-view {
  @include wisdome-shadow;
  border-radius: 24px;
}

.rbc-month-row:last-child .rbc-row-bg {
  border-bottom-right-radius: 24px;
}

.admin-event .upcoming-event .scroll {
  max-height: 320px;
  overflow-y: scroll;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*membership library*/
.admin-membership {
  background-color: #ebf7f6;
}

.admin-membership .content-header .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 55px;
  color: #060136;
  margin-top: 48px;
  margin-bottom: 21px;
}

.admin-membership .library-box {
  background-image: url("../images/admin/membership-box-bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  width: 253px;
  min-height: 277px;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.4941176471);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  text-align: center;
  margin-right: 31px;
  margin-bottom: 30px;
}

.admin-membership .library-box img {
  width: 96px;
  height: 96px;
  margin-bottom: 62px;
}

.admin-membership .library-box label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-membership .library-box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 10px;
}

.admin-membership .library-box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-membership .scheduled-content {
  margin-bottom: 25px;
}

.admin-membership .scheduled-content .title,
.admin-membership .most-favourite .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 29px;
  line-height: 43px;
  color: #333244;
  margin-bottom: 29px;
}

.admin-membership .scheduled-content label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-membership .scheduled-content .form-control {
  width: 220px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #ffffff;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-membership .scheduled-content .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  background: #c7ebeb;
  border-radius: 21px;
  padding: 10px 32px;
  margin-right: 23px;
  margin-bottom: 35px;
}

.admin-membership .scheduled-content .btn-custom:hover {
  color: #ffffff;
  background: #12aeb4;
}

.admin-membership .scheduled-content .content-box {
  width: 218px;
  position: relative;
  margin-right: 25px;
  margin-bottom: 25px;
}

.admin-membership .scheduled-content .content-box .content-overlay {
  position: absolute;
  top: 0;
  display: none;
  width: 220px;
  height: 190px;
}

.admin-membership .scheduled-content .content-box:hover .content-overlay {
  display: block;
}

.admin-membership .scheduled-content .content-box .content-img {
  margin-bottom: 13px;
  width: 219px;
  height: 189px;
  border-radius: 6px;
}

.admin-membership .scheduled-content .content-box .content-over-center {
  position: absolute;
  top: 70px;
  left: 75px;
}

.admin-membership .scheduled-content .content-box .content-over-bottom {
  position: absolute;
  top: 155px;
  right: 15px;
}

.admin-membership .scheduled-content .content-box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
}

.admin-membership .scheduled-content .content-box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 9px;
}

.admin-membership .scheduled-content .content-box .visibile,
.admin-membership .scheduled-content .content-box .date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7d8592;
  margin-bottom: 9px;
}

.admin-membership .most-favourite .content-box {
  margin-bottom: 22px;
}

.admin-membership .most-favourite .content-box .content-img {
  width: 88px;
  height: 90px;
  margin: 10px;
  border-radius: 3px;
}

.admin-membership .most-favourite .content-box .content-over-center {
  position: absolute;
  top: 30px;
  left: 50px;
  width: 35px;
  height: 57px;
}

.admin-membership .most-favourite .content-box .content-overlay {
  position: absolute;
  top: 0;
  display: none;
  border-radius: 3px;
}

.admin-membership .most-favourite .content-box:hover .content-overlay {
  display: block;
}

.admin-membership .most-favourite .content-box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
}

.admin-membership .most-favourite .content-box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 9px;
}

.admin-membership .most-favourite .content-box .visibile,
.admin-membership .most-favourite .content-box .date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7d8592;
  margin-bottom: 9px;
}

/*add new album modal*/
#addAlbumModal .modal-content,
#albumWantToCreateModal .modal-content {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 31px;
}

#addAlbumModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  padding-left: 40px;
}

#addAlbumModal .modal-body {
  padding: 28px 60px;
}

#addAlbumModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

#addAlbumModal .color-red {
  color: #f72b4a;
}

#addAlbumModal .form-check-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #162333;
}

#addAlbumModal .available {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#addAlbumModal .date {
  background-image: url("../images/admin/calendar (8).svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#addAlbumModal .btn-custom,
#albumWantToCreateModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
  padding: 10px 32px;
  margin-top: 60px;
  margin-bottom: 34px;
  width: 330px;
}

/*album Want To Create Modal*/
#albumWantToCreateModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  padding-left: 21px;
}

#albumWantToCreateModal .modal-body {
  padding: 58px 37px 24px;
}

#albumWantToCreateModal .btn-select {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3c3d43;
  background: #ffffff;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border: 1px solid #e2e2e2;
  border-radius: 9px;
  width: 227px;
  height: 58px;
  margin-right: 30px;
  margin-bottom: 54px;
}

#albumWantToCreateModal .btn-select:hover,
#albumWantToCreateModal .btn-select.active,
#albumWantToCreateModal .btn-select:active,
#albumWantToCreateModal .btn-select:focus {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
}

#albumWantToCreateModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3c3d43;
  margin-bottom: 34px;
}

#albumWantToCreateModal .location {
  margin-right: 28px;
}

#albumWantToCreateModal .location-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #a6a7ae;
  margin-top: 14px;
}

/*Membership Settings*/
.admin-membership-setting .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
  margin-top: 63px;
  margin-bottom: 36px;
}

.admin-membership-setting .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.admin-membership-setting .calendar {
  margin-top: 63px;
  margin-bottom: 36px;
  display: flex;
  justify-content: end;
}

.admin-membership-setting .calendar input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #8b8b8b;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 9px;
  margin-left: 14px;
  width: 303px;
  height: 56px;
}

.admin-membership-setting .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 29px;
  line-height: 43px;
  color: #333244;
}

.admin-membership-setting .member-box {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.4862745098);
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 9px;
  width: 177px;
  height: 207px;
  margin-right: 47px;
  margin-bottom: 35px;
}

.admin-membership-setting .member-box.hide {
  opacity: 0.68;
}

.admin-membership-setting .member-box label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7d8592;
  margin-bottom: 0px;
}

.admin-membership-setting .member-box img {
  margin-bottom: 46px;
  width: 71px;
  height: 71px;
}

.admin-membership-setting .member-box .box-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
}

.admin-membership-setting .deliverables-box {
  padding-right: 520px;
}

/* Membership Settings tabs */
.admin-membership-setting .nav-pills {
  border-radius: 6px;
  height: 100%;
}

.admin-membership-setting .nav-pills .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333244;
  background-color: transparent;
  padding-left: 21px;
}

.admin-membership-setting .nav-pills .nav-link.active img {
  visibility: visible !important;
}

.admin-membership-setting .nav-pills .nav-link.active {
  background-color: transparent;
  color: #ffffff;
}

.admin-membership-setting .tab-content .tab-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 61px;
  color: #3c3d43;
  margin-bottom: 26px;
}

.admin-membership-setting .AccountDetails .edit-user-img {
  position: relative;
}

.admin-membership-setting .AccountDetails .user-img {
  width: 160px;
  height: 160px;
  border: 10px solid rgba(0, 174, 179, 0.13);
  border-radius: 80px;
}

.admin-membership-setting .AccountDetails .edit-button {
  position: absolute;
  top: -20px;
  left: 100px;
  cursor: pointer;
}
.admin-membership-setting .tab-content .branding .form-control,
.admin-membership-setting .tab-content .branding input.form-control:focus,
.admin-membership-setting .tab-content .branding .input-group-text {
  background: white;
  border-radius: 4px;
}
.admin-membership-setting .tab-content .branding .form-control:disabled,
.admin-membership-setting .tab-content .branding .form-control[readonly] {
  background-color: #ffffff;
}
.admin-membership-setting .tab-content .form-control,
.admin-membership-setting .tab-content .form-control:focus,
.admin-membership-setting .tab-content .input-group-text {
  background-color: #ebf7f6;
  border-radius: 4px;
}

.admin-membership-setting .tab-content .form-control:disabled,
.admin-membership-setting .tab-content .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.admin-membership-setting .AccountDetails .plan {
  background: #ebf7f6;
  border-radius: 4px;
  margin-right: 38px;
  margin-bottom: 47px;
  width: 524px;
}

.admin-membership-setting .AccountDetails .plan .card-body,
.admin-membership-setting .AccountDetails .explore-plans .card-body {
  padding: 26px;
}

.admin-membership-setting .AccountDetails .plan .plan-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 0px;
}

.admin-membership-setting .AccountDetails .plan .badge-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  color: #054143;
  background-color: #c7ebeb;
  padding: 0px 20px;
}

.admin-membership-setting .AccountDetails .plan .plan-price {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #0d7174;
  margin-bottom: 0px;
}

.admin-membership-setting .AccountDetails .plan .plan-detail {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #5a5a5a;
  padding-top: 48px;
  margin-bottom: 0px;
  text-align: justify;
}

.admin-membership-setting .AccountDetails .explore-plans {
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(18, 174, 180, 0.1607843137);
  border: 1px solid #12aeb4;
  border-radius: 14px;
  margin-right: 38px;
  margin-bottom: 47px;
  width: 524px;
}

.admin-membership-setting .AccountDetails .explore-plans .plan-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #12aeb4;
  margin-bottom: 0px;
}

.admin-membership-setting .AccountDetails .explore-plans .plan-price {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #12aeb4;
  margin-bottom: 0px;
}

.admin-membership-setting .AccountDetails .explore-plans .plan-detail {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #5a5a5a;
  padding-top: 18px;
  margin-bottom: 0px;
  text-align: justify;
}

.admin-membership-setting .AccountDetails .explore-plans .card-footer {
  background-color: transparent;
}

.admin-membership-setting .AccountDetails .explore-plans .btn-plan {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
  padding: 0px;
  height: 32px;
}

.admin-membership-setting .AccountDetails .explore-plans.selected,
.admin-membership-setting .AccountDetails .explore-plans:active,
.admin-membership-setting .AccountDetails .explore-plans:hover {
  box-shadow: 0px 3px 15px rgba(18, 174, 180, 0.1607843137);
  border: 1px solid #0d7174;
}

.admin-membership-setting .AccountDetails .explore-plans.selected .plan-name,
.admin-membership-setting .AccountDetails .explore-plans.selected .plan-detail,
.admin-membership-setting .AccountDetails .explore-plans.selected .plan-price,
.admin-membership-setting .AccountDetails .explore-plans:active .plan-name,
.admin-membership-setting .AccountDetails .explore-plans:active .plan-price,
.admin-membership-setting .AccountDetails .explore-plans:active .plan-detail,
.admin-membership-setting .AccountDetails .explore-plans:hover .plan-price,
.admin-membership-setting .AccountDetails .explore-plans:hover .plan-name,
.admin-membership-setting .AccountDetails .explore-plans:hover .plan-detail {
  color: #0d7174;
}

.admin-membership-setting .AccountDetails .explore-plans.selected .btn-plan,
.admin-membership-setting .AccountDetails .explore-plans:active .btn-plan,
.admin-membership-setting .AccountDetails .explore-plans:hover .btn-plan {
  background: #0d7174;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
}

.admin-membership-setting .tab-content .tab-subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 47px;
  color: #3c3d43;
  margin-bottom: 36px;
}

.admin-membership-setting .CompanyDetails .color-picker {
  width: 65px;
}

.admin-membership-setting .CompanyDetails .company-info {
  /* background-color: #7284CC; */
  border: 1px solid #e3e3e3;
  border-radius: 25px;
  padding: 11px;
  width: 100%;
}

.admin-membership-setting .CompanyDetails .company-info .company-name {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

.admin-membership-setting .CompanyDetails #Country {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}
.admin-membership-setting .AccountDetails select#AccountContact,
.admin-membership-setting .CompanyDetails select#AccountContact {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20%;
  color: #12aeb4;
}

.admin-membership-setting .CompanyDetails .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 290px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-membership-setting .NotificationsSettings .form-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #5a5a5a;
}

.admin-membership-setting .NotificationsSettings .form-subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 29px;
  color: #5a5a5a;
}

.admin-membership-setting .NotificationsSettings .btn-custom,
.admin-membership-setting .GeneralSettings .btn-custom,
.admin-membership-setting .WelcomePageSetting .btn-custom,
.admin-membership-setting .AccountDetails .btn-custom,
.admin-membership-setting .PolicyPages .btn-custom,
.admin-membership-setting .change-password .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #12aeb4;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(60, 118, 119, 0.4862745098);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-membership-setting .NotificationsSettings .btn-custom1,
.admin-membership-setting .GeneralSettings .btn-custom1,
.admin-membership-setting .WelcomePageSetting .btn-custom1,
.admin-membership-setting .AccountDetails .btn-custom1,
.admin-membership-setting .PolicyPages .btn-custom1,
.admin-membership-setting .change-password .btn-custom1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(60, 118, 119, 0.4862745098);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-membership-setting .NotificationsSettings .btn-custom:hover,
.admin-membership-setting .NotificationsSettings .btn-custom:active,
.admin-membership-setting .GeneralSettings .btn-custom:hover,
.admin-membership-setting .GeneralSettings .btn-custom:active,
.admin-membership-setting .WelcomePageSetting .btn-custom:hover,
.admin-membership-setting .WelcomePageSetting .btn-custom:active,
.admin-membership-setting .AccountDetails .btn-custom:hover,
.admin-membership-setting .AccountDetails .btn-custom:active,
.admin-membership-setting .PolicyPages .btn-custom:hover,
.admin-membership-setting .PolicyPages .btn-custom:active,
.admin-membership-setting .change-password .btn-custom:hover,
.admin-membership-setting .change-password .btn-custom:active {
  color: #ffffff;
  background: #12aeb4;
}

.admin-membership-setting .GeneralSettings #Country {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 94% center;
}

.admin-membership-setting .tab-content .tab-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.admin-membership-setting .PolicyPages .nav-tabs {
  background: #89d7da;
  border-radius: 16px;
  margin-bottom: 20px;
}

.admin-membership-setting .PolicyPages .nav-tabs .nav-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 65px;
  color: #ffffff;
  background-color: transparent;
  border: none;
}

.admin-membership-setting .PolicyPages .nav-tabs .nav-item.active {
  color: #12aeb4;
  border: none;
}

.admin-membership-setting .tab-content .tab-message {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 47px;
  color: #3c3d43;
  margin-bottom: 22px;
}

.admin-membership-setting .Unsubscribe .form-check-input {
  width: 20px;
  height: 20px;
}

.admin-membership-setting .Unsubscribe .form-check-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #272222;
  padding-left: 10px;
}

.admin-membership-setting .Unsubscribe .edit,
.admin-membership-setting .Unsubscribe .delete {
  cursor: pointer;
}

.admin-membership-setting .Unsubscribe .btn-link {
  color: #12aeb4;
}

.admin-membership-setting .Unsubscribe .btn-custom1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #12aeb4;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(60, 118, 119, 0.4862745098);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-membership-setting .Unsubscribe .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(60, 118, 119, 0.4862745098);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-membership-setting .Unsubscribe .btn-custom1:hover,
.admin-membership-setting .Unsubscribe .btn-custom1:active {
  color: #ffffff;
  background: #12aeb4;
}

.admin-membership-setting .hover-info {
  position: relative;
  display: inline-block;
}

.admin-membership-setting .hover-info .info-icon {
  cursor: pointer;
}

.admin-membership-setting .info-hover {
  visibility: hidden;
  position: absolute;
  top: 35%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #c9c9c9;
  background-color: rgba(0, 0, 0, 0.7215686275);
  border-radius: 25px;
  padding: 29px 11px 23px 17px;
  width: 474px;
  text-align: justify;
}

.admin-membership-setting .hover-info:hover .info-hover {
  visibility: visible;
  z-index: 2;
}

.modal ::placeholder {
  color: #c6c6c6;
}

.modal :-ms-input-placeholder {
  color: #c6c6c6;
}

.modal ::-ms-input-placeholder {
  color: #c6c6c6;
}

.modal-body #preview_img #uploaded-image {
  max-height: 180px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: auto;
}

/*********End user dashboard css start**********/
.enduser-dashboard .nav-sidebar > .nav-item .nav-icon {
  width: 21px;
}

.enduser-dashboard:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(
    .layout-top-nav
  )
  .main-header {
  background-color: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 38px rgba(193, 193, 193, 0.1607843137);
  border: 1px solid #e1e1e1;
}

.enduser-dashboard .navbar-expand .navbar-nav .nav-link {
  color: #2f4a92;
}

.enduser-dashboard .sidebar-dark-primary {
  background-color: #3f53a5;
  box-shadow: 0px 3px 39px rgba(141, 141, 141, 0.0901960784);
}

.enduser-dashboard .content-wrapper {
  background-color: #ffffff;
}

.enduser-dashboard .content-header h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  margin-bottom: 0;
}

.enduser-dashboard .content-header small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7d8592;
}

.enduser-dashboard .explore-box {
  background-color: rgba(63, 83, 165, 0.6901960784);
  text-align: center;
  margin-bottom: 46px;
}

.enduser-dashboard .explore-box .company-info {
  padding-top: 13px;
  margin-bottom: 19.73px;
}

.enduser-dashboard .explore-box .company-img {
  /*width: 117px;*/
  height: 151px;
}

.enduser-dashboard .explore-box .company-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  color: #fff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0;
}

.enduser-dashboard .explore-box .company-name small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.31px;
  color: #fff;
  text-transform: uppercase;
}

.enduser-dashboard .explore-box .explore-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 60px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  box-shadow: 0px 5px 6px #3f53a5;
  border: 1px solid #3f53a5;
  border-radius: 6px;
  width: 197.86px;
  height: 55.87px;
  display: inline-block;
  margin-bottom: 30px;
}

.enduser-dashboard .explore-box i {
  font-size: 40px;
  color: #fff;
  margin-bottom: 35px;
}

.enduser-dashboard .news {
  margin-bottom: 81px;
}

.enduser-dashboard .news .col-md-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.enduser-dashboard .news .heading,
.enduser-dashboard .latest .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 19px;
}

.enduser-dashboard .news .heading small {
  float: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2f4a92;
  padding-top: 40px;
}

.enduser-dashboard .news .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  margin-top: 19px;
  margin-bottom: 0px;
}

.enduser-dashboard .news .date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
  margin-bottom: 0px;
}

.enduser-dashboard .news .card {
  border: none;
}

.enduser-dashboard .news .card-img-top {
  margin-bottom: 7px;
  border-radius: 5px;
}

.enduser-dashboard .news .comment img,
.enduser-dashboard .news .event img,
.enduser-dashboard .news .reward img,
.enduser-dashboard .news .favourite img {
  width: 40px;
  height: 40px;
}

.enduser-dashboard .news .comment {
  position: absolute;
  top: 9px;
  left: 12px;
}

.enduser-dashboard .news .favourite {
  position: absolute;
  top: 72px;
  left: 20px;
}

.enduser-dashboard .news .favourite .fa-heart {
  color: #3f53a5;
  padding: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(19, 19, 20, 0.1019607843);
  border: 0.5px solid #f2f2f5;
  border-radius: 50px;
  font-size: 14px;
  margin-bottom: 10px;
}

.enduser-dashboard .news .comment p,
.enduser-dashboard .news .favourite p {
  font-family: "Lato-Bold", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #b2b2b6;
  margin-top: 0px;
  margin-bottom: 10px;
}

.enduser-dashboard .news .event {
  position: absolute;
  top: 9px;
  right: 12px;
}

.enduser-dashboard .news .reward {
  position: absolute;
  top: 9px;
  right: 12px;
}

.enduser-dashboard .info-card {
  margin-bottom: 89px;
}

.enduser-dashboard .info-card .card {
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(47, 74, 146, 0.7098039216);
  border: 1px solid #ebebeb;
  border-radius: 6px;
  text-align: center;
  margin: 0 28px 30px;
  width: 200px;
  height: 220px;
}

.enduser-dashboard .info-card .card .img-border {
  width: 92px;
  height: 92px;
  border-radius: 50px;
  border: 8px solid rgba(235, 96, 96, 0.7019607843);
  margin-bottom: 48px;
}

.enduser-dashboard .info-card .card .img-bg {
  background-color: #eb6060;
  border-radius: 50px;
  padding: 18px;
}

.enduser-dashboard .info-card .card .card-img-top {
  width: 40px;
  height: 40px;
}

.enduser-dashboard .info-card .card .card-body {
  padding: 26px 16px;
}

.enduser-dashboard .info-card .card .info-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
}

.enduser-dashboard .latest {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 68px;
}

.enduser-dashboard .latest .sub-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 11px;
}

.enduser-dashboard .latest #latestTab {
  border: none;
}

.enduser-dashboard .latest #latestTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #333244;
  margin-bottom: 20px;
}

.enduser-dashboard .latest #latestTab #viewAll-tab {
  color: #2f4a92;
}

.enduser-dashboard .latest #latestTab .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #2f4a92;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #2f4a92;
}

.enduser-dashboard .latest #latestTabContent {
  background-color: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding-top: 40px;
}

.enduser-dashboard #youFav .card,
.enduser-dashboard #suggEvent .card,
.enduser-dashboard #latestCont .card {
  background-color: transparent;
  width: 173px;
  margin: 0 16px;
  border: none;
}

.enduser-dashboard #youFav .card .card-img-top,
.enduser-dashboard #suggEvent .card .card-img-top,
.enduser-dashboard #latestCont .card .card-img-top {
  margin-bottom: 11px;
  width: 138px;
  height: 148px;
  border-radius: 8px;
}

.enduser-dashboard #youFav .card .badge-over,
.enduser-dashboard #suggEvent .card .badge-over,
.enduser-dashboard #latestCont .card .badge-over {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  background-color: #f8a90d;
  padding: 3px 7px;
  border-radius: 10rem;
  box-shadow: 0px 3px 10px #f8a90d;
  position: absolute;
  top: 12px;
  left: 12px;
}

.enduser-dashboard #youFav .card .img-over,
.enduser-dashboard #suggEvent .card .img-over,
.enduser-dashboard #latestCont .card .img-over {
  position: absolute;
  top: 50px;
  left: 40px;
}

.enduser-dashboard #youFav .card .title,
.enduser-dashboard #suggEvent .card .title,
.enduser-dashboard #latestCont .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 14px;
  width: 138px;
  min-height: 43px;
}

.enduser-dashboard #youFav .card .text,
.enduser-dashboard #suggEvent .card .text,
.enduser-dashboard #latestCont .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5f686f;
  margin-bottom: 18px;
}

.enduser-dashboard .calendar {
  background-color: #fff;
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 6px;
  margin-top: 65px;
  padding-bottom: 75px;
}

.enduser-dashboard .calendar .day {
  background-color: rgba(63, 83, 165, 0.1568627451);
  color: #2f4a92;
  border-radius: 4px;
  padding: 25px 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 55px;
  height: 97px;
  margin: 0 10px;
  margin-bottom: 10px;
}

.enduser-dashboard .calendar .day span:first-child {
  font-size: 16px;
  line-height: 25px;
}

.enduser-dashboard .calendar .day span:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
}

.enduser-dashboard .calendar .day.active {
  background-color: #2f4a92;
  color: #fff;
}

.enduser-dashboard .calendar .dots {
  background-color: rgba(47, 74, 146, 0.7098039216);
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0px 4px;
  font-size: 7px;
}

.enduser-dashboard .calendar .dots.active {
  background-color: #2f4a92;
}

.enduser-dashboard .calendar .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
}

.enduser-dashboard .calendar select {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background-color: #fff;
  width: 282px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 0px 20px;
}

.enduser-dashboard .suggested {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 57px;
}

.enduser-dashboard .suggested .sub-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 47px;
}

.enduser-dashboard .suggested #suggestedTab {
  border: none;
  justify-content: space-evenly;
}

.enduser-dashboard .suggested #suggestedTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  color: #999999;
  padding-bottom: 46px;
  margin-bottom: 65px;
}

.enduser-dashboard .suggested #suggestedTab .nav-link.active {
  font-size: 25px;
  line-height: 38px;
  color: #3f53a5;
  background-color: #fff;
  border: none;
  border-bottom: 4px solid #2f4a92;
}

.enduser-dashboard #all .card {
  background-color: transparent;
  width: 221px;
  margin: 0 17px 15px;
  border: none;
}

.enduser-dashboard #all .card .card-img-top {
  margin-bottom: 11px;
  width: 221px;
  height: 290px;
}

.enduser-dashboard #all .card .img-over {
  position: absolute;
  top: 12px;
  right: 17px;
}

.enduser-dashboard #all .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 17px;
  min-height: 46px;
}

.enduser-dashboard #all .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.36px;
  color: #5f686f;
  margin-bottom: 16px;
}

.enduser-dashboard #post {
  background-color: rgba(63, 83, 165, 0.1568627451);
  border-radius: 12px;
  padding: 13px 35px 33px;
}

.enduser-dashboard #post .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #0a1629;
  margin-bottom: 9px;
}

.enduser-dashboard #post .post-now {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 6px;
}

.enduser-dashboard #post .post-now textarea.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8f92a1;
  margin-bottom: 9px;
  border: 0;
}

.enduser-dashboard #post .post-now .form-control:focus {
  height: auto;
  background-color: transparent;
}

.enduser-dashboard #post .post-now select.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 34px;
  color: #7d8592;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 12px;
  background-color: #fff;
  width: 257px;
  height: 38px;
}

.enduser-dashboard #post .post-now select.form-control:focus {
  height: 38px;
  background-color: transparent;
}

.enduser-dashboard #post .title span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2f4a92;
  float: right;
}

.enduser-dashboard #post .latest-post {
  margin-bottom: 23px;
}

.enduser-dashboard #post .latest-post .card-body {
  padding: 16px 12px 10px 33px;
}

.enduser-dashboard #post .latest-post .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1e1f20;
  margin-bottom: 0;
}

.enduser-dashboard #post .latest-post .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8f92a1;
  margin-bottom: 0;
}

.enduser-dashboard #post .latest-post .course-no {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
  color: #8f92a1;
  float: right;
}

.enduser-dashboard #post .latest-post .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8f92a1;
  margin-bottom: 22px;
}

.enduser-dashboard #post .latest-post input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8f92a1;
  box-shadow: 0px -3px 6px rgba(63, 83, 165, 0.1568627451);
  border: 1px solid #d8e0f0;
  border-radius: 12px;
  background-color: #fff;
  height: 38px;
}

.enduser-dashboard #post .latest-post input.form-control:focus {
  height: 38px;
  background-color: transparent;
}

.enduser-dashboard #videos .card,
.enduser-dashboard #photos .card {
  margin: 0px 22px;
  border: none;
}

.enduser-dashboard #videos .video-img,
.enduser-dashboard #photos .photo {
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.1803921569);
  width: 308px;
  height: 218px;
  border-radius: 18px;
  margin-bottom: 28px;
}

.enduser-dashboard #videos .play-btn {
  position: absolute;
  left: 35%;
}

.enduser-dashboard #videos .side-icon,
.enduser-dashboard #photos .side-icon {
  justify-content: space-evenly;
  height: 218px;
}

.enduser-dashboard #videos .card .title,
.enduser-dashboard #photos .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 30px;
}

.enduser-dashboard #videos .card .text,
.enduser-dashboard #photos .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #5f686f;
  margin-bottom: 47px;
}

.enduser-dashboard #photos {
  margin-bottom: 18px;
}

.enduser-dashboard #courses,
.enduser-dashboard #challenges {
  margin-bottom: 56px;
}

.enduser-dashboard #courses .card,
.enduser-dashboard #challenges .card {
  width: 337px;
  margin: 0px 22px;
  margin-bottom: 32px;
  border: none;
}

.enduser-dashboard #courses .course-img,
.enduser-dashboard #challenges .course-img {
  margin-bottom: 17px;
  width: 337px;
  height: 263px;
  border-radius: 6px;
}

.enduser-dashboard #courses .user,
.enduser-dashboard #courses .like,
.enduser-dashboard #courses .lessons,
.enduser-dashboard #challenges .user,
.enduser-dashboard #challenges .like,
.enduser-dashboard #challenges .lessons {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5f5982;
  margin-bottom: 8px;
}

.enduser-dashboard #courses .timing,
.enduser-dashboard #challenges .timing {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5f686f;
  margin-bottom: 7px;
}

.enduser-dashboard #courses .title,
.enduser-dashboard #challenges .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #302d3a;
  margin-bottom: 22px;
}

.enduser-dashboard #courses .trainee span,
.enduser-dashboard #challenges .trainee span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7a7a7a;
}

.enduser-dashboard #courses .fees,
.enduser-dashboard #challenges .fees {
  font-family: "Poppins-Bold", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #3f53a5;
  margin-bottom: 0px;
}

/*end user profile pages*/
.enduser-dashboard section.profile {
  padding: 76px 38px 76px 56px;
  background-color: #ededf3;
}

.enduser-dashboard .profile #profile-tab {
  background-color: #d8ddf6;
  height: 100%;
}

.enduser-dashboard .profile #profile-tab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 65px;
  color: #333244;
  background-color: transparent;
  padding-left: 21px;
}

.enduser-dashboard .profile #profile-tab .nav-link.active,
.enduser-dashboard .profile #profile-tab .show > .nav-link {
  color: #2f4a92;
  background-color: transparent;
}

.enduser-dashboard .profile #profile-tab .nav-link.active img {
  visibility: visible !important;
}

.enduser-dashboard .profile #profile-tabContent {
  padding: 25px 52px 44px 40px;
  background-color: #fff;
}

.enduser-dashboard .profile #profile-tabContent .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 61px;
  color: #3c3d43;
  margin-bottom: 26px;
}

.enduser-dashboard .profile #account-details .user-img {
  width: 160px;
  height: 160px;
  border: 10px solid rgba(0, 174, 179, 0.13);
  border-radius: 80px;
  margin-bottom: 30px;
}

.enduser-dashboard .profile #account-details .details {
  border-bottom: 1px solid #eee;
}

.enduser-dashboard .profile #account-details .details:nth-child(5) {
  margin-bottom: 63px;
}

.enduser-dashboard .profile #account-details .details span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 65px;
  color: #bcbcbc;
}

.enduser-dashboard .profile #account-details .details label {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 65px;
  color: #3c3d43;
  margin-bottom: 0px;
}

.enduser-dashboard .profile #account-details .plans,
.enduser-dashboard .profile #account-details .explore-plans {
  background: #d8ddf6;
  box-shadow: 0px 3px 15px rgba(119, 119, 119, 0.1607843137);
  border: 1px solid #e8e8e8;
  border-radius: 15px;
  margin-right: 38px;
  margin-bottom: 47px;
  width: 524px;
}

.enduser-dashboard .profile #account-details .plans .card-body,
.enduser-dashboard .profile #account-details .explore-plans .card-body {
  padding: 34px 55px 22px 34px;
}

.enduser-dashboard .profile #account-details .plans .plan-name,
.enduser-dashboard .profile #account-details .explore-plans .plan-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 19px;
}

.enduser-dashboard .profile #account-details .plans .plan-fee,
.enduser-dashboard .profile #account-details .explore-plans .plan-fee {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 18px;
}

.enduser-dashboard .profile #account-details .plans .subsc-date,
.enduser-dashboard .profile #account-details .plans .expiry-date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5a5a5a;
  margin-bottom: 17px;
}

.enduser-dashboard .profile #account-details .plans .btn-custom,
.enduser-dashboard .profile #account-details .explore-plans .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #fff;
  background: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 261px;
  height: 42px;
  margin-top: 17px;
}

.enduser-dashboard .profile #account-details .explore-plans {
  background: #fff;
  box-shadow: 5px 10px 10px #e8e8e8;
  border: 1px solid #e8e8e8;
}

.enduser-dashboard .profile #account-details .explore-plans:hover {
  box-shadow: 5px 10px 10px #3f53a5;
  border: 1px solid #e8e8e8;
}

.enduser-dashboard .profile #account-details .explore-plans .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #5a5a5a;
}

.enduser-dashboard .profile #edit-account,
.enduser-dashboard .profile #change-password,
.enduser-dashboard .profile #notifications-settings {
  margin-bottom: 470px;
}

.enduser-dashboard .profile #edit-account .user-img {
  width: 160px;
  height: 160px;
  border: 10px solid rgba(0, 174, 179, 0.13);
  border-radius: 80px;
}

.enduser-dashboard .profile #edit-account .fa-pencil-alt {
  background: #2f4a92;
  border: 5px solid #ffffff;
  border-radius: 50px;
  padding: 17px;
  color: #fff;
  margin-top: -50px;
  margin-right: -85px;
}

.enduser-dashboard .profile #edit-account label,
.enduser-dashboard .profile #change-password label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  /*line-height: 65px;*/
  color: #333244;
  margin-bottom: 13px;
}

.enduser-dashboard .profile #edit-account .form-control,
.enduser-dashboard .profile #change-password .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #afafaf;
  background: #eeeeee;
  border-radius: 11px;
  padding-left: 49px;
  margin-bottom: 30px;
  height: 62px;
}

.enduser-dashboard .profile #edit-account .btn-white,
.enduser-dashboard .profile #change-password .btn-white,
.enduser-dashboard .profile #notifications-settings .btn-white {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3b4e9b;
  background: #fff;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 44px;
  margin-right: 40px;
}

.enduser-dashboard .profile #edit-account .btn-blue,
.enduser-dashboard .profile #change-password .btn-blue,
.enduser-dashboard .profile #notifications-settings .btn-blue {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 44px;
}

.enduser-dashboard .profile #notifications-settings .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 47px;
  color: #3c3d43;
  margin-bottom: 23px;
}

.enduser-dashboard .profile #notifications-settings .notification-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #5a5a5a;
  margin-bottom: 41px;
}

/*end user plan detail page*/
.enduser-dashboard .plan-details,
.enduser-dashboard .payment-details {
  background-color: #ededf3;
  padding: 49px;
}

.enduser-dashboard .content-header .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.enduser-dashboard .content-header .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .plan-details .card,
.enduser-dashboard .payment-details .card {
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
}

.enduser-dashboard .plan-details .plan,
.enduser-dashboard .payment-details .plan {
  background-color: #d8ddf6;
  padding: 58px 90px 40px 73px;
}

.enduser-dashboard .plan-details .plan .title,
.enduser-dashboard .payment-details .plan .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: #3c3d43;
  margin-bottom: 32px;
}

.enduser-dashboard .plan-details .plan .content,
.enduser-dashboard .payment-details .plan .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
  color: #5a5a5a;
}

.enduser-dashboard .plan-details .tc,
.enduser-dashboard .payment-details .tc {
  padding: 58px 90px 40px 73px;
}

.enduser-dashboard .plan-details .tc .text,
.enduser-dashboard .payment-details .tc .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 65px;
  color: #3c3d43;
  margin-bottom: 32px;
}

.enduser-dashboard .plan-details .tc .text a,
.enduser-dashboard .payment-details .tc .text a {
  color: #3f53a5;
}

.enduser-dashboard .plan-details .tc .text a:hover,
.enduser-dashboard .payment-details .tc .text a:hover {
  text-decoration: underline;
}

.enduser-dashboard .plan-details .tc ul {
  padding-left: 25px;
}

.enduser-dashboard .plan-details .tc ul li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #5a5a5a;
  margin-bottom: 31px;
}

.enduser-dashboard .plan-details .tc ul li::marker {
  color: #3f53a5;
  font-size: 25px;
}

.enduser-dashboard .plan-details .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background-color: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 414px;
  height: 58px;
  margin-top: 32px;
  margin-bottom: 434px;
}

.enduser-dashboard .payment-details .card-detail .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 17px;
  line-height: 26px;
  color: #666666;
  margin-bottom: 25px;
}

.enduser-dashboard .payment-details .card-detail label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3f53a5;
  margin-bottom: 33px;
}

.enduser-dashboard .payment-details .card-detail .form-group {
  margin-bottom: 33px;
}

.enduser-dashboard .payment-details .card-detail .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: #eeeeee;
  color: #b4b4b4;
  padding-left: 32px;
  height: 63px;
}

.enduser-dashboard .payment-details .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background-color: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 414px;
  height: 58px;
  margin-top: 111px;
  margin-bottom: 269px;
}

#paymentSuccessfull .close,
#paymentDone .close {
  opacity: 1;
}

#paymentDone .modal-header {
  padding: 32px 25px;
}

#paymentDone .modal-content {
  border: 1px solid #d8d8d8;
  border-radius: 15px;
}

#paymentDone .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0c0a08;
  margin-bottom: 24px;
}

#paymentDone .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #a6a7ae;
  margin-bottom: 43px;
}

/*Audio library css start*/
.enduser-dashboard .audio-library .playlist .title,
.enduser-dashboard .video-library .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.enduser-dashboard .audio-library .playlist .name,
.enduser-dashboard .video-library .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-library .playlist .left-img,
.enduser-dashboard .video-library .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.enduser-dashboard .audio-library .playlist .right-img,
.enduser-dashboard .video-library .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-library .discover,
.enduser-dashboard .video-library .discover {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .discover .border-line,
.enduser-dashboard .video-library .discover .border-line {
  margin-bottom: 34px;
}

.enduser-dashboard .audio-library .discover .border-line .title,
.enduser-dashboard .video-library .discover .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .discover .border-line label,
.enduser-dashboard .video-library .discover .border-line label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .discover .border-line .form-control,
.enduser-dashboard .video-library .discover .border-line .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #e0e3f0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .audio-library .discover .border-line::after,
.enduser-dashboard .video-library .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 65%;
}

.enduser-dashboard .audio-library .discover-slider .text-over,
.enduser-dashboard .video-library .discover-slider .text-over {
  position: absolute;
  top: 75px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.enduser-dashboard .audio-library .discover-slider .owl-stage-outer,
.enduser-dashboard .video-library .discover-slider .owl-stage-outer {
  padding: 20px 0px;
}

.enduser-dashboard .audio-library .discover-slider .item img,
.enduser-dashboard .video-library .discover-slider .item img {
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
}

.enduser-dashboard .audio-library .discover-slider .item img:hover,
.enduser-dashboard .video-library .discover-slider .item img:hover {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1803921569);
  border-radius: 7px;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  transform: scale(1.05);
}

.enduser-dashboard .audio-library .discover-slider .owl-dots .owl-dot span,
.enduser-dashboard .video-library .discover-slider .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: #e5e5e5;
}

.enduser-dashboard
  .audio-library
  .discover-slider
  .owl-dots
  .owl-dot.active
  span,
.enduser-dashboard
  .audio-library
  .discover-slider
  .owl-dots
  .owl-dot:hover
  span,
.enduser-dashboard
  .video-library
  .discover-slider
  .owl-dots
  .owl-dot.active
  span,
.enduser-dashboard
  .video-library
  .discover-slider
  .owl-dots
  .owl-dot:hover
  span {
  background: #2f4a92;
}

.enduser-dashboard .audio-library .new-audio,
.enduser-dashboard .video-library .new-video {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .new-audio .border-line,
.enduser-dashboard .video-library .new-video .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .new-audio .border-line .title,
.enduser-dashboard .video-library .new-video .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .new-audio .border-line::after,
.enduser-dashboard .video-library .new-video .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .new-audio .card,
.enduser-dashboard .video-library .new-video .card {
  background-color: transparent;
  width: 138px;
  min-height: 272px;
  margin: 0 7px;
  border: none;
  cursor: pointer;
}

.enduser-dashboard .audio-library .new-audio .card .card-img-top,
.enduser-dashboard .video-library .new-video .card .card-img-top {
  width: 138px;
  height: 148px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6470588235);
  border-radius: 10px;
  margin-bottom: 15px;
}

.enduser-dashboard .audio-library .new-audio .card .card-img-top:hover,
.enduser-dashboard .video-library .new-video .card .card-img-top:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6470588235);
}

.enduser-dashboard .audio-library .new-audio .card .title,
.enduser-dashboard .video-library .new-video .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #010004;
  margin-bottom: 4px;
  min-height: 43px;
}

.enduser-dashboard .audio-library .new-audio .card .text,
.enduser-dashboard .video-library .new-video .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929aa5;
}

.enduser-dashboard .audio-library .popular-folder,
.enduser-dashboard .video-library .popular-folder {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .popular-folder .border-line,
.enduser-dashboard .video-library .popular-folder .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .popular-folder .border-line .title,
.enduser-dashboard .video-library .popular-folder .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .popular-folder .border-line::after,
.enduser-dashboard .video-library .popular-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 78%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .popular-folder .card,
.enduser-dashboard .video-library .popular-folder .card {
  background-color: transparent;
  width: 138px;
  margin: 0 12px;
  border: none;
}

.enduser-dashboard .audio-library .popular-folder .card .card-img-top,
.enduser-dashboard .video-library .popular-folder .card .card-img-top {
  width: 132px;
  height: 111px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .audio-library .popular-folder .card .card-img-top1,
.enduser-dashboard .video-library .popular-folder .card .card-img-top1 {
  width: 132px;
  height: 111px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .audio-library .popular-folder .card .title,
.enduser-dashboard .video-library .popular-folder .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #010004;
  margin-bottom: 25px;
}

.enduser-dashboard .audio-library .audios,
.enduser-dashboard .video-library .videos {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .audios .border-line,
.enduser-dashboard .video-library .videos .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .audios .border-line .title,
.enduser-dashboard .video-library .videos .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios .border-line::after,
.enduser-dashboard .video-library .videos .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .audios #audioTab,
.enduser-dashboard .video-library .videos #videoTab {
  border: none;
}

.enduser-dashboard .audio-library .audios .nav-link,
.enduser-dashboard .video-library .videos .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .audios .nav-link.active,
.enduser-dashboard .video-library .videos .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2f4a92;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #2f4a92;
}

.enduser-dashboard .audio-library .audios #viewAll-tab,
.enduser-dashboard .video-library .videos #viewAll-tab {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2f4a92;
}

.enduser-dashboard .audio-library .audios #audioTabContent,
.enduser-dashboard .video-library .videos #videoTabContent {
  background: rgba(63, 83, 165, 0.1568627451) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 29px 37px 39px 60px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .title,
.enduser-dashboard .video-library .videos #videoTabContent .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .text,
.enduser-dashboard .video-library .videos #videoTabContent .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929aa5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .views,
.enduser-dashboard .video-library .videos #videoTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .dropdown-menu,
.enduser-dashboard .video-library .videos #videoTabContent .dropdown-menu {
  background: #ffffff;
  box-shadow: 5px 10px 10px #3f53a5;
  border: 1px solid #d6d6d6;
}

.enduser-dashboard .audio-library .audios #audioTabContent .dropdown-item,
.enduser-dashboard .video-library .videos #videoTabContent .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3f53a5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-library #audioTabContent .table-hover tbody tr:hover,
.enduser-dashboard .video-library #videoTabContent .table-hover tbody tr:hover {
  background-color: rgba(186, 193, 222, 0.2);
}

.enduser-dashboard .audio-library #audioTabContent .dropdown-item:hover,
.enduser-dashboard .video-library #videoTabContent .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-library #audioTabContent .dropdown-item.active,
.enduser-dashboard .audio-library #audioTabContent .dropdown-item:active,
.enduser-dashboard .video-library #videoTabContent .dropdown-item.active,
.enduser-dashboard .video-library #videoTabContent .dropdown-item:active {
  background-color: transparent;
}

.enduser-dashboard .audio-library .new-audio .scroll,
.enduser-dashboard .video-library .new-video .scroll {
  overflow-y: scroll;
  max-height: 540px;
  padding-top: 16px;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar-thumb,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar-track,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.enduser-dashboard .audio-library .popular-folder .scroll,
.enduser-dashboard .video-library .popular-folder .scroll {
  overflow-y: scroll;
  max-height: 515px;
}

.enduser-dashboard .audio-library .popular-folder .scroll::-webkit-scrollbar,
.enduser-dashboard .video-library .popular-folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard
  .audio-library
  .popular-folder
  .scroll::-webkit-scrollbar-thumb,
.enduser-dashboard
  .video-library
  .popular-folder
  .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard
  .audio-library
  .popular-folder
  .scroll::-webkit-scrollbar-track,
.enduser-dashboard
  .video-library
  .popular-folder
  .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*Search css start*/
#searchModal .modal-content {
  background-color: #fff;
  box-shadow: 5px 10px 10px #3f53a5;
  border: none;
  border-radius: 10px;
  margin-bottom: 33px;
}

#searchModal .modal-body {
  padding: 0;
}

#searchModal .modal-body .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8d91a1;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding-left: 35px;
  padding-right: 65px;
  height: 72px;
}

#searchModal .modal-body .search {
  position: absolute;
  top: 18px;
  right: 20px;
}

#searchModal .modal-body .search .fa-search {
  font-size: 34px;
  color: #3f53a5;
  text-shadow: 1px 3px 5px #3b4747;
}

#searchModal .result {
  border-bottom: 1px solid #ccc;
}

#searchModal .result .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #a8a8a8;
  padding-left: 11px;
  margin-top: 26px;
  margin-bottom: 25px;
}

#searchModal .result .list {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8d91a1;
  padding-left: 11px;
  margin-bottom: 19px;
}

#searchModal .result .list:last-child {
  margin-bottom: 30px;
}

#searchModal .scroll {
  overflow-y: scroll;
  max-height: 362px;
}

#searchModal .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#searchModal .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

#searchModal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*create playlist css start*/
#createPlaylistModal .modal-content {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 12px;
}

#createPlaylistModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
}

#createPlaylistModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333244;
  background-color: #eeeeee;
  padding-left: 49px;
  border-radius: 10px;
}

#createPlaylistModal input.form-control {
  height: 107px;
}

#createPlaylistModal textarea.form-control {
  height: 306px;
  padding-top: 30px;
  margin-bottom: 45px;
}

#createPlaylistModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  background: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  color: #fff;
  width: 100%;
  height: 83px;
  margin-bottom: 21px;
}

#createPlaylistModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #afafaf;
  margin-bottom: 46px;
}

/*Add playlist css start*/
#addPlaylistModal .modal-content {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 12px;
}

#addPlaylistModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 55px;
  color: #333244;
}

#addPlaylistModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  background: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  color: #fff;
  width: 269px;
  height: 89px;
  margin-top: 24px;
  margin-bottom: 54px;
}

#addPlaylistModal .playlist-img {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6470588235);
  border-radius: 10px;
  width: 142px;
  height: 133px;
}

#addPlaylistModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  color: #2f4a92;
}

#addPlaylistModal .add {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  width: 61px;
  height: 52px;
  text-align: center;
}

#addPlaylistModal .add .fa-plus {
  font-size: 22px;
  line-height: 44px;
  color: #2f4a92;
}

#addPlaylistModal .your-playlist {
  margin-bottom: 35px;
}

.multiPurposePopup .modal-dialog {
  top: 30%;
}
.multiPurposePopup .modal-content {
  border-radius: 10px;
}
.multiPurposePopup .modal-header {
  justify-content: center;
  gap: 1rem;
}

/*my playlist page css start*/
.enduser-dashboard .my-playlist {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .my-playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .my-playlist .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .my-playlist .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .my-playlist .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .my-playlist .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.enduser-dashboard .my-playlist .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .my-playlist .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .my-playlist .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .my-playlist .playlist-board {
  background-color: #2f4a92;
  border-radius: 15px;
  padding: 22px 25px 37px 42px;
  margin-bottom: 51px;
}

.enduser-dashboard .my-playlist .playlist-board .upload-file {
  position: unset;
  border: 10px solid rgba(255, 255, 255, 0.56);
  border-radius: 25px;
  margin-right: 76px;
}

.enduser-dashboard .my-playlist .playlist-board #choose-file {
  display: none;
  visibility: hidden;
}

.enduser-dashboard .my-playlist .playlist-board #preview_img {
  background-color: #ffffff;
  border-radius: 15px;
  width: 186px;
  height: 164px;
}

.enduser-dashboard .my-playlist .playlist-board .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 39px;
  line-height: 59px;
  color: #ffffff;
  margin-bottom: 10px;
}

.enduser-dashboard .my-playlist .playlist-board .desc {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 0px;
}

.enduser-dashboard .my-playlist .playlist-board .fa-ellipsis-h {
  color: #fff;
  font-size: 50px;
  line-height: 0.5;
}

.enduser-dashboard .my-playlist .find .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 30px;
}

.enduser-dashboard .my-playlist .search input {
  background: #ffffff;
  box-shadow: 5px 10px 10px #3f53a5;
  height: 58px;
}

.enduser-dashboard .my-playlist .search .fa-search {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 34px;
  color: #3f53a5;
  text-shadow: 1px 3px 5px #3b4747;
}

/*audio folder page css start*/
.enduser-dashboard .audio-folder {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-folder .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .audio-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 78%;
  margin-left: 20px;
}

.enduser-dashboard .audio-folder .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .audio-folder .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.enduser-dashboard .audio-folder .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .audio-folder .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-folder .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-folder .folder-info {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-folder .folder-info {
  margin-bottom: 34px;
}

.enduser-dashboard .audio-folder .folder-info .info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .audio-folder .folder-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .audio-folder .folder-info .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #e0e3f0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .audio-folder .audio-folders .card {
  background-color: transparent;
  width: 250px;
  margin: 0 16px 77px;
  text-align: center;
}

.enduser-dashboard .audio-folder .audio-folders .card-img-top {
  width: 236px;
  height: 198px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .audio-folder .audio-folders .card-img-top1 {
  width: 236px;
  height: 198px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .audio-folder .audio-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #010004;
  margin-top: 27px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-folder .audio-folders .file-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-folder .audio-folders .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

/*audio top result page css start*/
.enduser-dashboard .audio-top-result,
.enduser-dashboard .video-top-result {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-top-result .title,
.enduser-dashboard .video-top-result .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-top-result .title a,
.enduser-dashboard .video-top-result .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .audio-top-result .border-line::after,
.enduser-dashboard .video-top-result .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .playlist,
.enduser-dashboard .video-top-result .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .audio-top-result .playlist .title,
.enduser-dashboard .video-top-result .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.enduser-dashboard .audio-top-result .playlist .left-img,
.enduser-dashboard .video-top-result .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .audio-top-result .playlist .name,
.enduser-dashboard .video-top-result .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-top-result .playlist .right-img,
.enduser-dashboard .video-top-result .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-top-result .audio-list .card {
  background-color: transparent;
  width: 429px;
  padding: 32px 70px 28px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-top-result .audio-list .card:hover {
  box-shadow: 0px 3px 27px #3f53a5;
  border-radius: 7px;
}

.enduser-dashboard .audio-top-result .audio-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 35px;
}

.enduser-dashboard .audio-top-result .audio-list .card .audio-img {
  width: 250px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-top-result .audio-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 180px;
  left: 190px;
}

.enduser-dashboard .audio-top-result .audio-list .card .fav-icon {
  width: 28px;
  height: 25px;
  float: right;
  margin: 0 20px 0px 0;
}

.enduser-dashboard .audio-top-result .audio-list .card .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-list .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-top-result .audio-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .folders .title,
.enduser-dashboard .video-top-result .folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .folders .border-line::after,
.enduser-dashboard .video-top-result .folders .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 90%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .folders .card {
  background-color: transparent;
  width: 218px;
  margin: 25px 11px 38px;
  padding-top: 38px;
  padding-bottom: 35px;
}

.enduser-dashboard .audio-top-result .folders .card .card-img-top {
  width: 173px;
  height: 170px;
  border-radius: 50%;
  margin-bottom: 21px;
}

.enduser-dashboard .audio-top-result .folders .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-top-result .folders .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #7d8592;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files .view-all,
.enduser-dashboard .video-top-result .video-files .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2f4a92;
  float: right;
  margin-top: -40px;
  margin-right: 40px;
}

.enduser-dashboard .audio-top-result .audio-files .border-line::after,
.enduser-dashboard .video-top-result .video-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 88%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .audio-files .table-responsive,
.enduser-dashboard .video-top-result .video-files .table-responsive,
.enduser-dashboard .video-result-detail .video-files .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 29px 37px 39px 60px;
  margin-top: 54px;
}

.enduser-dashboard .audio-top-result .audio-files table .title,
.enduser-dashboard .video-top-result .video-files table .title,
.enduser-dashboard .video-result-detail .video-files table .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-top-result .audio-files table .text,
.enduser-dashboard .video-top-result .video-files table .text,
.enduser-dashboard .video-result-detail .video-files table .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929aa5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files table .views,
.enduser-dashboard .video-top-result .video-files table .views,
.enduser-dashboard .video-result-detail .video-files table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-menu,
.enduser-dashboard .video-top-result .video-files table .dropdown-menu,
.enduser-dashboard .video-result-detail .video-files table .dropdown-menu {
  background: #ffffff;
  box-shadow: 5px 10px 10px #3f53a5;
  border: 1px solid #d6d6d6;
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-item,
.enduser-dashboard .video-top-result .video-files table .dropdown-item,
.enduser-dashboard .video-result-detail .video-files table .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3f53a5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-top-result .audio-files .table-hover tr:hover,
.enduser-dashboard .video-top-result .video-files .table-hover tr:hover,
.enduser-dashboard .video-result-detail .video-files .table-hover tr:hover {
  background-color: rgba(186, 193, 222, 0.2);
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-item:hover,
.enduser-dashboard .video-top-result .video-files table .dropdown-item:hover,
.enduser-dashboard
  .video-result-detail
  .video-files
  table
  .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-top-result .audio-files .dropdown-item.active,
.enduser-dashboard .audio-top-result .audio-files .dropdown-item:active,
.enduser-dashboard .video-top-result .video-files .dropdown-item.active,
.enduser-dashboard .video-top-result .video-files .dropdown-item:active,
.enduser-dashboard .video-result-detail .video-files .dropdown-item.active,
.enduser-dashboard .video-result-detail .video-files .dropdown-item:active {
  background-color: transparent;
}

/*audio top result detail page css start*/
.enduser-dashboard .audio-result-detail,
.enduser-dashboard .video-result-detail {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-result-detail .title,
.enduser-dashboard .video-result-detail .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-result-detail .title a,
.enduser-dashboard .video-result-detail .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .audio-result-detail .border-line::after,
.enduser-dashboard .video-result-detail .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .audio-result-detail .audio-list .card {
  background-color: transparent;
  width: 330px;
  padding: 32px 25px 28px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-result-detail .audio-list .card:hover {
  box-shadow: 0px 3px 27px #3f53a5;
  border-radius: 7px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .audio-img {
  width: 250px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 115px;
  left: 140px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .fav-icon {
  width: 28px;
  height: 25px;
  float: right;
  margin: 0 20px 0px 0;
}

.enduser-dashboard .audio-result-detail .audio-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .now-playing .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  text-align: right;
}

.enduser-dashboard .audio-result-detail .now-playing .play-box {
  background: #2f4a92;
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 161px 0px 33px;
}

.enduser-dashboard .audio-result-detail .now-playing .play-img img {
  border: 10px solid #fff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin-top: -85px;
  float: left;
}

.enduser-dashboard .audio-result-detail .now-playing .play-controls {
  background: #ffffff;
  box-shadow: 1px 1px 15px #e1e4f1;
  border-radius: 17px;
  width: 432px;
  padding: 30px 20px 0px;
}

.enduser-dashboard .audio-result-detail .now-playing .play-controls .detail {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.32px;
  color: #646464;
  padding-top: 22px;
  margin-bottom: 6px;
  text-align: center;
}

.enduser-dashboard .audio-result-detail .folder-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .audio-result-detail .folder-files .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 29px 37px 39px 60px;
  margin-top: 10px;
}

.enduser-dashboard .audio-result-detail .folder-files table .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-result-detail .folder-files table .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929aa5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .folder-files table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .folder-files table .dropdown-menu {
  background: #ffffff;
  box-shadow: 5px 10px 10px #3f53a5;
  border: 1px solid #d6d6d6;
}

.enduser-dashboard .audio-result-detail .folder-files table .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3f53a5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-result-detail .folder-files .table-hover tr:hover {
  background-color: rgba(186, 193, 222, 0.2);
}

.enduser-dashboard
  .audio-result-detail
  .folder-files
  table
  .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-result-detail .folder-files .dropdown-item.active,
.enduser-dashboard .audio-result-detail .folder-files .dropdown-item:active {
  background-color: transparent;
}

/*video top result css start*/
.enduser-dashboard .video-library #videoTabContent .video-img,
.enduser-dashboard .video-top-result .video-files .video-img,
.enduser-dashboard .video-result-detail .video-files .video-img {
  position: relative;
}

.enduser-dashboard .video-library #videoTabContent .play-btn,
.enduser-dashboard .video-top-result .video-files .play-btn,
.enduser-dashboard .video-result-detail .video-files .play-btn {
  position: absolute;
  top: 20px;
  left: 30px;
}

.enduser-dashboard .video-top-result .video-list .card {
  background-color: transparent;
  width: 429px;
  padding: 32px 23px 28px;
  text-align: center;
  margin-right: 35px;
  margin-bottom: 46px;
}

.enduser-dashboard .video-top-result .video-list .card:hover {
  box-shadow: 0px 3px 27px #3f53a5;
  border-radius: 7px;
}

.enduser-dashboard .video-top-result .video-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 35px;
}

.enduser-dashboard .video-top-result .video-list .card .audio-img {
  width: 385px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 27px;
}

.enduser-dashboard .video-top-result .video-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 180px;
  left: 190px;
}

.enduser-dashboard .video-top-result .video-list .card .fav-icon {
  width: 28px;
  height: 25px;
  margin: 0 0px 0px 20px;
}

.enduser-dashboard .video-top-result .video-list .card .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 25px;
}

.enduser-dashboard .video-top-result .video-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .video-top-result .popular-folder {
  padding-left: 7.5px;
  margin-bottom: 38px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line {
  margin-bottom: 25px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 90%;
  margin-left: 20px;
}

.enduser-dashboard .video-top-result .popular-folder .card {
  background-color: transparent;
  width: 188px;
  margin: 0 34px;
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
}

.enduser-dashboard .video-top-result .popular-folder .card .card-img-top {
  width: 179px;
  height: 151px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .video-top-result .popular-folder .card .card-img-top1 {
  width: 179px;
  height: 151px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .video-top-result .popular-folder .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 9px;
}

.enduser-dashboard .video-top-result .popular-folder .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #7d8592;
  margin-bottom: 35px;
}

.enduser-dashboard .video-result-detail .video-files {
  margin-top: 43px;
}

.enduser-dashboard .video-result-detail .video-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 82%;
  margin-left: 20px;
}

/*video top result detail*/
.enduser-dashboard .video-result-detail .video-list .card {
  background-color: transparent;
  width: 370px;
  padding: 32px 25px 28px;
  text-align: center;
  margin-bottom: 46px;
}

.enduser-dashboard .video-result-detail .video-list .card:hover {
  box-shadow: 0px 3px 27px #3f53a5;
  border-radius: 7px;
}

.enduser-dashboard .video-result-detail .video-list .card .video-img {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 26px;
}

.enduser-dashboard .video-result-detail .video-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 145px;
  left: 155px;
}

.enduser-dashboard .video-result-detail .video-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  margin-bottom: 22px;
}

.enduser-dashboard .video-result-detail .video-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .video-result-detail .video-list .card .fav-icon {
  width: 28px;
  height: 25px;
  margin-left: 10px;
}

/*content library folder*/
.enduser-dashboard .content-wrapper.content-library-folder {
  background-color: #ededf3;
}

.enduser-dashboard .content-library-folder {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .content-library-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .content-library-folder .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .content-library-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 66%;
  margin-left: 20px;
}

.enduser-dashboard .content-library-folder .folder-info {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .content-library-folder .folder-info {
  margin-bottom: 34px;
}

.enduser-dashboard .content-library-folder .folder-info .info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .content-library-folder .folder-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .content-library-folder .content-folders {
  background-color: #fff;
  margin: 40px 42px 40px 0px;
  padding: 45px 50px;
  border-radius: 7px;
}

.enduser-dashboard .content-library-folder .content-folders .card {
  background-color: transparent;
  width: 255px;
  margin-right: 32px;
  margin-bottom: 54px;
}

.enduser-dashboard .content-library-folder .content-folders .card-img-top {
  width: 164px;
  height: 243px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.enduser-dashboard .content-library-folder .content-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library-folder .content-folders .file-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7d8592;
  margin-bottom: 9px;
}

/*contant library*/
.enduser-dashboard .content-library {
  padding-top: 37px;
  padding-left: 8px;
  margin-bottom: 48px;
}

.enduser-dashboard .content-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .content-library .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 88%;
  margin-left: 20px;
}

.enduser-dashboard .content-library .most-popular-content .card {
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
  margin-bottom: 24px;
}

.enduser-dashboard .content-library .most-popular-content .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 37px;
}

.enduser-dashboard .content-library .most-popular-content .card .slider-img {
  width: 144px;
  height: 215px;
  margin: 0 auto;
  margin-bottom: 23px;
}

.enduser-dashboard .content-library .most-popular-content .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8d91a1;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .most-popular-content .card .comm {
  width: 28px;
  height: 25px;
  margin-right: 10px;
}

.enduser-dashboard .content-library .most-popular-content .card .fav-icon {
  width: 28px;
  height: 25px;
  margin-left: 10px;
}

.enduser-dashboard .content-library .most-popular-content .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 20px;
}

.enduser-dashboard
  .content-library
  .most-popular-content
  .popular-slider
  .owl-prev {
  position: absolute;
  top: 80px;
  left: 0px;
}

.enduser-dashboard
  .content-library
  .most-popular-content
  .popular-slider
  .owl-next {
  position: absolute;
  top: 80px;
  right: 0px;
}

.enduser-dashboard
  .content-library
  .most-popular-content
  .popular-slider
  .owl-nav
  [class*="owl-"] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.enduser-dashboard .content-library .content-files .card {
  background: #ffffff;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .content-files .card-body {
  padding-bottom: 60px;
}

.enduser-dashboard .content-library .content-files .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 26px;
}

.enduser-dashboard .content-library .content-files .fa-filter {
  font-size: 15px;
  padding: 7px 9px;
  margin-left: 6px;
  color: #2f4a92;
  background: #e1e4f1;
  border-radius: 4px;
}

.enduser-dashboard .content-library .content-files .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2f4a92;
  float: right;
}

.enduser-dashboard .content-library .content-files .folder .folder-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #a8a8a8;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .content-files .folder .book {
  border-bottom: 1px solid #dedede;
  padding-top: 23px;
  padding-bottom: 31px;
}

.enduser-dashboard .content-library .content-files .folder .book:last-child {
  border-bottom: none;
}

.enduser-dashboard .content-library .content-files .folder .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
}

.enduser-dashboard .content-library .content-files .folder .book-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929aa5;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .about-book {
  background: #ffffff;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .about-book .book-views {
  background-image: url("../images/enduser/book-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 60px 95px 80px;
  margin-bottom: 44px;
}

.enduser-dashboard .content-library .about-book .book-views .book-img {
  width: 144px;
  height: 215px;
  margin-right: 32px;
}

.enduser-dashboard .content-library .about-book .book-views .side-icon {
  justify-content: center;
  height: 215px;
}

.enduser-dashboard .content-library .about-book .book-views .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  margin-bottom: 20px;
}

.enduser-dashboard .content-library .about-book .book-views .book-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #707070;
  margin-top: 18px;
}

.enduser-dashboard .content-library .about-book .media {
  margin-bottom: 19px;
}

.enduser-dashboard .content-library .about-book .left-round-img {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  border: 1px solid #f9f9f9;
}

.enduser-dashboard .content-library .about-book .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library .about-book .book-title .post-time {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2f4a92;
  float: right;
}

.enduser-dashboard .content-library .about-book .folder-files {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #3f53a5;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .about-book .book-description {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #707070;
  margin-bottom: 3px;
}

.enduser-dashboard .content-library .book-comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-top: 32px;
  margin-bottom: 24px;
}

.enduser-dashboard .content-library .book-comments .title .send {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8d91a1;
  float: right;
}

.enduser-dashboard
  .content-library
  .book-comments
  .title
  .send
  .fa-paper-plane {
  background: rgba(63, 83, 165, 0.168627451);
  border-radius: 3px;
  font-size: 20px;
  color: #2f4a92;
  padding: 9px 12px;
  margin-left: 10px;
}

.enduser-dashboard .content-library .book-comments .share-thought {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8d91a1;
  background: #f1f1f1;
  border-color: #f1f1f1;
  border-radius: 6px;
}

.enduser-dashboard .content-library .book-comments .left-round-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
}

.enduser-dashboard .content-library .book-comments {
  background: #ffffff;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .book-comments .media {
  padding: 30px 0px;
  border-bottom: 1px solid #dedede;
}

.enduser-dashboard .content-library .book-comments .media:last-child {
  border-bottom: none;
}

.enduser-dashboard .content-library .book-comments .media .name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 10px;
}

.enduser-dashboard .content-library .book-comments .media .name .time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
  float: right;
}

.enduser-dashboard .content-library .book-comments .media .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #91929e;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .folders {
  background: #ffffff;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 20px;
}

.enduser-dashboard .content-library .folders .search-course {
  position: relative;
}

.enduser-dashboard .content-library .folders .search-content input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8d91a1;
  background: #f1f1f1;
  border-radius: 6px;
  height: 36px;
  margin-bottom: 25px;
}

.enduser-dashboard .content-library .folders .search-icon {
  position: absolute;
  bottom: 8px;
  right: 20px;
}

.enduser-dashboard .content-library .folders .search-icon .fa-search {
  font-size: 12px;
  color: #7e828f;
}

.enduser-dashboard .content-library .folders .folder-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 30px;
}

.enduser-dashboard .content-library .folders .folder-info .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2f4a92;
  float: right;
}

.enduser-dashboard .content-library .folders .book {
  padding-left: 15px;
}

.enduser-dashboard .content-library .folders .book img {
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
  width: 137px;
  margin-bottom: 30px;
}

.enduser-dashboard .content-library .folders .book .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library .folders .book .desc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 26px;
}

.enduser-dashboard .content-library .folders .scroll {
  max-height: 1300px;
  overflow-y: scroll;
  display: block;
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar-thumb {
  background: #2f4a92;
  border-radius: 2px;
  height: 50px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* enduser dashboard content show */
.enduser-dashboard .show-content .template,
.enduser-dashboard .show-content .template2,
.enduser-dashboard .show-content .template3 {
  margin-bottom: 60px;
}

.enduser-dashboard .show-content .template .title,
.enduser-dashboard .show-content .template2 .title,
.enduser-dashboard .show-content .template3 .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #7284cc;
  margin-bottom: 8px;
}

.enduser-dashboard .show-content .template .text,
.enduser-dashboard .show-content .template2 .text,
.enduser-dashboard .show-content .template3 .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #929aa5;
  margin-bottom: 16px;
}

.enduser-dashboard .show-content .template img,
.enduser-dashboard .show-content .template3 img {
  width: 288px;
  height: 315px;
}

.enduser-dashboard .show-content .template2 img {
  width: 650px;
  height: 315px;
}

/*end user courses*/
.enduser-dashboard .content-header .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .courses .queries-icon {
  margin-right: 40px;
}

.enduser-dashboard .courses .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2f4a92;
  background: #e0e3f0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .courses .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .courses .playlist {
  position: relative;
}

.enduser-dashboard .courses .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  padding-bottom: 0;
}

.enduser-dashboard .courses .playlist .left-img {
  position: absolute;
  left: -20px;
  z-index: 1;
}

.enduser-dashboard .courses .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263a3b;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .courses .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 28px;
  z-index: 1;
}

.enduser-dashboard .courses .discover {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .courses .discover .border-line {
  margin-bottom: 34px;
}

.enduser-dashboard .courses .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 65%;
}

.enduser-dashboard .courses .discover .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .discover label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .discover .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8b8b8b;
  background: #e0e3f0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .courses .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 65%;
}

.enduser-dashboard .courses .discover-slider .item img {
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
}

.enduser-dashboard .courses .discover-slider .text-over {
  position: absolute;
  top: 75px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: #e5e5e5;
}

.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot.active span,
.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot:hover span {
  background: #2f4a92;
}

.enduser-dashboard .courses .your-course {
  margin-bottom: 50px;
}

.enduser-dashboard .courses .your-course .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .your-course .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .courses .your-course .search-course {
  position: relative;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  box-shadow: 0px 3px 9px #7284cc;
  border: 1px solid #e5e5e5;
  width: 394px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .courses .your-course .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .courses .your-course .filter-icon {
  margin-left: 27px;
  margin-right: 48px;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2f4a92;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .book-box {
  width: 191px;
  min-height: 146px;
  background: #ffffff;
  box-shadow: 5px 10px 10px #8794c7;
  border-radius: 24px;
  padding: 12px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .book-box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a1629;
}

.enduser-dashboard .courses .your-course .book-box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7d8592;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .your-course .book-box .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #2f4a92;
}

.enduser-dashboard .courses .your-course .scroll {
  max-height: 840px;
  overflow-y: scroll;
  display: block;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*courses view all */
.enduser-dashboard .courses .your-course #coursesTab {
  border: none;
  align-items: center;
  margin-bottom: 60px;
}

.enduser-dashboard .courses .your-course #coursesTab::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 58%;
  margin-left: 20px;
}

.enduser-dashboard .courses .your-course .nav-item {
  width: 180px;
  margin-right: 10px;
}

.enduser-dashboard .courses .your-course .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8d9296;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.enduser-dashboard .courses .your-course .nav-link.active {
  color: #3f53a5;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #3f53a5;
}

.enduser-dashboard .courses #coursesTabContent .box {
  width: 394px;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer;
}

.enduser-dashboard .courses #coursesTabContent .box:hover,
.course-detail .top-result .box:hover {
  box-shadow: 4px 5px 16px #3f53a5;
}

.enduser-dashboard .courses #coursesTabContent .box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-top: 14px;
  margin-bottom: 8px;
}

.enduser-dashboard .courses #coursesTabContent .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 11px;
}

.enduser-dashboard .courses #coursesTabContent .box .text:last-child {
  margin-bottom: 0px;
}

.enduser-dashboard .courses #coursesTabContent .box .student img {
  margin-top: -10px;
  margin-right: -5px;
  border-radius: 50px;
}

.enduser-dashboard .courses #coursesTabContent .scroll {
  max-height: 1010px;
  overflow-y: scroll;
  display: block;
}

.enduser-dashboard .courses #coursesTabContent .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard
  .courses
  #coursesTabContent
  .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard
  .courses
  #coursesTabContent
  .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*course detail page*/
.enduser-dashboard .course-detail .return-page {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.enduser-dashboard .course-detail .return-page::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 75%;
  margin-left: 20px;
}

.enduser-dashboard .course-detail .search-course {
  position: relative;
}

.enduser-dashboard .course-detail .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #e5e5e5;
  width: 520px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .course-detail .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .course-detail .detail-page .course-img {
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .detail-page .course-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  background-color: #e6edf5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.enduser-dashboard .course-detail .detail-page .student-img img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.enduser-dashboard .course-detail .detail-page .student-img img:nth-child(1),
.enduser-dashboard .course-detail .detail-page .student-img img:nth-child(2) {
  margin-right: -14px;
}

.enduser-dashboard .course-detail .detail-page .price {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7d8592;
}

.enduser-dashboard .course-detail .detail-page .price span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #0a1629;
}

.enduser-dashboard .course-detail .detail-page .course-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #7d8592;
  margin-top: 30px;
  margin-bottom: 18px;
  text-align: justify;
}

.enduser-dashboard .course-detail .detail-page .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2f4a92;
  background: #e0e3f0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .course-detail .detail-page .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .course-detail .lesson .title,
.enduser-dashboard .lesson-detail .lesson .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .course-detail .lesson .border-line::after,
.enduser-dashboard .lesson-detail .lesson .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .course-detail .lesson .search-course,
.enduser-dashboard .lesson-detail .lesson .search-course {
  position: relative;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .search-course input,
.enduser-dashboard .lesson-detail .lesson .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8d91a1;
  background: #ffffff;
  box-shadow: 0px 3px 9px #7284cc;
  border: 1px solid #e5e5e5;
  width: 394px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .course-detail .lesson .search-icon,
.enduser-dashboard .lesson-detail .lesson .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .course-detail .lesson .filter-icon,
.enduser-dashboard .lesson-detail .lesson .filter-icon {
  margin-left: 27px;
  margin-right: 27px;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .view-all,
.enduser-dashboard .lesson-detail .lesson .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2f4a92;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .table-responsive,
.enduser-dashboard .lesson-detail .lesson .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  margin-bottom: 32px;
}

.enduser-dashboard .course-detail .lesson table tr td,
.enduser-dashboard .lesson-detail .lesson table tr td {
  vertical-align: middle;
  padding: 8px;
}

.enduser-dashboard .course-detail .lesson table tr td .live-btn,
.enduser-dashboard .lesson-detail .lesson table tr td .live-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  background: #2f4a92;
  border-radius: 5px;
  padding: 2px 10px;
  width: 98px;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(1),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(2),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(1),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(3),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(5),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(7),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(3),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(5),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(8),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(8) {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2f4a92;
  width: 92px;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4) {
  width: 115px;
}

.enduser-dashboard .course-detail .lesson table .student-img,
.enduser-dashboard .lesson-detail .lesson table .student-img {
  width: 92px;
}

.enduser-dashboard .course-detail .lesson table .student-img img,
.enduser-dashboard .lesson-detail .lesson table .student-img img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.enduser-dashboard .course-detail .lesson table .student-img img:nth-child(1),
.enduser-dashboard .course-detail .lesson table .student-img img:nth-child(2),
.enduser-dashboard .lesson-detail .lesson table .student-img img:nth-child(1),
.enduser-dashboard .lesson-detail .lesson table .student-img img:nth-child(2) {
  margin-right: -14px;
}

/*lesson detail*/
.enduser-dashboard .lesson-detail .lesson-img {
  background: #ffffff;
  box-shadow: 9px 21px 41px rgba(0, 0, 0, 0.3490196078);
  border-radius: 15px;
  padding: 12px;
  margin-top: 75px;
  margin-bottom: 27px;
  position: relative;
}

.enduser-dashboard .lesson-detail .lesson-img .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333244;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 14px;
  padding: 3px 18px;
  position: absolute;
  top: 20px;
  left: 40px;
}

.enduser-dashboard .lesson-detail .student-img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.enduser-dashboard .lesson-detail .student-img img:nth-child(1),
.enduser-dashboard .lesson-detail .student-img img:nth-child(2) {
  margin-right: -14px;
}

.enduser-dashboard .lesson-detail .member {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  text-align: right;
  margin-bottom: 100px;
}

.enduser-dashboard .lesson-detail .attach-files .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #7d8592;
  margin-bottom: 24px;
}

.enduser-dashboard .lesson-detail .attach-img img {
  margin-bottom: 27px;
}

.enduser-dashboard .lesson-detail .attach-files .book-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #060136;
  margin-top: 7px;
  margin-bottom: 30px;
}

.enduser-dashboard .lesson-detail .lesson-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 35px;
  line-height: 53px;
  color: #060136;
  margin-bottom: 23px;
}

.enduser-dashboard .lesson-detail .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  margin-top: 30px;
  margin-bottom: 29px;
}

.enduser-dashboard .lesson-detail .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #7d8592;
  margin-bottom: 29px;
}

.enduser-dashboard .lesson-detail .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2f4a92;
  background: #e0e3f0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .lesson-detail .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .lesson-detail .lesson-comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 24px;
}

.enduser-dashboard .lesson-detail .lesson-comments .send {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8d91a1;
}

.enduser-dashboard .lesson-detail .lesson-comments .send .fa-paper-plane {
  background: rgba(63, 83, 165, 0.168627451);
  border-radius: 3px;
  font-size: 20px;
  color: #2f4a92;
  padding: 9px 12px;
  margin-left: 17px;
}

.enduser-dashboard .lesson-detail .lesson-comments textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8d91a1;
  background: #f1f1f1;
  border-color: #f1f1f1;
  border-radius: 6px;
  width: 80%;
}

.enduser-dashboard .lesson-detail .lesson-comments .media {
  padding: 20px 0px;
  border-bottom: 1px solid #dedede;
}

.enduser-dashboard .lesson-detail .lesson-comments .media:last-child {
  border-bottom: none;
}

.enduser-dashboard .lesson-detail .lesson-comments .left-round-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 10px;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .name .time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
  float: right;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #91929e;
  margin-bottom: 0px;
}

.enduser-dashboard .lesson-detail .hr {
  border: 4px solid #e4e4e4;
  margin-top: 27px;
  margin-bottom: 27px;
}

/*lesson detail attachment modal*/
#attachmentFileModal .modal-content {
  background: #ffffff;
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border: 1px solid #d8d8d8;
  border-radius: 30px;
}

#attachmentFileModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #3b4747;
  margin-bottom: 0px;
}

#attachmentFileModal .modal-header {
  padding: 39px 39px 16px;
}

#attachmentFileModal .modal-body {
  padding: 52px 68px;
}

#attachmentFileModal .files {
  background: #e6edf5;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 27px;
}

#attachmentFileModal .file-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #060136;
  margin-bottom: 0px;
}

#attachmentFileModal .download-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #acacac;
}

/*Courses Top result*/
.course-detail .top-result .box {
  width: 394px;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer;
}

.course-detail .top-result .lesson-img {
  position: relative;
}

.course-detail .top-result .lesson-img .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333244;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 14px;
  padding: 3px 18px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.course-detail .top-result .box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-top: 14px;
  margin-bottom: 8px;
}

.course-detail .top-result .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 11px;
}

.course-detail .top-result .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 11px;
}

.course-detail .top-result .box .student img {
  margin-top: -10px;
  margin-right: -5px;
  border-radius: 50px;
}

/*course ask question*/
.course-ask-question .course-answer {
  background: #e0e3f0;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}

.course-ask-question .course-answer .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4c565f;
  padding: 14px 29px;
}

.course-ask-question .course-answer .search-course {
  position: relative;
  margin: 14px 29px;
}

.course-ask-question .course-answer .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #4c565f;
  background: #f1f1f1;
  border-radius: 6px;
  border: none;
  height: 45px;
}

.course-ask-question .course-answer .search-course .search-icon {
  position: absolute;
  top: 8px;
  right: 15px;
}

.course-ask-question .course-answer .search-course .search-icon img {
  width: 29px;
}

.course-ask-question #courseAnswer .btn-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4c565f;
}

.course-ask-question .course-answer .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #3f53a5;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-ask-question #courseAnswer .answer-border {
  border-bottom: 1px solid #fff;
  padding: 14px 29px;
}

.course-ask-question #courseAnswer .btn-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4c565f;
}

.course-ask-question #courseAnswer .collapse p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #8b8e94;
}

.course-ask-question #courseAnswer .btn-link.collapsed::after {
  content: "\f107";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
  color: #3f53a5;
  float: right;
  margin-top: -20px;
}

.course-ask-question #courseAnswer .btn-link::after {
  content: "\f105";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
  color: #3f53a5;
  float: right;
  margin-top: -20px;
}

.course-ask-question .message-detail {
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(47, 74, 146, 0.6392156863);
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 40px;
}

.course-ask-question .message-detail .media .img-rounded {
  border-radius: 50px;
  width: 48px;
  height: 48px;
}

.course-ask-question .message-detail .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  color: #333244;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #91929e;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7d8592;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #e6ebf5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.course-ask-question .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.course-ask-question .message-detail .media .img-rounded {
  border-radius: 50px;
}

.course-ask-question .message-detail .chat-messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .chat-messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3f53a5;
  background: #e6edf5;
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.course-ask-question .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.course-ask-question .message-detail .chat-messages .attachment {
  border: 1px solid #d8dde6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.course-ask-question .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0a1629;
  margin-bottom: 4px;
}

.course-ask-question .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929e;
  margin-bottom: 0px;
}

.course-ask-question .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.course-ask-question .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  height: 36px;
}

/*multiple membership modal  */
#multiple-membership .scroll {
  max-height: 300px;
  overflow-y: scroll;
  margin-right: -10px;
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

#multiple-membership .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

#multiple-membership .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

#multiple-membership .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*end user event*/
.enduser-dashboard .enduser-event .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  margin-top: 40px;
  margin-bottom: 34px;
}

.enduser-dashboard .enduser-event .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #e4e4e5;
  width: 88%;
  margin-left: 20px;
}

.enduser-dashboard .enduser-event .event-calendar {
  margin-bottom: 25px;
}

.enduser-dashboard .enduser-event .event-calendar .calendar-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #1d2445;
  margin-bottom: 32px;
}

.enduser-dashboard .enduser-event .event-calendar .day {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #7284cc;
  background-color: rgba(74, 98, 160, 0.1490196078);
  border-radius: 12px;
  padding: 15px 10px;
  text-align: center;
  width: 48px;
  height: 75px;
  margin: 0 5px;
  margin-bottom: 10px;
}

.enduser-dashboard .enduser-event .event-calendar .day.active {
  background-color: #4a62a0;
  color: #fff;
}

.enduser-dashboard .enduser-event .event-calendar .dots {
  background-color: rgba(74, 98, 160, 0.1490196078);
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0px 4px;
  font-size: 6px;
}

.enduser-dashboard .enduser-event .event-calendar .dots.active {
  background-color: #4a62a0;
}

.enduser-dashboard .enduser-event .upcoming-event {
  background: #ffffff;
  box-shadow: 5px 10px 10px rgba(114, 132, 204, 0.5019607843);
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 20px;
}

.enduser-dashboard .enduser-event .upcoming-event .upcoming-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  margin-bottom: 24px;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list {
  border-left: 4px solid #7284cc;
  border-radius: 2px;
  padding-left: 20px;
  margin-bottom: 24px;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list:last-child {
  margin-bottom: 0px;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list .event-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  margin-bottom: 0px;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list .event-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.27px;
  color: #343434;
  margin-bottom: 15px;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list .event-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.enduser-dashboard .enduser-event .upcoming-event .event-list .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #4a62a0;
  margin-bottom: 0px;
}

.enduser-dashboard .enduser-event .view-event {
  background: #ffffff;
  border: 1px solid #7284cc;
  border-radius: 35px;
  min-height: 640px;
  margin: 0px 20px 20px;
  padding: 23px 0px 23px 83px;
}

.enduser-dashboard .enduser-event .view-event .view-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
  margin-bottom: 26px;
}

.enduser-dashboard .enduser-event .view-event .view-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #c9c9c9;
}

.enduser-dashboard .enduser-event .view-event.show {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 30px;
  border: none;
  min-height: auto;
  padding: 0px;
  padding-bottom: 50px;
}

.enduser-dashboard .enduser-event .view-event.show .view-title {
  padding-top: 19px;
  padding-left: 63px;
}

.enduser-dashboard .enduser-event .view-event .event-img {
  position: relative;
}

.enduser-dashboard .enduser-event .view-event .event-img img {
  width: 100%;
  height: 259px;
  margin-bottom: 14px;
}

.enduser-dashboard .enduser-event .view-event .event-img .event-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.53px;
  color: #ffffff;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.enduser-dashboard .enduser-event .view-event .event-info,
.enduser-dashboard .enduser-event .view-event .event-description,
.enduser-dashboard .enduser-event .view-event .participants,
.enduser-dashboard .enduser-event .view-event .comments {
  padding-left: 30px;
  padding-right: 30px;
}

.enduser-dashboard .enduser-event .view-event .event-date,
.enduser-dashboard .enduser-event .view-event .event-type {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  padding-left: 2px;
  margin-bottom: 20px;
}

.enduser-dashboard .enduser-event .view-event .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #4a62a0;
}

.enduser-dashboard .enduser-event .view-event .participants .part-title,
.enduser-dashboard .enduser-event .view-event .event-description .desc-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
}

.enduser-dashboard .enduser-event .view-event .event-description .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7886;
  margin-bottom: 20px;
}

.enduser-dashboard .enduser-event .view-event .participants img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #ccc;
}

.enduser-dashboard .enduser-event .view-event .participants .name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  text-align: center;
  margin-bottom: 0px;
}

.enduser-dashboard .enduser-event .view-event .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #3f53a5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 414px;
  height: 58px;
}

.enduser-dashboard .enduser-event .view-event .comments .comment-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
  letter-spacing: -0.27px;
  margin-top: 23px;
}

.enduser-dashboard .enduser-event .view-event .comments .comment-list {
  max-height: 330px;
  overflow-y: scroll;
  display: block;
  padding-right: 5px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list::-webkit-scrollbar-thumb {
  background: #4a62a0;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.enduser-dashboard .enduser-event .view-event .comments .comment-list .media {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list
  .media:hover {
  background-color: #f4f4f4;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list
  .user-img {
  width: 26px;
  height: 26px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list
  .comment-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #7284cc;
  margin-bottom: 0px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list
  .comment-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7886;
  margin-bottom: 0px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .comment-list
  .date-time,
.enduser-dashboard .enduser-event .view-event .comments .comment-list .reply {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a7bad4;
}

.enduser-dashboard .enduser-event .view-event .comments .new-msg {
  box-shadow: 0px 1px 2px #d8e0f0;
  border: 1px solid #d8e0f0;
  border-radius: 14px;
  padding: 2px 6px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.enduser-dashboard .enduser-event .view-event .comments .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  background-color: transparent;
  border: none;
  height: 34px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .new-msg
  img:first-child {
  width: 20px;
}

.enduser-dashboard
  .enduser-event
  .view-event
  .comments
  .new-msg
  img:last-child {
  width: 34px;
}

.modal-backdrop {
  background-color: transparent;
}

.modal-backdrop.show {
  opacity: 0.95;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}
.admin-content-library .for-drop-down .dropdown-menu,
.admin-courses.for-drop-down .dropdown-menu {
  width: 150px;
  box-shadow: -4px 5px 15px #dee2e6;
}
.admin-content-library .for-drop-down .dropdown-menu .dropdown-item,
.admin-courses.for-drop-down .dropdown-menu .dropdown-item {
  width: 150px;
  color: black;
  text-align: left;
}
.admin-content-library .for-drop-down .dropdown-item:hover,
.admin-courses.for-drop-down .dropdown-item:hover {
  color: white;
}
.admin-content-library .for-drop-down .dropdown-item:hover,
.admin-courses.for-drop-down .dropdown-item:hover > svg{
  color: white;
}
.admin-courses .email-messaging .text {
  font-weight: 350;
  margin-bottom: 45px;
}
.email-messaging .info-img {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 45px;
}
.admin-courses .courses-list .search-email {
  width: 30%;
  position: relative;
}
.admin-courses .courses-list .search-email input {
  width: 100%;
  height: 100%;
  position: absolute;
}
.admin-courses .courses-list .search-email .search-icon {
  position: absolute;
  inset: 6px 6px 6px auto;
}
.admin-courses .courses-list .search-email .search-icon > img {
  width: 30px;
}
.admin-courses .courses-list .table-responsive thead {
  color: var(--brand-color);
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid var(--brand-color);
}
.autoresponder .card-body {
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  display: flex;
  justify-content: left;
  gap: 0.5rem;
}
.autoresponder .card-body .week-count {
  background-color: #ffffff;
  border-color: #ebf7f6;
  border-radius: 3px;
  width: 35px;
  height: 30px;
  display: inline;
}
.autoresponder .card-body input::after {
  border-color: 1px solid var(--brand-color);
}
.autoresponder .last-action {
  color: var(--brand-color);
  text-decoration: none;
}
.admin-courses .email-creation .heading {
  color: var(--brand-color);
  font-size: 20px;
}
.admin-courses .email-creation label {
  color: #506278;
  font-size: 16px;
}
.admin-courses .email-creation .scroll {
  width: 45%;
}
.admin-courses .email-creation .search-members {
  position: relative;
}
.admin-courses .email-creation .search-members input {
  width: 100%;
  height: 45px;
}
.admin-courses .email-creation .search-members .search-icon {
  width: 35px;
  height: 35px;
  inset: 5px 5px 5px auto;
  position: absolute;
}
.admin-courses .email-creation .search-members .search-icon img {
  width: 100%;
}
.admin-courses .email-creation .access {
  background-color: #ebf7f6;
  border-color: #ebf7f6;
  padding: 15px 15px 15px 35px;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* width */
.admin-courses .email-creation .access::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.admin-courses .email-creation .access::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.admin-courses .email-creation .access::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.admin-courses .email-creation .access::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.wis-modal.email-detail-modal .modal-header {
  border-bottom: 0px;
}
.email-detail-modal td:nth-child(1):not(.email-content td),
.email-detail-modal td:nth-child(2):not(.email-content td) {
  border-right: 1px solid black;
}
.email-detail-modal td {
  padding: 10px;
  text-align: center;
}
.email-detail-modal tr:nth-child(1),
.wis-modal.email-detail-modal label {
  color: var(--brand-color);
  font-size: 16px;
}
.email-content {
  padding: 0.5rem;
  margin-top: 0.5rem;
  background: whitesmoke;
  border: 1px solid #e9e5e5;
  border-radius: 3px;
  overflow-y: scroll;
}
.email-content div > div {
  display: flex;
  justify-content: center;
}
.email-content div > div > figure {
  width: 600px;
}
.email-content div > div > figure > img {
  width: 100%;
}
.email-content div > table {
  display: flex;
  justify-content: center;
}
.email-content div > table tr:nth-child(1) {
  color: black;
  font-size: 14px;
}

.email-content td {
  border: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.email-btn-custom {
  font-size: 16px;
  color: #ffffff;
  background: var(--brand-color);
  box-shadow: 5px 3px 12px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  line-height: 25px;
  width: 150px;
  height: 42px;
  font-weight: 600;
  border: 1px solid var(--brand-color);
}
.email-btn-custom:hover {
  color: var(--brand-color);
  background-color: #c7ebeb;
  border: 1px solid #c7ebeb;
}
.se-component figure {
  height: 100% !important;
  width: 100%;
  padding-bottom: 25em !important;
}
.se-component figure > iframe {
  height: 25em !important;
}
.email-custom-checkbox {
  display: flex;
  align-items: center;
  height:14px;
  margin-bottom:0px;
}

.email-custom-checkbox input[type="checkbox"] {
  display: none; 
}

.email-custom-checkbox label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 15px; 
  font-size: 16px;
  user-select: none;
}

.email-custom-checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: 1px solid var(--brand-color);
  border-radius: 3px;
  background-color: #fff;
  transition: all 0.1s;
}

.email-custom-checkbox input[type="checkbox"]:checked + label:before {
  background-color: var(--brand-color);
}

.email-custom-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 4px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
}

.emailautomation-container {
  display: grid;
  grid-template-columns: 2.25fr 0.75fr;
  gap: 20px;
  // height: 100vh;
  background-color: #f8f8f8;
}

.emailautomation-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  background-color: #f8f8f8;
}

.emailautomation-buttons {
  display: flex;
  justify-content: flex-end; 
  gap: 10px; 
  padding-bottom: 20px;
}


.emailautomation-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.display-box {
  width: 40%;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor:pointer;
}
.display-box:hover{
  box-shadow: 0px 0px 4px 0px #ddd;
}
.display-box.selected{
  border: 1px solid var(--brand-color);
  box-shadow: 0px 0px 3px 0px var(--brand-color);
}
.display-box .display-delay{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: .5rem;
}
.display-delay div{
  border: 1px solid #ddd;
  border-bottom: none;
  border-left:none;
  border-right:none;
}
.work-flow{
  height: 40px;
  border: 1px solid #ddd;
}

.emailautomation-config {
  background-color: white;
  padding: 10px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emailautomation-config h6 {
  margin: 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
  padding: 15px;
  font-size: 14px;
}
.emailautomation-config .config-content{
  display:flex;
  flex-direction: column;
  padding: 10px
}

.config-content .dropdown{
  height: 30px;
  cursor:pointer;
}
.config-content .dropdown .dropdown-toggle{
  height:100%;
  font-size: 12px;
  cursor:pointer;
}
.config-content .dropdown-menu{
  box-shadow: none;
  cursor:pointer;
}
.config-content .dropdown-item{
  font-size: 12px;
  color:#333244;
  background-color: white;
  cursor:pointer;
}
.config-content .dropdown-item:hover{
  background: #ddd;
}
.emailautomation-config select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.config-group-data{
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 270px;
  overflow-y: auto;
  border-radius: 4px;
}
.config-group-data .access-level{
  display: flex;
  align-items: center;
  padding: 0.35rem 1rem;
  gap: .5rem;
}

.save-btn {
  background-color: var(--brand-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: var(--opaque-brand-color-light);
}

@media (min-width: 1600px) {
  #setupGuide .modal-dialog,
  #stepsSetup .modal-dialog,
  #wantToCreateModal .modal-dialog,
  #createFolderModal .modal-dialog,
  #addVideoDetailsModal .modal-dialog,
  #addAudioDetailsModal .modal-dialog,
  #addLessonDetailsModal .modal-dialog,
  #addAudioModal .modal-dialog,
  #addVideoModal .modal-dialog,
  #createSubFolderModal .modal-dialog,
  #uploadAudioModal .modal-dialog,
  #uploadVideoModal .modal-dialog,
  #uploadLessonModal .modal-dialog,
  #selectSubFolderModal .modal-dialog,
  #createMembershipLevelModal .modal-dialog,
  #membershipBillingOptionsModal .modal-dialog,
  #renewalAndSuceessModal .modal-dialog,
  #popupMessageModal .modal-dialog,
  #selectFolderModal .modal-dialog,
  #contentLibraryUploadPhoto .modal-dialog,
  #contentLibraryUploadVideo .modal-dialog,
  #selectAudioSubFolderModal .modal-dialog,
  #selectVideoSubFolderModal .modal-dialog,
  #insideFolderModal .modal-dialog,
  #addFilesToBulkModal .modal-dialog,
  #filterMembersModal .modal-dialog,
  #importMembersModal .modal-dialog,
  #addEditMembersModal .modal-dialog,
  #addEventModal .modal-dialog,
  #attachmentFileModal .modal-dialog,
  #addAlbumModal .modal-dialog,
  #albumWantToCreateModal .modal-dialog {
    max-width: 696px;
  }
  #paymentSuccessfull .modal-dialog,
  #paymentDone .modal-dialog,
  #audioAddedModal .modal-dialog,
  #videoAddedModal .modal-dialog,
  #lessonAddedModal .modal-dialog {
    max-width: 608px;
  }
  #landingPage .modal-dialog,
  #viewEventModal .modal-dialog {
    max-width: 596px;
  }
  #searchModal .modal-dialog {
    max-width: 688px;
  }
  #createPlaylistModal .modal-dialog {
    max-width: 552px;
  }
  #reConfirmModal .modal-dialog {
    max-width: 893px;
  }
  #audioPreviewModal .modal-dialog,
  #videoPreviewModal .modal-dialog {
    max-width: 613px;
  }
  #socialCreateModal .modal-dialog {
    max-width: 625px;
  }
}
@media (min-width: 992px) {
  #login .col-lg-5,
  #login .col-lg-6,
  #register .col-lg-6,
  #forgot .col-lg-6,
  #create-password .col-lg-6,
  #account-verification .col-lg-6,
  #welcome .col-lg-5,
  #multiple-membership .col-lg-5,
  #user-membership-login .col-lg-5,
  #user-welcome .col-lg-5 {
    -ms-flex: 0 0 45.439%;
    flex: 0 0 45.439%;
    max-width: 45.439%;
  }
}
@media (min-width: 576px) {
  #verifiedModal .modal-sm {
    max-width: 402px;
  }
}
@media only screen and (max-width: 1600px) {
  .enduser-dashboard .news .title {
    font-size: 18px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1440px) {
  #paymentSuccessfull .modal-dialog,
  #audioAddedModal .modal-dialog,
  #videoAddedModal .modal-dialog,
  #lessonAddedModal .modal-dialog {
    max-width: 402px;
  }
  .modal-header .close img {
    width: 40px;
  }
  #setupGuide p {
    font-size: 20px;
    line-height: 35px;
  }
  #setupGuide .btn-setup {
    padding: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  #setupGuide .btn-setup img {
    width: 30px;
  }
  .enduser-dashboard .explore-box .explore-btn {
    font-size: 24px;
    line-height: 50px;
  }
  .enduser-dashboard .news .heading,
  .enduser-dashboard .latest .heading {
    font-size: 36px;
    line-height: 44px;
  }
  .enduser-dashboard .news .heading small {
    padding-top: 18px;
  }
  .enduser-dashboard .info-card {
    padding-left: 8px;
  }
  .enduser-dashboard .info-card .card {
    margin: 0 8px 30px;
    width: 155px;
  }
  .enduser-dashboard .latest {
    padding-left: 0px;
    padding-right: 0px;
  }
  .enduser-dashboard .latest .sub-heading {
    font-size: 25px;
    line-height: 50px;
  }
  .enduser-dashboard .calendar select {
    width: 80%;
  }
  .enduser-dashboard .suggested {
    padding-left: 0px;
    padding-right: 0px;
  }
  .enduser-dashboard .suggested .sub-heading {
    font-size: 25px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .enduser-dashboard #youFav .card .img-over img,
  .enduser-dashboard #suggEvent .card .img-over img,
  .enduser-dashboard #latestCont .card .img-over img {
    width: 40px;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 25px;
    margin-bottom: 40px;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link.active {
    font-size: 20px;
  }
  .enduser-dashboard #all .card {
    width: 190px;
  }
  .enduser-dashboard #all .card .card-img-top {
    width: 190px;
    height: 249px;
  }
  .enduser-dashboard #all .card .img-over img {
    width: 40px;
  }
  .enduser-dashboard #post .latest-post .card-body {
    padding: 16px;
  }
  .enduser-dashboard #post .latest-post .card-body .card-columns {
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .enduser-dashboard #post .latest-post .card-body .card-columns img {
    margin-bottom: 10px;
  }
  .enduser-dashboard #videos .video-img,
  .enduser-dashboard #photos .photo {
    width: 255px;
    height: 180px;
  }
  .enduser-dashboard #courses .card,
  .enduser-dashboard #challenges .card {
    width: 290px;
  }
  .enduser-dashboard #courses .course-img,
  .enduser-dashboard #challenges .course-img {
    width: 290px;
    height: 226px;
  }
  .enduser-dashboard #courses .title,
  .enduser-dashboard #challenges .title {
    font-size: 20px;
    line-height: 28px;
  }
  #login .card-body {
    padding: 16px 67px;
  }
  #login .login-logo img,
  #register .register-logo img,
  #welcome .welcome-logo img {
    width: 100px;
  }
  #login .login-msg,
  #register .register-msg {
    margin-bottom: 16px;
  }
  #register {
    margin: 5% auto;
  }
  #register .card-body {
    padding: 16px 67px 16px;
  }
  #register .btn-info {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #forgot .card-body {
    padding: 30px 67px 20px;
  }
  #forgot .btn-info {
    margin-top: 0px;
  }
  #forgot .forgot-logo img {
    width: 250px;
  }
  #forgot .forgot-title {
    font-size: 24px;
    line-height: 38px;
  }
  #landingPage .title {
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 0px;
  }
  #landingPage .close img {
    width: 40px;
    height: 40px;
  }
  #landingPage .sub-title {
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #landingPage .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  #landingPage .text img {
    width: 14px;
  }
  #landingPage .sub-title2 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  #landingPage .btn-custom {
    height: 50px;
    font-size: 20px;
    line-height: 32px;
  }
  #tour .card-body {
    padding: 25px 40px;
  }
  #tour .tour-title {
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  #tour .tour-slider .tour-logo {
    width: 300px;
    margin: 0 auto;
  }
  #tour .tour-text {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 6px;
  }
  #membership .membership-subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  #membership .membership-title {
    font-size: 20px;
    line-height: 32px;
  }
  #membership .card-body .btn-preview {
    height: 50px;
  }
  #welcome .card-body {
    padding: 16px 92px;
  }
  #welcome .company-info {
    margin: 0px auto 24px;
  }
  #welcome .welcome-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #account-verification .card-body {
    padding: 16px 67px;
  }
  #account-verification .verification-img img {
    width: 220px;
  }
  #account-verification .verification-title {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  #user-membership-login .card-body,
  #user-welcome .card-body {
    padding: 16px 38px;
  }
  #user-welcome .btn-info {
    margin-top: 0px;
    margin-bottom: 16px;
  }
  #user-membership-login {
    margin: 4% auto;
  }
  #user-membership-login .btn-info {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  #multiple-membership {
    margin: 4% auto;
    height: 80vh;
  }
  #multiple-membership .multiple-membership-logo {
    margin-bottom: 0px;
  }
  #multiple-membership .multiple-membership-logo img {
    width: 90px;
  }
  #multiple-membership .card-body {
    padding: 16px;
  }
  #multiple-membership .multiple-membership-title {
    font-size: 22px;
    line-height: 37px;
    margin-bottom: 0px;
  }
  #multiple-membership .multiple-membership-msg {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  #multiple-membership .btn-info {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .daterange input {
    width: 100%;
  }
  .content-header h1 {
    font-size: 1.8rem;
  }
  .activity-list h5 {
    font-size: 18px;
    line-height: 31px;
  }
  .steps-mainpage .margin-left {
    margin-left: 0%;
  }
  #stepsSetup .title,
  #stepsSetup .title1 {
    font-size: 22px;
    line-height: 25px;
  }
  #stepsSetup form {
    padding: 0px 16px;
  }
  #stepsSetup .go-back {
    font-size: 14px;
    line-height: 28px;
  }
  #stepsSetup .go-back img {
    width: 40px;
  }
  .admin-members .member-list tbody tr td:last-child {
    width: auto;
  }
  .admin-members .member-list tbody tr td .dropdown-menu {
    width: auto;
  }
  .admin-members .member-list tbody tr td .dropdown-menu .dropdown-item {
    font-size: 16px;
    line-height: 20px;
  }
  .admin-members .member-list tbody tr td .dropdown-menu {
    background: #dbf3f4;
  }
  .admin-members .top-header .title {
    font-size: 30px;
    line-height: 43px;
  }
  .admin-members .top-buttons .member-search input {
    height: 46px;
  }
  .admin-members .top-buttons .search-icon {
    width: 34px;
    position: absolute !important;
    inset: 5px 7px 5px auto;
  }
  .admin-members .top-buttons .btn-custom {
    font-size: 16px;
    line-height: 22px;
    width: 200px;
    height: 46px;
  }
  .content-wrapper.admin-content-library {
    margin-right: 0px;
  }
  .admin-content-library .info-img {
    width: 20px;
  }
  .admin-content-library .title,
  .admin-audio-library .title,
  .admin-video-library .title,
  .admin-courses .title,
  .admin-student-queries .title {
    font-size: 20px;
    line-height: 36px;
  }
  .admin-content-library .readinglist .name,
  .admin-audio-library .playlist .name,
  .admin-video-library .playlist .name {
    font-size: 14px;
    line-height: 23px;
    width: 320px;
  }
  .admin-content-library .readinglist .left-img,
  .admin-audio-library .playlist .left-img,
  .admin-video-library .playlist .left-img {
    width: 50px;
  }
  .admin-content-library .readinglist .right-img,
  .admin-audio-library .playlist .right-img,
  .admin-video-library .playlist .right-img {
    right: 12px;
  }
  .admin-content-library .content-folders .title,
  .admin-audio-library .audio-folders .title,
  .admin-video-library .video-folders .title {
    font-size: 20px;
    line-height: 36px;
  }
  .admin-content-library .content-folders .title img,
  .admin-audio-library .audio-folders .title img,
  .admin-video-library .video-folders .title img {
    width: 20px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    width: 150px;
    height: 42px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .arrows-img,
  .admin-video-library .video-folders .video-folders-slider .arrows-img,
  .admin-video-library .video-folders .inside-video-folder .arrows-img {
    font-size: 30px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .slide-title,
  .admin-video-library .video-folders .video-folders-slider .slide-title,
  .admin-video-library .video-folders .inside-video-folder .slide-title {
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .img-over,
  .admin-video-library .video-folders .video-folders-slider .img-over,
  .admin-video-library .video-folders .inside-video-folder .img-over {
    height: 123px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .slide-text,
  .admin-video-library .video-folders .video-folders-slider .slide-text,
  .admin-video-library .video-folders .inside-video-folder .slide-text,
  .admin-audio-library .audio-folders .audio-folders-slider .slide-anchor,
  .admin-video-library .video-folders .video-folders-slider .slide-anchor {
    font-size: 12px;
    line-height: 19px;
    padding-left: 10px;
  }
  .admin-video-library .video-folders .video-info label {
    font-size: 15px;
    line-height: 30px;
  }
  .admin-video-library .video-folders .video-info label img {
    width: 20px;
  }
  .admin-video-library .video-folders .video-info label img:nth-child(2) {
    width: 12px;
  }
  .admin-video-library .video-list .after-search .video-title {
    font-size: 16px;
    line-height: 22px;
  }
  .admin-content-library .content-list .nav-item,
  .admin-audio-library .audio-list .nav-item,
  .admin-video-library .video-list .nav-item {
    width: 200px;
    margin-right: 0px;
  }
  .admin-content-library .content-list .nav-link,
  .admin-audio-library .audio-list .nav-link,
  .admin-video-library .video-list .nav-link,
  .admin-courses .courses-list .nav-link,
  .admin-courses .courses-folders .nav-link {
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
  }
  .admin-content-library .content-list .search-content input,
  .admin-audio-library .audio-list .search-library input,
  .admin-video-library .video-list .search-library input {
    width: 450px;
    height: 46px;
    font-size: 16px;
    line-height: 26px;
  }
  .admin-content-library .content-list .search-content .search-icon img,
  .admin-audio-library .audio-list .search-library .search-icon img,
  .admin-video-library .video-list .search-library .search-icon img,
  .admin-social-wall #socialWallTab .search-icon img {
    width: 100%;
  }

  .admin-content-library .content-list #contentLibraryTabContent,
  .admin-audio-library .audio-list #audioLibraryTabContent,
  .admin-video-library .video-list #videoLibraryTabContent {
    padding: 16px;
  }
  .admin-content-library .content-list #contentLibraryTabContent .table-title,
  .admin-audio-library .audio-list #audioLibraryTabContent .table-title,
  .admin-video-library .video-list #videoLibraryTabContent .table-title {
    font-size: 16px;
    line-height: 26px;
  }
  .admin-content-library .content-list #contentLibraryTabContent .views,
  .admin-audio-library .audio-list #audioLibraryTabContent .views,
  .admin-video-library .video-list #videoLibraryTabContent .views {
    font-size: 13px;
    line-height: 20px;
  }
  .content-wrapper.admin-courses,
  .content-wrapper.admin-student-queries {
    margin-right: 0px;
  }
  .content-wrapper.admin-courses .content-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .content-wrapper.admin-courses
    .content-header
    .course-detail
    .description-content.text
    > div
    > figure {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .content-wrapper.admin-courses
    .content-header
    .course-detail
    .description-content.text
    > div
    > figure
    > img {
    height: 100% !important;
    width: 99% !important;
    object-fit: fill !important;
  }

  .content-wrapper.admin-courses
    .content-header
    .course-detail
    .description-content.text
    > div
    > figure
    > iframe,
  .content-wrapper.admin-courses
    .content-header
    .course-detail
    .description-content.text
    > div
    > figure
    > video {
    height: 100% !important;
    width: 99% !important;
    background-color: black;
  }

  .admin-courses .title a {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-courses .courses-folders {
    padding-left: 0px;
  }
  .admin-courses .content-header .btn-custom {
    font-size: 18px;
    line-height: 26px;
    width: 210px;
    height: 40px;
  }
  .admin-courses .courses-list .nav-item,
  .admin-courses .courses-folders .nav-item {
    width: 185px;
    margin-right: 0px;
  }
  .admin-courses .courses-folders .search-course input,
  .admin-courses .content-header .search-course input {
    width: 100%;
    height: 46px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .admin-courses .courses-folders .search-course .search-icon img,
  .admin-courses .content-header .search-course .search-icon img {
    width: 100%;
  }
  .admin-courses .courses-folders .courses-slider .img-over {
    height: 130px;
  }
  .admin-courses .courses-list .student-queries .queries-icon span {
    font-size: 13px;
    line-height: 16px;
    padding: 8px 26px;
    top: -33px;
    right: 17px;
  }
  .admin-courses .courses-list .student-queries .queries-icon .question-img {
    width: 75px;
    top: -22px;
  }
  .admin-courses .courses-list .student-queries .title,
  .admin-student-queries .student-queries .title {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 10px;
  }
  .admin-courses .courses-list .student-queries .search-queries,
  .admin-student-queries .student-queries .search-queries {
    margin-bottom: 21px;
  }
  .admin-courses .courses-list .student-queries .search-queries input,
  .admin-student-queries .student-queries .search-queries input {
    font-size: 18px;
    line-height: 28px;
    height: 46px;
  }
  .admin-courses .courses-list .student-queries .search-icon,
  .admin-student-queries .student-queries .search-icon {
    top: 10px;
    right: 12px;
  }
  .admin-courses .courses-list .student-queries .search-icon img,
  .admin-student-queries .student-queries .search-icon img {
    width: 25px;
  }
  .admin-courses .courses-list .student-queries .messages-list .media img,
  .admin-student-queries .student-queries .messages-list .media img,
  .admin-student-queries .message-detail .media img {
    width: 34px;
    height: 34px;
  }
  .admin-courses .courses-list .student-queries .messanger-name,
  .admin-student-queries .student-queries .messanger-name,
  .admin-student-queries .message-detail .messanger-name {
    font-size: 15px;
    line-height: 13px;
  }
  .admin-courses .courses-list .student-queries .msg-time,
  .admin-student-queries .student-queries .msg-time {
    font-size: 13px;
  }
  .admin-courses .courses-list .student-queries .messanger-text,
  .admin-student-queries .student-queries .messanger-text,
  .admin-student-queries .message-detail .messanger-text {
    font-size: 14px;
    line-height: 16px;
  }
  .admin-courses .courses-list .student-queries .msg-count,
  .admin-student-queries .student-queries .msg-count {
    font-size: 10px;
    line-height: 18px;
    width: 30px;
    height: 22px;
  }
  .admin-courses .course-detail .edit-img {
    width: 60px;
  }
  .admin-courses .course-detail .course-info {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 30px;
  }
  .admin-courses .course-detail .student-img img {
    width: 30px;
    height: 30px;
  }
  .admin-courses .course-detail .price {
    font-size: 13px;
    line-height: 21px;
  }
  .admin-courses .course-detail .price span {
    font-size: 18px;
    line-height: 28px;
  }
  .admin-courses .course-detail .course-text {
    font-size: 14px;
    line-height: 27px;
  }
  #wantToCreateModal .modal-title,
  #createFolderModal .modal-title,
  #addAudioModal .modal-title,
  #addVideoModal .modal-title,
  #createSubFolderModal .title,
  #selectFolderModal .modal-title {
    font-size: 22px;
    line-height: 30px;
    padding-left: 16px;
  }
  #addVideoDetailsModal .modal-title,
  #addAudioDetailsModal .modal-title,
  #addLessonDetailsModal .title {
    font-size: 22px;
    line-height: 30px;
  }
  #addLessonDetailsModal .title,
  #addLessonDetailsModal .folder-name,
  #addAudioDetailsModal .folder-name,
  #addVideoDetailsModal .folder-name {
    padding-left: 16px;
  }
  #createSubFolderModal small {
    padding-left: 16px;
  }
  #wantToCreateModal .modal-body,
  #addAudioModal .modal-body,
  #addVideoModal .modal-body {
    padding: 40px 16px;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #wantToCreateModal .video .fa-video,
  #addVideoModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #selectFolderModal .folder .fa-folder,
  #selectFolderModal .audio .fa-microphone,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio {
    font-size: 50px;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #selectFolderModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio {
    padding: 45.5px 55px;
  }
  #wantToCreateModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #addVideoModal .video .fa-video {
    padding: 45.5px 52px;
  }
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #selectFolderModal .audio .fa-microphone {
    padding: 45.5px 61.38px;
  }
  #wantToCreateModal .folder-img,
  #selectFolderModal .folder-img {
    padding: 30px 50px;
  }
  #addAudioModal .upload-audio-file,
  #addVideoModal .upload-video-file,
  #addVideoModal .upload-lesson-file {
    padding: 0px 30px 0px;
  }
  #uploadAudioModal .title,
  #uploadVideoModal .title,
  #uploadLessonModal .title,
  #uploadAudioModal .subtitle,
  #uploadVideoModal .subtitle,
  #uploadLessonModal .subtitle,
  #uploadAudioModal small,
  #uploadVideoModal small,
  #uploadLessonModal small {
    word-wrap: break-word;
    padding-left: 16px;
    margin-bottom: 0px;
    font-size: 18px;
  }
  #createFolderModal .btn-custom,
  #addVideoDetailsModal .btn-custom,
  #addAudioDetailsModal .btn-custom,
  #addLessonDetailsModal .btn-custom,
  #createSubFolderModal .btn-custom {
    width: 224px;
    font-size: 16px;
    line-height: 26px;
    height: 44px;
  }
  #createFolderModal .modal-header,
  #createFolderModal .modal-body,
  #addVideoDetailsModal .modal-header,
  #addVideoDetailsModal .modal-body,
  #addAudioDetailsModal .modal-header,
  #addAudioDetailsModal .modal-body,
  #addLessonDetailsModal .modal-header,
  #addLessonDetailsModal .modal-body,
  #createSubFolderModal .modal-header,
  #createSubFolderModal .modal-body,
  #uploadAudioModal .modal-header,
  #uploadAudioModal .modal-body,
  #uploadVideoModal .modal-header,
  #uploadVideoModal .modal-body,
  #uploadLessonModal .modal-header,
  #uploadLessonModal .modal-body {
    padding: 16px;
  }
  #createFolderModal .upload-file,
  #createSubFolderModal .upload-file,
  #addVideoDetailsModal .upload-file,
  #addAudioDetailsModal .upload-file {
    right: 6px;
  }
  #createFolderModal label,
  #addVideoDetailsModal label,
  #addAudioDetailsModal label,
  #addLessonDetailsModal label,
  #createSubFolderModal label,
  #createFolderModal .form-control,
  #addVideoDetailsModal .form-control,
  #addAudioDetailsModal .form-control,
  #addLessonDetailsModal .form-control,
  #createSubFolderModal .form-control,
  #renewalAndSuceessModal label {
    font-size: 14px;
    line-height: 23px;
  }
  #createFolderModal .form-control,
  #addVideoDetailsModal .form-control,
  #addAudioDetailsModal .form-control,
  #addLessonDetailsModal .form-control,
  #createSubFolderModal .form-control {
    padding-left: 12px;
  }
  #wantToCreateModal .select-folder,
  #selectSubFolderModal .folder {
    padding-left: 16px;
    padding-right: 16px;
  }
  #wantToCreateModal .select-folder .card,
  #selectSubFolderModal .folder .card {
    margin: 15px;
  }
  #wantToCreateModal .select-folder .card .card-body,
  #selectSubFolderModal .folder .card .card-body {
    padding: 16px;
  }
  #wantToCreateModal .select-folder .card .card-body .page-info img,
  #selectSubFolderModal .folder .card .card-body .page-info img {
    width: 30px;
  }
  #wantToCreateModal .select-folder .card .card-body .page-info,
  #selectSubFolderModal .folder .card .card-body .page-info {
    font-size: 12px;
    line-height: 18px;
  }
  #selectFolderModal .select-icon {
    padding: 0px;
  }
  #selectFolderModal .select-folder,
  #selectAudioSubFolderModal .folder,
  #selectVideoSubFolderModal .folder,
  #insideFolderModal .folder {
    padding: 0px;
  }
  #selectFolderModal .select-folder .audio-img,
  #selectAudioSubFolderModal .folder .audio-img,
  #selectVideoSubFolderModal .folder .video-img,
  #insideFolderModal .folder .audio-img {
    width: 100px;
    height: 100px;
  }
  #selectFolderModal .select-folder .card,
  #selectAudioSubFolderModal .folder .card,
  #selectVideoSubFolderModal .folder .card,
  #insideFolderModal .folder .card {
    margin: 15px 10px;
  }
  #selectFolderModal .select-folder .card .card-body,
  #selectAudioSubFolderModal .folder .card .card-body,
  #selectVideoSubFolderModal .folder .card .card-body,
  #insideFolderModal .folder .card .card-body {
    padding: 10px;
  }
  #selectFolderModal .select-folder .card .card-title,
  #selectAudioSubFolderModal .folder .card .card-title,
  #selectVideoSubFolderModal .folder .card .card-title,
  #insideFolderModal .folder .card .card-title {
    font-size: 16px;
    line-height: 24px;
  }
  #selectFolderModal .select-folder .card .card-text,
  #selectAudioSubFolderModal .folder .card .card-text,
  #selectVideoSubFolderModal .folder .card .card-text,
  #insideFolderModal .folder .card .card-text {
    font-size: 12px;
    line-height: 20px;
  }
  #selectFolderModal .select-folder .card .card-body .page-info,
  #selectAudioSubFolderModal .folder .card .card-body .page-info,
  #selectVideoSubFolderModal .folder .card .card-body .page-info,
  #insideFolderModal .folder .card .card-body .page-info {
    font-size: 12px;
    line-height: 19px;
  }
  #selectFolderModal .select-folder .card .card-body .page-info img,
  #selectAudioSubFolderModal .folder .card .card-body .page-info img,
  #selectVideoSubFolderModal .folder .card .card-body .page-info img,
  #insideFolderModal .folder .card .card-body .page-info img {
    width: 30px;
  }
  #selectFolderModal .select-folder .card .card-body .student-info img,
  #insideFolderModal .folder .card .card-body .student-info img {
    width: 20px;
    height: 20px;
  }
  #selectFolderModal .select-folder .btn-custom,
  #selectAudioSubFolderModal .folder .btn-custom,
  #selectVideoSubFolderModal .folder .btn-custom,
  #insideFolderModal .folder .btn-custom {
    font-size: 20px;
    line-height: 31px;
    width: 290px;
    height: 46px;
    margin-bottom: 15px;
  }
  #selectSubFolderModal .go-back,
  #createMembershipLevelModal .modal-body .go-back,
  #membershipBillingOptionsModal .modal-body .go-back,
  #contentLibraryUploadPhoto .go-back,
  #contentLibraryUploadVideo .go-back,
  #selectAudioSubFolderModal .go-back,
  #selectVideoSubFolderModal .go-back,
  #insideFolderModal .go-back,
  #renewalAndSuceessModal .go-back,
  #addLessonDetailsModal .go-back,
  #createSubFolderModal .go-back,
  #uploadAudioModal .go-back,
  #uploadVideoModal .go-back,
  #uploadLessonModal .go-back {
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
  #selectSubFolderModal .go-back img,
  #createMembershipLevelModal .modal-body .go-back img,
  #membershipBillingOptionsModal .modal-body .go-back img,
  #contentLibraryUploadPhoto .go-back img,
  #contentLibraryUploadVideo .go-back img,
  #selectAudioSubFolderModal .go-back img,
  #selectVideoSubFolderModal .go-back img,
  #insideFolderModal .go-back img,
  #createSubFolderModal .go-back img,
  #uploadAudioModal .go-back img,
  #uploadVideoModal .go-back img,
  #uploadLessonModal .go-back img,
  #renewalAndSuceessModal .go-back img,
  #addLessonDetailsModal .go-back img {
    width: 40px;
  }
  #membershipBillingOptionsModal .info-hover,
  #createMembershipLevelModal .info-hover,
  .admin-content-library .info-hover,
  .admin-content-library .content-folders .info-hover,
  .admin-membership-setting .info-hover,
  #addLessonDetailsModal .info-hover {
    font-size: 12px;
    line-height: 18px;
    padding: 10px;
    width: 420px;
    text-align: justify;
  }
  #uploadAudioModal .uploaded-file .name,
  #uploadVideoModal .uploaded-file .name,
  #uploadLessonModal .uploaded-file .name {
    font-size: 14px;
    line-height: 24px;
  }
  #uploadAudioModal .uploaded-file .perc,
  #uploadVideoModal .uploaded-file .perc,
  #uploadLessonModal .uploaded-file .perc {
    font-size: 16px;
    line-height: 26px;
  }
  #uploadAudioModal .uploaded-file img,
  #uploadVideoModal .uploaded-file img,
  #uploadLessonModal .uploaded-file img {
    width: 40px;
  }
  #wantToCreateModal .select-folder .btn-custom,
  #selectSubFolderModal .folder .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 46px;
  }
  .content-page-setting section {
    padding-left: 16px;
    padding-right: 16px;
  }
  #selectAudioSubFolderModal .folder .title,
  #selectVideoSubFolderModal .folder .title,
  #insideFolderModal .folder .title {
    font-size: 18px;
    line-height: 34px;
  }
  #insideFolderModal .folder .uploaded-file .file-title {
    font-size: 16px;
    line-height: 24px;
  }
  #insideFolderModal .folder .uploaded-file .file-text {
    font-size: 12px;
    line-height: 23px;
  }
  #audioPreviewModal .modal-title,
  #audioPreviewModal .audio-title,
  #videoPreviewModal .modal-title,
  #videoPreviewModal .video-title {
    font-size: 18px;
    line-height: 28px;
  }
  #audioPreviewModal .chat-like,
  #videoPreviewModal .chat-like {
    padding: 0px 10px;
  }
  #audioPreviewModal .chat-like img,
  #videoPreviewModal .chat-like img {
    width: 30px;
  }
  #audioPreviewModal .cursor .cursor-img,
  #videoPreviewModal .cursor .cursor-img {
    width: 17px;
    top: -9px;
  }
  #audioPreviewModal .timing span,
  #videoPreviewModal .timing span {
    font-size: 16px;
    line-height: 26px;
  }
  #audioPreviewModal .controls,
  #videoPreviewModal .controls {
    padding: 0px 40px;
  }
  #audioPreviewModal .controls img:nth-child(1),
  #audioPreviewModal .controls img:nth-child(2),
  #audioPreviewModal .controls img:nth-child(4),
  #audioPreviewModal .controls img:nth-child(5),
  #videoPreviewModal .controls img:nth-child(1),
  #videoPreviewModal .controls img:nth-child(2),
  #videoPreviewModal .controls img:nth-child(4),
  #videoPreviewModal .controls img:nth-child(5) {
    width: 22px;
  }
  #audioPreviewModal .controls img:nth-child(3),
  #videoPreviewModal .controls img:nth-child(3) {
    width: 70px;
  }
  .content-choose-template,
  .content-edit-template {
    border-radius: 50px;
    margin: 20px 10px;
    padding: 50px 30px 10px;
  }
  .content-choose-template .title,
  .content-edit-template .title {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .content-choose-template .text,
  .content-edit-template .text {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 60px;
  }
  .content-choose-template .template,
  .content-edit-template .template {
    padding: 22px;
  }
  .content-choose-template .template .content .heading,
  .content-edit-template .template .content .heading {
    font-size: 20px;
    line-height: 36px;
  }
  .content-edit-template .upload-media .dropdown-menu {
    width: auto;
    padding-left: 30px;
  }
  .content-edit-template .upload-media .dropdown-item {
    font-size: 18px;
    line-height: 28px;
  }
  .content-edit-template .text-area {
    padding: 16px;
  }
  .content-edit-template .text-area textarea {
    font-size: 22px;
    line-height: 38px;
  }
  .content-edit-template .upload-media .image {
    height: 270px;
  }
  .content-edit-template .upload-media {
    padding: 33px 26px;
  }
  .content-edit-template .upload-media .btn-custom {
    font-size: 20px;
    line-height: 30px;
    width: 240px;
    height: 50px;
  }
  .content-edit-template .btn-custom1 {
    font-size: 20px;
    line-height: 31px;
    height: 50px;
  }
  #contentLibraryUploadPhoto .modal-header,
  #contentLibraryUploadVideo .modal-header,
  #contentLibraryUploadPhoto .modal-body,
  #contentLibraryUploadVideo .modal-body {
    padding: 16px;
  }
  #contentLibraryUploadPhoto .title,
  #contentLibraryUploadVideo .title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  #contentLibraryUploadPhoto .label-title {
    font-size: 16px;
    line-height: 26px;
  }
  #contentLibraryUploadPhoto .btn-custom,
  #contentLibraryUploadVideo .btn-custom {
    font-size: 16px;
    line-height: 26px;
    height: 50px;
  }
  #createMembershipLevelModal .modal-body,
  #membershipBillingOptionsModal .modal-body {
    padding: 16px;
  }
  #createMembershipLevelModal .title {
    font-size: 20px;
    line-height: 30px;
  }
  #createMembershipLevelModal .subscription-info .card-body {
    padding: 16px;
  }
  .admin-social-wall .title {
    font-size: 26px;
    line-height: 43px;
  }
  .admin-social-wall .calendar input {
    width: 100%;
    height: 48px;
  }
  .admin-social-wall #socialWallTabContent .owl-item {
    padding: 16px 6px;
  }
  .admin-social-wall #socialWallTabContent .item {
    padding: 5px;
  }
  .admin-social-wall #socialWallTabContent .item .slide-title {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-social-wall #socialWallTabContent .item .slide-text .fa-history {
    font-size: 16px;
  }
  .admin-social-wall .post-area,
  .admin-social-wall .posts {
    padding: 16px;
  }
  .admin-social-wall .post-area .uploaded-img {
    width: 157px;
  }
  .admin-social-wall .post-area .uploaded-img .img {
    width: 155px;
    height: 99px;
  }
  .admin-social-wall .posts .sm-img {
    width: 179px;
    height: 112px;
  }
  .admin-social-wall .post-area .btn-custom {
    width: 100%;
    height: 50px;
  }
  .admin-social-wall .posts .lg-img {
    width: 343px;
    height: 240px;
  }
  #showPostModal #postCarousel {
    margin-top: -60px;
  }
  .admin-social-wall #socialWallTab .nav-item {
    width: 130px;
    margin-right: 0px;
  }
  .admin-social-wall #socialWallTab .nav-link {
    font-size: 16px;
    line-height: 25px;
  }
  .admin-social-wall #socialWallTab .search-library input {
    width: 380px;
    height: 46px;
  }
  .admin-social-wall #socialWallTab .search-icon {
    position: absolute;
    width: 36px;
    height: 36px;
    inset: 5px 5px 5px auto;
  }

  .admin-social-wall #socialWallTab .btn-custom {
    width: 45px;
    height: 45px;
    font-size: 15px;
    padding: 4px;
    margin-left: 20px;
  }
  .admin-social-wall .post-area .cross {
    width: 18px;
  }
  #socialCreateModal .modal-title {
    font-size: 24px;
    line-height: 37px;
  }
  #socialCreateModal .title {
    font-size: 22px;
    line-height: 44px;
  }
  #socialCreateModal .btn-custom {
    width: 290px;
    height: 50px;
    font-size: 20px;
  }
  .enduser-dashboard section.profile {
    padding: 16px 8px;
  }
  .enduser-dashboard .profile #profile-tabContent {
    padding: 16px;
  }
  .enduser-dashboard .profile #profile-tabContent .title {
    font-size: 22px;
    line-height: 30px;
  }
  .enduser-dashboard .profile #account-details .details span,
  .enduser-dashboard .profile #account-details .details label {
    font-size: 16px;
  }
  .enduser-dashboard .profile #account-details .plans,
  .enduser-dashboard .profile #account-details .explore-plans {
    margin-right: 0px;
  }
  .enduser-dashboard .profile #account-details .plans .card-body,
  .enduser-dashboard .profile #account-details .explore-plans .card-body {
    padding: 16px;
  }
  .enduser-dashboard .profile #account-details .plans .plan-name,
  .enduser-dashboard .profile #account-details .explore-plans .plan-name,
  .enduser-dashboard .profile #account-details .plans .plan-fee,
  .enduser-dashboard .profile #account-details .explore-plans .plan-fee {
    font-size: 18px;
    line-height: 26px;
  }
  .enduser-dashboard .profile #edit-account .btn-white,
  .enduser-dashboard .profile #change-password .btn-white,
  .enduser-dashboard .profile #notifications-settings .btn-white,
  .enduser-dashboard .profile #edit-account .btn-blue,
  .enduser-dashboard .profile #change-password .btn-blue,
  .enduser-dashboard .profile #notifications-settings .btn-blue {
    height: 50px;
  }
  .enduser-dashboard .profile #notifications-settings .sub-title {
    font-size: 20px;
    line-height: 39px;
  }
  .enduser-dashboard .profile #notifications-settings .notification-text {
    font-size: 14px;
  }
  .enduser-dashboard .content-library .folders .card-body {
    padding: 16px;
  }
  .enduser-dashboard .content-library .folders .book {
    padding-left: 0px;
  }
  .enduser-dashboard .content-library .title {
    font-size: 26px;
    line-height: 42px;
  }
  .enduser-dashboard .content-library-folder .title {
    font-size: 24px;
    line-height: 40px;
  }
  .enduser-dashboard .content-library-folder .content-folders .title {
    font-size: 20px;
    line-height: 33px;
  }
  .enduser-dashboard .content-library-folder .content-folders .file-info {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .content-header .title {
    font-size: 24px;
    line-height: 37px;
  }
  .enduser-dashboard .plan-details .plan .title,
  .enduser-dashboard .payment-details .plan .title {
    font-size: 22px;
    line-height: 44px;
  }
  .enduser-dashboard .payment-details .btn-custom,
  .enduser-dashboard .plan-details .btn-custom {
    height: 50px;
  }
  #paymentSuccessfull .title {
    font-size: 24px;
    line-height: 46px;
    margin-bottom: 14px;
  }
  #paymentSuccessfull .text {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
  }
  .enduser-dashboard .audio-library .audios .nav-link,
  .enduser-dashboard .video-library .videos .nav-link {
    font-size: 20px;
    line-height: 31px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent,
  .enduser-dashboard .video-library .videos #videoTabContent {
    padding: 16px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .title,
  .enduser-dashboard .video-library .videos #videoTabContent .title {
    font-size: 16px;
    line-height: 24px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .text,
  .enduser-dashboard .video-library .videos #videoTabContent .text {
    font-size: 14px;
    line-height: 22px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .views,
  .enduser-dashboard .video-library .videos #videoTabContent .views {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .play-img,
  .enduser-dashboard .video-library .videos #videoTabContent .play-img,
  .enduser-dashboard .audio-library .audios #audioTabContent .fav-img,
  .enduser-dashboard .video-library .videos #videoTabContent .fav-img {
    width: 40px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .drop-img,
  .enduser-dashboard .video-library .videos #videoTabContent .drop-img {
    width: 7px;
  }
  #createPlaylistModal .modal-title,
  #addPlaylistModal .modal-title {
    font-size: 22px;
    line-height: 39px;
  }
  #createPlaylistModal .form-control {
    padding-left: 25px;
    font-size: 18px;
    line-height: 26px;
  }
  #createPlaylistModal input.form-control {
    height: 75px;
  }
  #createPlaylistModal textarea.form-control {
    height: 200px;
    margin-bottom: 30px;
  }
  #createPlaylistModal .btn-custom {
    height: 50px;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 0px;
  }
  #createPlaylistModal .text {
    margin-bottom: 0px;
  }
  #addPlaylistModal .btn-custom {
    height: 60px;
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 36px;
  }
  #addPlaylistModal .title {
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 0px;
  }
  #addPlaylistModal .add {
    width: 45px;
    height: 42px;
  }
  #addPlaylistModal .add .fa-plus {
    font-size: 18px;
    line-height: 40px;
  }
  .enduser-dashboard .audio-folder .title {
    font-size: 22px;
    line-height: 38px;
  }
  .enduser-dashboard .audio-folder .border-line::after {
    width: 70%;
  }
  .enduser-dashboard .audio-folder .folder-info .form-control {
    width: 30%;
  }
  .enduser-dashboard .my-playlist .border-line::after {
    width: 55%;
  }
  .enduser-dashboard .my-playlist .title {
    font-size: 24px;
    line-height: 42px;
  }
  .enduser-dashboard .audio-top-result .title,
  .enduser-dashboard .video-top-result .title,
  .enduser-dashboard .audio-result-detail .title,
  .enduser-dashboard .video-result-detail .title {
    font-size: 24px;
    line-height: 42px;
  }
  .enduser-dashboard .audio-top-result .border-line::after,
  .enduser-dashboard .video-top-result .border-line::after,
  .enduser-dashboard .audio-result-detail .border-line::after,
  .enduser-dashboard .video-result-detail .border-line::after {
    width: 60%;
  }
  .enduser-dashboard .audio-top-result .folders .title,
  .enduser-dashboard .video-top-result .folders .title {
    font-size: 24px;
    line-height: 38px;
  }
  .enduser-dashboard .audio-top-result .folders .card .title {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .audio-top-result .folders .card .text {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .audio-top-result .audio-files .table-responsive,
  .enduser-dashboard .video-top-result .video-files .table-responsive,
  .enduser-dashboard .video-result-detail .video-files .table-responsive,
  .enduser-dashboard .audio-result-detail .folder-files .table-responsive {
    padding: 16px;
  }
  .enduser-dashboard .show-content .template .title,
  .enduser-dashboard .show-content .template2 .title,
  .enduser-dashboard .show-content .template3 .title {
    font-size: 22px;
    line-height: 38px;
  }
  #renewalAndSuceessModal .modal-header,
  #renewalAndSuceessModal .modal-body {
    padding: 16px;
  }
  #renewalAndSuceessModal .title {
    font-size: 22px;
    line-height: 22px;
  }
  #renewalAndSuceessModal .subtitle {
    font-size: 18px;
    line-height: 38px;
  }
  #renewalAndSuceessModal .nav-pills .nav-link {
    font-size: 14px;
  }
  #renewalAndSuceessModal .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 46px;
  }
  #audioAddedModal .modal-body,
  #videoAddedModal .modal-body,
  #lessonAddedModal .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  #audioAddedModal .title,
  #videoAddedModal .title,
  #lessonAddedModal .title {
    font-size: 22px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  #audioAddedModal .text,
  #videoAddedModal .text,
  #lessonAddedModal .text {
    font-size: 14px;
    line-height: 24px;
  }
  .content-page-setting .title {
    font-size: 22px;
    line-height: 39px;
  }
  .content-page-setting .title img {
    width: 50px;
  }
  .content-page-setting .breadcrumb-item a,
  .content-page-setting .breadcrumb-item,
  .content-edit-template .breadcrumb-item a,
  .content-edit-template
    .breadcrumb-item
    .content-choose-template
    .breadcrumb-item
    a,
  .content-choose-template .breadcrumb-item {
    font-size: 16px;
    line-height: 25px;
  }
  .content-page-setting .btn-custom {
    font-size: 20px;
    line-height: 32px;
    height: 50px;
  }
  .content-final-preview section {
    padding-left: 16px;
    padding-right: 16px;
  }
  .content-final-preview .title {
    font-size: 22px;
    line-height: 39px;
  }
  .content-final-preview .heading img {
    width: 60px;
  }
  .content-final-preview .heading a {
    font-size: 14px;
    line-height: 23px;
    width: 190px;
    height: 50px;
    margin-right: 8px;
  }
  .content-final-preview .heading a img {
    width: 30px;
  }
  .content-final-preview .breadcrumb-item a,
  .content-final-preview .breadcrumb-item {
    font-size: 16px;
    line-height: 25px;
  }
  .content-final-preview .template .title {
    font-size: 20px;
    line-height: 33px;
  }
  .content-final-preview .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 50px;
  }
  #createMembershipLevelModal label {
    font-size: 14px;
    line-height: 23px;
  }
  #createMembershipLevelModal .btn-custom,
  #membershipBillingOptionsModal .btn-custom {
    font-size: 20px;
    line-height: 31px;
    height: 50px;
  }
  #addFilesToBulkModal .modal-title,
  #filterMembersModal .modal-title,
  #importMembersModal .modal-title,
  #addEditMembersModal .modal-title {
    font-size: 22px;
    line-height: 44px;
  }
  #addFilesToBulkModal .attach-file,
  #addFilesToBulkModal .audience,
  #addFilesToBulkModal .file-history {
    padding-left: 16px;
    padding-right: 16px;
  }
  #addFilesToBulkModal .attach-file .title,
  #addFilesToBulkModal .audience .title,
  #addFilesToBulkModal .file-history .title {
    font-size: 18px;
    line-height: 28px;
  }
  #addFilesToBulkModal .file-history .file-name {
    font-size: 16px;
    line-height: 24px;
  }
  #addFilesToBulkModal .btn-custom {
    font-size: 16px;
    line-height: 24px;
    height: 50px;
  }
  #audioAddedModal .success-img,
  #videoAddedModal .success-img,
  #lessonAddedModal .success-img {
    margin-top: -58px;
    margin-left: -35px;
    width: 300px;
  }
  .admin-courses .title a,
  .admin-student-queries .title a {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-student-queries .student-queries .title img,
  .admin-student-queries .message-detail .filters img,
  .admin-student-queries
    .message-detail
    .chat-messages
    .new-msg
    img:last-child {
    width: 34px;
    height: 34px;
  }
  .admin-student-queries .message-detail .chat-messages .messanger-name {
    font-size: 13px;
    line-height: 22px;
  }
  .admin-student-queries .message-detail .chat-messages .msg-time {
    font-size: 11px;
    line-height: 20px;
  }
  .admin-student-queries .message-detail .chat-messages .messanger-text {
    font-size: 12px;
    line-height: 20px;
  }
  .admin-student-queries .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .admin-student-queries .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .admin-student-queries .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .admin-student-queries
    .message-detail
    .chat-messages
    .new-msg
    img:nth-child(1),
  .admin-student-queries
    .message-detail
    .chat-messages
    .new-msg
    img:nth-child(2),
  .admin-student-queries
    .message-detail
    .chat-messages
    .new-msg
    img:nth-child(3) {
    width: 18px;
  }
  .admin-student-queries .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .admin-student-queries .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
  }
  /*messages*/
  .admin-messages .top-header .title {
    font-size: 25px;
    line-height: 31px;
  }
  .admin-messages .top-header .calendar {
    font-size: 16px;
    line-height: 26px;
    width: 300px;
    height: 42px;
  }
  .admin-messages .student-queries .title {
    font-size: 18px;
    line-height: 28px;
  }
  .admin-messages .student-queries .title img,
  .admin-messages .message-detail .filters img,
  .admin-messages .message-detail .chat-messages .new-msg img:last-child,
  .admin-messages .student-queries .search-icon img {
    width: 34px;
    height: 34px;
  }
  .admin-messages .student-queries .search-queries input {
    height: 48px;
    font-size: 16px;
    line-height: 23px;
  }
  .admin-messages .student-queries .sub-title {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-messages .message-detail .media img {
    width: 36px;
    height: 36px;
  }
  .admin-messages .student-queries .messages-list .media img,
  .admin-messages .chat-messages .media img {
    width: 34px;
    height: 34px;
  }
  .admin-messages .student-queries .messanger-name,
  .admin-messages .message-detail .messanger-name,
  .admin-messages .message-detail .chat-messages .messanger-name {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-messages .student-queries .msg-time,
  .admin-messages .message-detail .chat-messages .msg-time {
    font-size: 12px;
  }
  .admin-messages .student-queries .messanger-text,
  .admin-messages .message-detail .messanger-text,
  .admin-messages .message-detail .chat-messages .messanger-text {
    font-size: 13px;
    line-height: 18px;
  }
  .admin-messages .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .admin-messages .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .admin-messages .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .admin-messages .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(1),
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(2),
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(3) {
    width: 18px;
  }
  .admin-messages .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
  }
  .admin-event .top-header .title {
    font-size: 30px;
    line-height: 43px;
  }
  .admin-event .top-header .calendar {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 260px;
  }
  .admin-event .top-buttons .member-search input {
    height: 46px;
    width: 300px;
  }
  .admin-event .top-buttons .search-icon,
  .admin-courses .courses-folders .search-icon,
  .admin-courses .content-header .search-icon,
  .admin-content-library .content-list .search-icon {
    position: absolute;
    width: 36px;
    inset: 5px 5px 5px auto;
  }
  .admin-event .top-buttons .btn-custom {
    font-size: 16px;
    line-height: 22px;
    width: 200px;
    height: 46px;
  }

  .admin-video-library .video-folders .grid-view.scroll,
  .admin-audio-library .audio-folders .grid-view.scroll {
    max-height: 840px;
  }
  .admin-video-library .grid-view .folder .folder-title,
  .admin-audio-library .audio-folders .folder .folder-title {
    font-size: 16px;
    line-height: 25px;
  }
  .admin-video-library .grid-view .folder .folder-text,
  .admin-audio-library .audio-folders .folder .folder-text {
    font-size: 10px;
    line-height: 16px;
  }
  .admin-video-library .video-folders .list-view .table-title,
  .admin-audio-library .audio-folders .list-view .table-title {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-dashboard .courses .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .courses .queries-icon span {
    padding: 6px 26px;
  }
  .enduser-dashboard .courses .playlist .title {
    font-size: 13px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .playlist .left-img {
    width: 46px;
    left: -15px;
  }
  .enduser-dashboard .courses .playlist .name {
    font-size: 14px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .playlist .right-img {
    width: 50px;
    top: 24px;
  }
  .enduser-dashboard .courses .discover .title,
  .enduser-dashboard .courses .your-course .border-line .title {
    font-size: 22px;
    line-height: 33px;
  }
  .enduser-dashboard .courses .your-course .border-line::after {
    width: 75%;
  }
  .enduser-dashboard .courses .your-course .search-course input {
    width: 100%;
    height: 46px;
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses .your-course .search-icon img {
    width: 28px;
  }
  .enduser-dashboard .courses .your-course .filter-icon {
    width: 40px;
  }
  .enduser-dashboard .courses .your-course .view-all {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses .your-course .book-box {
    margin-right: 14px;
  }
  .enduser-dashboard .courses .your-course .book-box .title {
    font-size: 13px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .your-course .book-box .text {
    font-size: 11px;
    line-height: 15px;
  }
  .enduser-dashboard .courses .your-course .book-box .fee {
    font-size: 15px;
    line-height: 21px;
  }
  .enduser-dashboard .courses .your-course .book-box .check-circle {
    width: 30px;
  }
  .enduser-dashboard .courses .your-course .nav-link {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses #coursesTabContent .box,
  .course-detail .top-result .box {
    width: 300px;
    padding: 10px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .title,
  .course-detail .top-result .box .title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 6px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .text,
  .course-detail .top-result .box .text {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 8px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .student img,
  .course-detail .top-result .box .student img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .course-detail .search-course input,
  .enduser-dashboard .course-detail .lesson .search-course input,
  .enduser-dashboard .lesson-detail .lesson .search-course input {
    height: 46px;
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .course-detail .search-icon img,
  .enduser-dashboard .course-detail .lesson .search-icon img,
  .enduser-dashboard .lesson-detail .lesson .search-icon img {
    width: 30px;
  }
  .enduser-dashboard .course-detail .detail-page .course-info {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 30px;
  }
  .enduser-dashboard .course-detail .detail-page .student-img img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .course-detail .detail-page .price {
    font-size: 13px;
    line-height: 21px;
  }
  .enduser-dashboard .course-detail .detail-page .price span {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .course-detail .detail-page .course-text {
    font-size: 14px;
    line-height: 27px;
  }
  .enduser-dashboard .course-detail .detail-page .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .course-detail .lesson .title,
  .enduser-dashboard .lesson-detail .lesson .title {
    font-size: 24px;
    line-height: 37px;
  }
  .enduser-dashboard .course-detail .lesson table .live-btn,
  .enduser-dashboard .lesson-detail .lesson table .live-btn {
    font-size: 14px;
    line-height: 24px;
    padding: 2px 6px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(1),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(2),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(1),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(2) {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(3),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(5),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(7),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(3),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(5),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) {
    font-size: 15px;
    line-height: 24px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(7) img,
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) img {
    width: 24px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(8),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(8) {
    font-size: 13px;
    line-height: 21px;
  }
  .enduser-dashboard .lesson-detail .student-img img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .lesson-detail .attach-img img {
    width: 50px;
    height: 50px;
  }
  .enduser-dashboard .lesson-detail .attach-img img:first-child {
    width: 60px;
    height: 60px;
  }
  .enduser-dashboard .lesson-detail .lesson-img {
    padding: 10px;
    margin-top: 65px;
  }
  .enduser-dashboard .lesson-detail .lesson-img .fee,
  .course-detail .top-result .lesson-img .fee {
    font-size: 12px;
    line-height: 18px;
    left: 20px;
  }
  .enduser-dashboard .lesson-detail .member {
    font-size: 14px;
    line-height: 22px;
  }
  .enduser-dashboard .lesson-detail .attach-files .title,
  .enduser-dashboard .lesson-detail .heading {
    font-size: 15px;
    line-height: 24px;
  }
  .enduser-dashboard .lesson-detail .lesson-title {
    font-size: 24px;
    line-height: 42px;
  }
  .enduser-dashboard .lesson-detail .text {
    font-size: 14px;
    line-height: 26px;
  }
  .enduser-dashboard .lesson-detail .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .title {
    font-size: 19px;
    line-height: 29px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .left-round-img {
    width: 40px;
    height: 40px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .media .name {
    font-size: 15px;
    line-height: 27px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .media .comment {
    font-size: 13px;
    line-height: 23px;
  }
  #attachmentFileModal .modal-header {
    padding: 16px;
  }
  #attachmentFileModal .modal-title {
    font-size: 22px;
    line-height: 36px;
  }
  #attachmentFileModal .modal-body {
    padding: 32px;
  }
  #attachmentFileModal .attach-img {
    width: 60px;
    height: 60px;
  }
  #attachmentFileModal .file-title {
    font-size: 13px;
    line-height: 24px;
  }
  #attachmentFileModal .download-img {
    width: 30px;
    height: 30px;
  }
  #attachmentFileModal .download-text {
    font-size: 7px;
    line-height: 11px;
  }
  .course-ask-question .message-detail .media img {
    width: 34px;
    height: 34px;
  }
  .course-ask-question .message-detail .messanger-name {
    font-size: 15px;
    line-height: 13px;
  }
  .course-ask-question .message-detail .messanger-text {
    font-size: 14px;
    line-height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .messanger-name {
    font-size: 13px;
    line-height: 22px;
  }
  .course-ask-question .message-detail .chat-messages .messanger-text {
    font-size: 12px;
    line-height: 20px;
  }
  .course-ask-question .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg img:nth-child(1),
  .course-ask-question .message-detail .chat-messages .new-msg img:nth-child(2),
  .course-ask-question
    .message-detail
    .chat-messages
    .new-msg
    img:nth-child(3) {
    width: 18px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg img:last-child {
    width: 34px;
    height: 34px;
  }
  .admin-membership .library-box {
    width: 223px;
  }
  .admin-membership .content-header .title {
    font-size: 26px;
    line-height: 45px;
  }
  .admin-membership .scheduled-content .title,
  .admin-membership .most-favourite .title {
    font-size: 24px;
    line-height: 37px;
  }
  .admin-membership .scheduled-content .form-control {
    width: 23%;
  }
  .admin-membership .scheduled-content .content-box .title,
  .admin-membership .most-favourite .content-box .title {
    font-size: 16px;
    line-height: 25px;
  }
  .admin-membership .scheduled-content .content-box .text,
  .admin-membership .most-favourite .content-box .text {
    font-size: 13px;
    line-height: 20px;
  }
  .admin-membership .scheduled-content .content-box .visibile,
  .admin-membership .scheduled-content .content-box .date,
  .admin-membership .most-favourite .content-box .visibile,
  .admin-membership .most-favourite .content-box .date {
    font-size: 12px;
    line-height: 19px;
  }
  .admin-membership .scheduled-content .content-box .content-over-center {
    width: 50px;
    height: 50px;
  }
  #addAlbumModal .modal-title,
  #albumWantToCreateModal .modal-title {
    font-size: 22px;
    line-height: 44px;
    padding-left: 0px;
  }
  #addAlbumModal .modal-body {
    padding: 16px;
  }
  #addAlbumModal .avail-info {
    width: 18px;
    height: 18px;
  }
  #addAlbumModal .btn-custom,
  #albumWantToCreateModal .btn-custom {
    margin-top: 24px;
    margin-bottom: 10px;
  }
  #albumWantToCreateModal .btn-select {
    width: 160px;
    height: 48px;
    margin-right: 10px;
    margin-bottom: 25px;
  }
  #albumWantToCreateModal .modal-body {
    padding: 30px;
  }
  #albumWantToCreateModal .location img {
    width: 80px;
    height: 80px;
  }
  .enduser-dashboard .enduser-event .title {
    font-size: 30px;
    line-height: 46px;
  }
  .enduser-dashboard .enduser-event .upcoming-event .upcoming-title {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-dashboard .enduser-event .upcoming-event .event-list .event-title,
  .enduser-dashboard .enduser-event .upcoming-event .event-list .event-text {
    font-size: 15px;
    line-height: 23px;
  }
  .enduser-dashboard .enduser-event .upcoming-event .event-list .event-time,
  .enduser-dashboard .enduser-event .upcoming-event .event-list .event-link {
    font-size: 13px;
    line-height: 18px;
  }
  .enduser-dashboard .enduser-event .view-event.show .view-title {
    padding-top: 16px;
    padding-left: 30px;
  }
  .enduser-dashboard .enduser-event .view-event .event-img .event-name {
    font-size: 20px;
    line-height: 28px;
  }
  .admin-membership-setting .title,
  .admin-membership-setting .sub-title {
    font-size: 22px;
  }
  .admin-membership-setting .title,
  .admin-membership-setting .calendar {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .admin-membership-setting .calendar img {
    width: 48px;
    height: 48px;
  }
  .admin-membership-setting .calendar input {
    height: 48px;
  }
  .admin-membership-setting .deliverables-box {
    padding-right: 0px;
    min-height: 464px;
  }
  .admin-membership-setting .member-box .box-title {
    font-size: 17px;
    line-height: 27px;
  }
  .admin-membership-setting .member-box {
    width: 167px;
    height: 197px;
  }
  .admin-membership-setting .nav-pills .nav-link {
    font-size: 15px;
    line-height: 20px;
  }
  .admin-membership-setting .tab-content .tab-title {
    font-size: 22px;
    line-height: 47px;
  }
  .admin-membership-setting .AccountDetails .edit-button {
    width: 69px;
  }
  .admin-membership-setting .AccountDetails .user-img {
    width: 150px;
    height: 150px;
  }
  .admin-membership-setting .NotificationsSettings .btn-custom,
  .admin-membership-setting .GeneralSettings .btn-custom,
  .admin-membership-setting .WelcomePageSetting .btn-custom,
  .admin-membership-setting .AccountDetails .btn-custom,
  .admin-membership-setting .PolicyPages .btn-custom,
  .admin-membership-setting .NotificationsSettings .btn-custom1,
  .admin-membership-setting .GeneralSettings .btn-custom1,
  .admin-membership-setting .WelcomePageSetting .btn-custom1,
  .admin-membership-setting .AccountDetails .btn-custom1,
  .admin-membership-setting .PolicyPages .btn-custom1,
  .admin-membership-setting .Unsubscribe .btn-custom,
  .admin-membership-setting .Unsubscribe .btn-custom1 {
    width: 190px;
    height: 48px;
  }
  .admin-membership-setting .change-password .btn-custom,
  .admin-membership-setting .change-password .btn-custom1 {
    height: 48px;
  }
  .admin-membership-setting .AccountDetails .plan {
    margin-right: 20px;
    margin-bottom: 40px;
    width: 440px;
  }
  .admin-membership-setting .AccountDetails .plan .card-body,
  .admin-membership-setting .AccountDetails .explore-plans .card-body {
    padding: 16px;
  }
  .admin-membership-setting .AccountDetails .plan .badge-custom {
    font-size: 11px;
    line-height: 26px;
  }
  .admin-membership-setting .AccountDetails .plan .plan-name,
  .admin-membership-setting .AccountDetails .explore-plans .plan-name {
    font-size: 14px;
    line-height: 24px;
  }
  .admin-membership-setting .AccountDetails .plan .plan-price,
  .admin-membership-setting .AccountDetails .explore-plans .plan-price {
    font-size: 12px;
    line-height: 18px;
  }
  .admin-membership-setting .AccountDetails .plan .plan-detail,
  .admin-membership-setting .AccountDetails .explore-plans .plan-detail {
    font-size: 12px;
    line-height: 18px;
  }
  .admin-membership-setting .AccountDetails .explore-plans {
    margin-right: 13px;
    margin-bottom: 40px;
    width: 365px;
  }
  .admin-membership-setting .AccountDetails .explore-plans .btn-plan {
    font-size: 16px;
    line-height: 26px;
    height: 28px;
  }
  .admin-membership-setting .tab-content .tab-subtitle {
    font-size: 20px;
    line-height: 39px;
  }
  .admin-membership-setting .CompanyDetails .edit-button-img {
    width: 69px;
  }
  .admin-membership-setting .tab-content .tab-text {
    font-size: 16px;
    line-height: 23px;
  }
  .admin-membership-setting .PolicyPages .nav-tabs .nav-item {
    font-size: 15px;
    line-height: 36px;
    padding: 8px 0px;
  }
  .admin-membership-setting .tab-content .tab-message {
    font-size: 18px;
    line-height: 41px;
  }
  .admin-membership-setting .Unsubscribe .form-check-input {
    width: 16px;
    height: 16px;
  }
  .admin-membership-setting .Unsubscribe .form-check-label {
    font-size: 14px;
    line-height: 28px;
  }
  .admin-membership-setting .info-img {
    width: 16px;
  }
  .admin-social-wall #socialWallTabContent .owl-prev {
    left: -50px;
  }
  .admin-social-wall #socialWallTabContent .owl-next {
    right: -45px;
  }
  .admin-social-wall #socialWallTabContent .owl-prev img {
    width: 40px;
    height: 40px;
  }
  .admin-social-wall #socialWallTabContent .owl-next img {
    width: 40px;
    height: 40px;
  }
  .admin-event .upcoming-event {
    padding: 16px;
  }
  #showPostModal .social-modal-slider {
    margin-top: -70px;
  }
  #createMembershipLevelModal .close-img {
    width: 40px;
  }
  #addLessonDetailsModal .info-img {
    width: 16px;
  }
  .admin-courses .courses-folders .info-img,
  .admin-courses .courses-list .info-img {
    width: 16px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1200px) {
  #banner,
  #login,
  #register,
  #forgot,
  #account-verification,
  #welcome,
  #tour,
  #membership,
  #multiple-membership,
  #user-membership-login,
  #user-welcome {
    height: auto;
    margin: 10px auto;
  }
  .enduser-dashboard .calendar select {
    width: 70%;
  }
  .notification.dropdown-menu.dropdown-menu-lg.dropdown-menu-right {
    max-width: max-content;
  }
  .notification.dropdown-menu-right {
    right: -10rem !important;
  }
  .download-link {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #tour .tour-slider .owl-next {
    width: 50px;
  }
  .enduser-dashboard #all .card {
    width: 200px;
    margin: 0px 10px 15px;
  }
  .enduser-dashboard #all .card .card-img-top {
    width: 200px;
    height: 290px;
  }
  .enduser-dashboard #post .post-now {
    margin-bottom: 30px;
  }
  #register .card-body {
    padding: 42px 30px 30px;
  }
  #account-verification .card-body {
    padding: 16px 38px;
  }
  #welcome .card-body {
    padding: 16px 40px;
  }
  .content-page-setting .btn-custom {
    font-size: 20px;
    line-height: 32px;
    width: 100%;
    height: 50px;
  }
  .enduser-dashboard .profile #profile-tab .nav-link {
    font-size: 14px;
    line-height: 40px;
  }
  .enduser-dashboard .content-library-folder .border-line::after {
    width: 50%;
  }
  .enduser-dashboard .plan-details,
  .enduser-dashboard .payment-details {
    padding: 16px;
  }
  .enduser-dashboard .plan-details .plan,
  .enduser-dashboard .payment-details .plan,
  .enduser-dashboard .plan-details .tc,
  .enduser-dashboard .payment-details .tc {
    padding: 30px;
  }
  .enduser-dashboard .audio-library .discover .border-line::after,
  .enduser-dashboard .video-library .discover .border-line::after,
  .enduser-dashboard .courses .discover .border-line::after {
    border: none;
    width: auto;
  }
  .enduser-dashboard .audio-library .discover .border-line .form-control,
  .enduser-dashboard .video-library .discover .border-line .form-control {
    width: 30%;
  }
  .enduser-dashboard .audio-library .new-audio .border-line::after,
  .enduser-dashboard .video-library .new-video .border-line::after {
    width: 70%;
  }
  .enduser-dashboard .audio-library .popular-folder .border-line::after,
  .enduser-dashboard .video-library .popular-folder .border-line::after {
    width: 60%;
  }
  .admin-video-library .video-list .after-search .sort-by select {
    margin-bottom: 10px;
  }
  .admin-video-library
    .video-list
    #videoLibraryTabContent
    #videoList
    .table
    tr
    td:nth-child(2)
    img,
  .admin-video-library
    .video-list
    #videoLibraryTabContent
    #scheduledVideos
    .table
    tr
    td:nth-child(2)
    img {
    width: 186px;
    height: 75px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .admin-messages .student-queries {
    padding: 0px;
    padding-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #e8e8e8;
  }
  .enduser-dashboard .courses .your-course #coursesTab::after {
    border: none;
    width: auto;
  }
  .enduser-dashboard .course-detail .search-course input {
    width: auto;
  }
  .enduser-dashboard .enduser-event .view-event {
    padding: 20px;
    margin: 0px 0px 20px;
  }
  .enduser-dashboard .enduser-event .view-event .view-title {
    font-size: 20px;
    line-height: 28px;
  }
  .enduser-dashboard .enduser-event .view-event .view-text {
    font-size: 17px;
    line-height: 27px;
  }
  .enduser-dashboard .enduser-event .view-event .btn-custom {
    height: 48px;
  }
  .admin-membership-setting .title {
    margin-top: 30px;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .admin-membership-setting .calendar {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 991px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 0px !important;
    padding-left: 0px;
  }
  .enduser-dashboard #videos .card,
  .enduser-dashboard #photos .card {
    margin: 0px auto;
  }
  .enduser-dashboard #courses .card,
  .enduser-dashboard #challenges .card {
    margin: 0 auto 20px;
  }
  #showPostModal #postCarousel {
    margin-top: -25px;
    margin-bottom: 20px;
  }
  #showPostModal .carousel-control-prev {
    left: -30px;
  }
  #showPostModal .carousel-control-next {
    right: -30px;
  }
  .admin-social-wall .posts .lg-img {
    width: 297px;
    height: 240px;
  }
  .admin-video-library .video-folders .grid-view,
  .admin-audio-library .audio-folders .grid-view {
    padding-left: 10px;
    padding-right: 20px;
  }
  .admin-video-library .video-folders .list-view,
  .admin-audio-library .audio-folders .list-view {
    padding-right: 10px;
  }
  #showPostModal .social-modal-slider {
    margin-top: -20px;
  }
  .notification-box {
    font-size: calc(100% - 0.2em);
  }
  .notification-content {
    font-size: small;
    font-weight: 600;
  }
  .notification.dropdown-menu-right {
    right: -12rem !important;
  }
}
@media only screen and (max-width: 480px) {
  .notification-content:nth-child(1) {
    font-weight: normal;
  }
  .notification.dropdown-menu-right {
    right: -9.5rem !important;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 6px;
    padding-left: 6px;
  }
  #setupGuide .btn-setup {
    width: auto;
  }
  #login .card-body,
  #welcome .card-body,
  #tour .card-body {
    padding: 30px 16px;
  }
  #filterMembersModal .modal-dialog {
    margin: 0 0 0 auto;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .enduser-dashboard #youFav .card,
  .enduser-dashboard #suggEvent .card,
  .enduser-dashboard #latestCont .card {
    width: 165px;
  }
  .enduser-dashboard #post .post-now select.form-control {
    width: 68%;
    margin-left: 0px !important;
  }
  #tour .tour-slider .owl-nav {
    position: absolute;
    top: -40px;
    right: -10px;
  }
  #landingPage .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #landingPage .text {
    font-size: 14px;
    line-height: 24px;
  }
  #landingPage .sub-title2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  #stepsSetup .company-logo {
    margin: 0 14% 0 auto;
  }
  #landingPage .btn-custom {
    width: 100%;
    font-size: 20px;
    line-height: 38px;
  }
  #stepsSetup form .btn-custom {
    width: 100%;
  }
  #stepsSetup .subscription img {
    width: 120px;
  }
  #stepsSetup .plan-info .card-body {
    padding: 16px;
  }
  #stepsSetup .plan-info .plan-name span {
    font-size: 14px;
  }
  #stepsSetup .plan-info .plan-type span {
    font-size: 12px;
  }
  #stepsSetup .plan-info .btn-outline-custom,
  #stepsSetup .plan-info .btn-custom {
    width: 100%;
  }
  .admin-content-library .readinglist .left-img,
  .admin-audio-library .playlist .left-img,
  .admin-video-library .playlist .left-img,
  .enduser-dashboard .audio-folder .playlist .left-img,
  .enduser-dashboard .my-playlist .playlist .left-img {
    right: 250px;
  }
  .admin-content-library .readinglist .name,
  .admin-audio-library .playlist .name,
  .admin-video-library .playlist .name,
  .enduser-dashboard .audio-folder .playlist .name,
  .enduser-dashboard .my-playlist .playlist .name {
    width: 268px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    width: 100%;
  }
  .admin-content-library .content-list .nav-item,
  .admin-content-library .content-list .search-content input,
  .admin-audio-library .audio-list .nav-item,
  .admin-audio-library .audio-list .search-library input,
  .admin-video-library .video-list .nav-item,
  .admin-video-library .video-list .search-library input,
  .enduser-dashboard .audio-folder .folder-info .form-control {
    width: 100%;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #wantToCreateModal .video .fa-video,
  #addVideoModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #selectFolderModal .folder .fa-folder,
  #selectFolderModal .audio .fa-microphone,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio,
  #wantToCreateModal .folder-img,
  #selectFolderModal .folder-img {
    margin: 6px;
  }
  #wantToCreateModal .modal-title,
  #createFolderModal .modal-title,
  #addVideoDetailsModal .modal-title,
  #addAudioDetailsModal .modal-title,
  #addLessonDetailsModal .title,
  #addAudioModal .modal-title,
  #addVideoModal .modal-title,
  #createSubFolderModal .title,
  #selectFolderModal .modal-title {
    font-size: 18px;
    line-height: 35px;
    padding-left: 0px;
  }
  #createFolderModal .btn-custom,
  #addVideoDetailsModal .btn-custom,
  #addAudioDetailsModal .btn-custom,
  #addLessonDetailsModal .btn-custom,
  #createSubFolderModal .btn-custom {
    width: 100%;
  }
  #wantToCreateModal .select-folder .card,
  #selectSubFolderModal .folder .card {
    margin: 10px 10px 10px 5px;
  }
  #wantToCreateModal .select-folder .card .card-body,
  #selectSubFolderModal .folder .card .card-body {
    padding: 10px;
  }
  .content-choose-template .template .content .heading,
  .content-edit-template .template .content .heading {
    font-size: 14px;
    line-height: 20px;
  }
  .content-choose-template .template,
  .content-edit-template .template {
    padding: 12px;
  }
  .content-choose-template,
  .content-edit-template {
    margin: 20px 0px;
    text-align: center;
  }
  .content-edit-template .upload-media .btn-custom {
    font-size: 16px;
    line-height: 26px;
    width: 160px;
    height: 45px;
  }
  .enduser-dashboard .profile #account-details .plans .btn-custom,
  .enduser-dashboard .profile #account-details .explore-plans .btn-custom {
    width: 100%;
  }
  .enduser-dashboard .payment-details .card-detail form {
    padding: 16px;
  }
  .enduser-dashboard .payment-details .btn-custom,
  .enduser-dashboard .plan-details .btn-custom {
    margin: 16px;
  }
  .enduser-dashboard .plan-details .tc .text,
  .enduser-dashboard .payment-details .tc .text {
    line-height: 28px;
  }
  #paymentSuccessfull .title {
    font-size: 20px;
    line-height: 40px;
  }
  #paymentSuccessfull .text {
    font-size: 13px;
    line-height: 25px;
  }
  .enduser-dashboard .audio-library .new-audio .border-line::after,
  .enduser-dashboard .video-library .new-video .border-line::after {
    width: 60%;
  }
  .enduser-dashboard .audio-library .popular-folder .border-line::after,
  .enduser-dashboard .video-library .popular-folder .border-line::after {
    width: 45%;
  }
  .enduser-dashboard .my-playlist .playlist-board .upload-file {
    margin-right: 0px;
  }
  #showPostModal .carousel-control-prev {
    left: -60px;
  }
  #showPostModal .carousel-control-next {
    right: -60px;
  }
  .admin-social-wall #socialWallTab .search-library input {
    width: 100%;
  }
  .admin-social-wall .posts .show-media {
    flex-wrap: wrap;
  }
  .admin-social-wall .post-area .btn-custom {
    height: auto;
  }
  #renewalAndSuceessModal .nav-pills .nav-link {
    font-size: 14px;
    padding: 16px 6px;
  }
  .admin-student-queries .message-detail {
    padding: 10px;
  }
  .admin-student-queries .student-queries .title img,
  .admin-student-queries .message-detail .filters img,
  .admin-student-queries .chat-messages .attachment img {
    width: 30px;
    height: 30px;
  }
  .admin-event {
    margin-left: 0px;
    margin-right: 0px;
  }
  .enduser-dashboard .courses .queries-icon {
    margin-right: 15px;
  }
  .enduser-dashboard .courses .playlist .name {
    width: 280px;
  }
  .enduser-dashboard .courses .discover .form-control {
    width: 100%;
  }
  .enduser-dashboard .courses .your-course .border-line::after {
    width: 45%;
  }
  .enduser-dashboard .courses .your-course .book-box {
    width: 100%;
  }
  .enduser-dashboard .courses .your-course .nav-item {
    width: 135px;
  }
  .enduser-dashboard .courses #coursesTabContent .box,
  .course-detail .top-result .box {
    width: 100%;
    padding: 10px;
    margin-right: 15px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .banner-img,
  .course-detail .top-result .box .banner-img {
    width: 100%;
  }
  .enduser-dashboard .course-detail .return-page::after {
    border-bottom: none;
    width: auto;
  }
  .enduser-dashboard .lesson-detail .lesson-comments textarea {
    width: 100%;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .send {
    margin-top: 10px;
  }
  .enduser-dashboard .lesson-detail .lesson-img {
    margin-top: 0px;
  }
  .enduser-dashboard .lesson-detail .member {
    margin-bottom: 30px;
  }
  .enduser-dashboard .lesson-detail .lesson .search-course input {
    width: 100%;
  }
  #attachmentFileModal .attach-img {
    width: 40px;
    height: 40px;
  }
  #attachmentFileModal .file-title {
    font-size: 12px;
    line-height: 23px;
  }
  #attachmentFileModal .download-img {
    width: 25px;
    height: 25px;
  }
  .course-ask-question .chat-messages .attachment img {
    width: 30px;
    height: 30px;
  }
  .admin-membership .scheduled-content .form-control {
    width: 100%;
  }
  .admin-membership .scheduled-content .content-box .content-over-center {
    width: 50px;
  }
  #addAlbumModal .btn-custom,
  #albumWantToCreateModal .btn-custom {
    width: 100%;
  }
  .enduser-dashboard .enduser-event .view-event .event-info,
  .enduser-dashboard .enduser-event .view-event .event-description,
  .enduser-dashboard .enduser-event .view-event .participants,
  .enduser-dashboard .enduser-event .view-event .comments {
    padding-left: 20px;
    padding-right: 20px;
  }
  .enduser-dashboard .enduser-event .view-event .btn-custom {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .admin-membership-setting .member-box {
    width: 160px;
    height: 190px;
    margin-right: 25px;
  }
  .admin-membership-setting .member-box:nth-child(even) {
    margin-right: 0px;
  }
  .admin-membership-setting .AccountDetails .plan,
  .admin-membership-setting .AccountDetails .explore-plans {
    margin-right: 0px;
  }
  .admin-membership-setting .AccountDetails .explore-plans {
    width: 395px;
  }
  .admin-membership-setting .NotificationsSettings .btn-custom,
  .admin-membership-setting .GeneralSettings .btn-custom,
  .admin-membership-setting .WelcomePageSetting .btn-custom,
  .admin-membership-setting .AccountDetails .btn-custom,
  .admin-membership-setting .PolicyPages .btn-custom,
  .admin-membership-setting .NotificationsSettings .btn-custom1,
  .admin-membership-setting .GeneralSettings .btn-custom1,
  .admin-membership-setting .WelcomePageSetting .btn-custom1,
  .admin-membership-setting .AccountDetails .btn-custom1,
  .admin-membership-setting .PolicyPages .btn-custom1,
  .admin-membership-setting .Unsubscribe .btn-custom,
  .admin-membership-setting .Unsubscribe .btn-custom1,
  .admin-membership-setting .change-password .btn-custom,
  .admin-membership-setting .change-password .btn-custom1 {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .admin-membership-setting .PolicyPages .nav-tabs .nav-item {
    flex-basis: auto;
    flex-grow: 0;
    width: 100%;
  }
  .admin-courses .courses-list #coursesTabContent .table td:nth-child(2),
  .admin-courses .courses-list #coursesTabContent .table td:nth-child(3) {
    word-break: unset;
    word-wrap: unset;
  }
  .admin-social-wall #socialWallTabContent .owl-prev {
    left: -33px;
  }
  .admin-social-wall #socialWallTabContent .owl-next {
    right: -33px;
  }
  #membershipBillingOptionsModal .info-hover,
  #createMembershipLevelModal .info-hover,
  .admin-content-library .info-hover,
  .admin-content-library .content-folders .info-hover,
  .admin-membership-setting .info-hover,
  #addLessonDetailsModal .info-hover {
    width: 280px;
  }
  .admin-content-library .content-folders .info-hover {
    top: 90%;
    left: -150px;
  }
  .admin-membership-setting .info-hover {
    top: 90%;
    left: -50px;
  }
  #addLessonDetailsModal .info-hover {
    top: 80%;
    left: -100px;
  }
}
@media only screen and (max-width: 380px) {
  #stepsSetup .company-logo {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 320px) {
  .admin-membership-setting .member-box {
    width: 140px;
    margin-right: 10px;
  }
  .admin-membership-setting .member-box .box-title {
    font-size: 15px;
  }
  #membershipBillingOptionsModal .info-hover,
  #createMembershipLevelModal .info-hover,
  .admin-content-library .info-hover,
  .admin-content-library .content-folders .info-hover,
  .admin-membership-setting .info-hover,
  #addLessonDetailsModal .info-hover {
    width: 250px;
  }
  .admin-content-library .content-folders .info-hover {
    left: -180px;
  }
  .admin-membership-setting .info-hover {
    top: 90%;
    left: -55px;
  }
  #addLessonDetailsModal .info-hover {
    left: -140px;
  }
}
/*------LOADER------ */
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -9px 0 0 -35px;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #a0dbdd;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*------LOADER------ */
/* Rahul */
body {
  margin: 0;
  padding: 0;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.empty-input {
  color: red;
}

.slider {
  padding: 22px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.main-subs-expired-div {
  position: relative;
  width: 100%;
  height: 100%;
}

/* .inner-subs-div {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 30%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2em;
} */
.inner-subs-div {
  position: absolute;
  left: 35%;
  right: 25%;
  top: 25%;
  box-shadow: rgba(122, 215, 198, 0.34) 0px 7px 29px 0px;
  padding: 2em;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 1em;
}

.congradulation-section {
  position: relative;
}

.congradulation-section img:nth-child(1) {
  width: 12em;
  height: auto;
}

.congradulation-section p:nth-child(2) {
  color: #00aeb3 !important;
  font-size: 13px !important;
  color: #067578 !important;
}

.congradulation-section img:nth-child(3) {
  width: 6em;
  height: auto;
  position: absolute;
  left: 2%;
  z-index: 100;
  top: 15%;
  background-color: none !important;
}

.congrats-success-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00aeb3;
  margin-top: 2em;
  padding: 1em;
  width: 60%;
  border-radius: 1em;
}

.congrats-success-div > label:nth-child(1) {
  font-weight: 400;
  color: white;
  font-size: 18px;
}

.congrats-success-div > label:nth-child(2) {
  text-align: center;
  color: white;
}

.payment-failed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.payment-failed p {
  text-align: center;
  color: #056e71;
}

.failed-payment-div {
  background-color: #00aeb3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  border-radius: 1em;
}

.back-to-subs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-to-subs img {
  background-color: #bfeeef;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  padding: 0.5em;
}

.back-to-subs label {
  margin-left: 1em;
  color: #00aeb3;
}

.failed-payment-div > label:nth-child(1) {
  color: white;
  margin-top: 0.5em;
}

.failed-payment-div img:nth-child(2) {
  width: 3em;
  height: auto;
  margin-bottom: 5px;
}

.failed-payment-div > label:nth-child(3) {
  color: white;
  margin: 0;
}

.failed-payment-div > label:nth-child(4) {
  color: white;
}

.click-begib-lbl {
  width: 100%;
  background-color: white;
  margin-top: 1em;
  padding: 0.5em 1.5em;
  color: black;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.choose-subs-div img:nth-child(3) {
  background-color: white !important;
  width: 12em !important;
}

.choose-subs-div label:nth-child(2) {
  font-size: 13px !important;
  font-weight: 200 !important;
  color: black !important;
}

.subscription-section {
  padding: 0.5em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  height: 45em;
  width: 100%;
  justify-content: space-evenly;
  position: relative;
  gap: 1.5em;
}

.subscription-section .best-option-lbl {
  position: absolute;
  right: -3%;
  top: 16px;
  font-size: 30px;
  transform: rotate(35deg);
  color: #00aeb3;
  font-family: "Sacramento", cursive;
}

.subscription-section::-webkit-scrollbar {
  width: 3px;
}

.unsubscribe-bg {
  background: #a91919 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.unsubscribe-bg:hover {
  background-color: #a91919 !important;
  color: white !important;
}

.subscription-section::-webkit-scrollbar-track {
  background-color: white;
}

.subscription-section::-webkit-scrollbar-thumb {
  background-color: #00aeb3;
  height: 5px;
}

.subscription-section::-webkit-scrollbar-thumb:hover {
  background-color: #00aeb3;
}

.subs-card-main {
  width: 44%;
  height: 90% em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
  align-items: center;
  padding: 1em;
}

.subs-card-main > label:nth-child(1) {
  font-weight: 600;
  font-size: 20px;
  color: #00aeb3;
  text-align: center;
  height: 2.5em;
}
.left-main-sidebar button {
  background-color: #343a40;
  text-align: left;
}
.content-bg {
  background-color: #343a40 !important;
}
.subs-card-main label:nth-child(2) {
  font-weight: 600;
  font-size: 14px;
  color: #00aeb3;
}
.content-folder-name {
  color: #726a6a;
  margin-top: 1em;
}
.student-list-next-prev {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.student-list-next-prev button:nth-child(1) {
  width: 7em;
  background-color: #00aeb3;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  margin-top: 1em;
}
.sun-editor .se-wrapper .se-wrapper-code {
  background-color: white !important;
  color: black !important;
}
.student-list-next-prev button:nth-child(2) {
  width: 7em;
  background-color: #00aeb3;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  margin-top: 1em;
}
.student-list-next-prev button {
  cursor: pointer;
}
.subs-card-main label:nth-child(3) {
  font-weight: 400;
  font-size: 12px;
  color: #00aeb3;
}

.description-and-pointer-div-hide {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.7em !important;
  margin: 1em 0 2em 0;
  height: 1.6em;
  overflow: hidden;
  width: 85%;
  margin-left: auto;
}
.description-and-pointer-div-show {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.7em !important;
  margin: 1em 0 2em 0;
  height: fit-content;
  width: 85%;
  margin-left: auto;
}
.load-more-lbl {
  color: #00aeb3;
  width: 85%;
  margin: -1.5em 0 1em 0;
  margin-left: auto;
  cursor: pointer;
}
.pointers-main-div-show {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pointers-main-div-show div div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}
.pointers-main-div-show div div label:nth-child(1) {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #00aeb3;
}
.pointers-main-div-show div div label:nth-child(2) {
  font-size: 16px;
  color: #4c4b4b;
  font-weight: 400;
}
.bullet-main-div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-left: -10%;
  margin-bottom: 2em;
}

.bullet-main-div div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.bullet-main-div div label:nth-child(1) {
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background-color: #00aeb3;
}

.bullet-main-div div label:nth-child(2) {
  font-size: 16px;
  color: #4c4b4b;
}

.yearly-btn {
  padding: 0.5em 2.3em;
  border: none;
  outline: none;
  border-radius: 0.5em;
  background-color: #00aeb3;
}

.yearly-btn label:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 0;
}

.yearly-btn label:nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 0;
}

.yearly-btn label:nth-child(3) {
  font-size: 14px;
  font-weight: 300;
  color: white;
  margin: 0;
}

.monthly-btn {
  border: none;
  outline: none;
  margin-top: 1em;
  padding: 0.6em 3.2em;
  border-radius: 0.5em;
  background-color: #00aeb3;
}

.payment-portal-logo-des {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-portal-logo-des img {
  width: 12em;
  height: auto;
}

.payment-portal-logo-des p {
  font-size: 11px !important;
  font-size: 200 !important;
  text-align: center;
  margin-top: 1em;
  color: #054143;
}

.expire-subs-div {
  position: absolute;
  left: 40%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: 30%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-btn label {
  color: white !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  margin: 0;
}

.subs-card-main button:hover {
  cursor: pointer;
}

.payment-portal-directed {
  width: 90%;
  margin: 1.5em auto;
  background-color: #00aeb3;
  padding: 1em 2em;
  border-radius: 1em;
}

.payment-portal-directed p {
  color: white !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 1.3em !important;
  text-align: center;
}

.payment-continue {
  border: none;
  outline: none;
  margin: 1em 35%;
  padding: 0.7em 1.5em;
  border-radius: 0.5em;
  font-weight: 500;
}

.explore-subscription-section {
  width: 66.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4.5em;
  position: relative;
}

.best-option-lbl {
  position: absolute;
  right: -10%;
  top: -15px;
  font-size: 40px;
  transform: rotate(35deg);
  color: #00aeb3;
  font-family: "Sacramento", cursive;
}

.congradulation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congradulation-section img {
  width: 15em;
  height: auto;
  background-color: white !important;
}

.congradulation-section p {
  text-align: center;
}

.congradulation-message {
  background-color: #00aeb3;
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 2em;
  border-radius: 1em;
  gap: 1em;
}

.congradulation-message label:nth-child(1) {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.congradulation-message label:nth-child(2) {
  font-size: 18px;
  color: white;
  text-align: center;
}

.click-to-begin-btn {
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5em 1em;
}

.congradulation-section p {
  width: 70%;
  font-size: 12px !important;
  line-height: 1.5em !important;
  font-weight: 100 !important;
  margin: 1em auto;
  color: #054143 !important;
}

.congradulation-message label:nth-child(3) {
  color: white !important;
}

.go-back-subs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.go-back-subs label:nth-child(1) {
  width: 2.5em;
  height: 2.5em;
  background-color: #cbeced;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.go-back-subs label:nth-child(2) {
  margin-left: 1em;
  color: #00aeb3;
}

.congradulation-message img:nth-child(2) {
  background-color: none !important;
  width: 3em !important;
  height: 5em !important;
}

.return-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.create-sub-item-link {
  color: #12aeb4;
}
.create-sub-item-link:hover {
  color: #12aeb4;
}

#socialCreateModal .modal-title,
.socialEditModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #12aeb4;
}
#socialCreateModal .modal-header .close img,
.socialEditModal .modal-header .close img {
  width: 35px;
}
#socialCreateModal .modal-content,
.socialEditModal .modal-content {
  border-radius: 24px;
}
#socialCreateModal .title,
.socialEditModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  margin-bottom: 17px;
}
#socialCreateModal .label-title,
.socialEditModal .label-title, #createFolderModal .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 9px;
}
#socialCreateModal .label-title small,
.socialEditModal .label-title small, #createFolderModal .label-title small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a6a7ae;
}
#socialCreateModal input.form-control,
#socialCreateModal input.form-control:focus,
.socialEditModal input.form-control,
.socialEditModal input.form-control:focus {
  background-color: #ebf7f6;
  color: #222;
  border-color: #ebf7f6;
  font-size: 16px;
  line-height: 25px;
  height: 58px;
}
#socialCreateModal textarea.form-control,
#socialCreateModal textarea.form-control:focus,
.socialEditModal textarea.form-control,
.socialEditModal textarea.form-control:focus {
  background-color: #ebf7f6;
  color: #222;
  border-color: #ebf7f6;
  font-size: 16px;
  line-height: 25px;
  height: 143px;
}
#socialCreateModal .upload-file,
.socialEditModal .upload-file {
  position: absolute;
  right: 10px;
  top: auto;
  margin-top: 25px;
}
#socialCreateModal .upload-file img,
.socialEditModal .upload-file img {
  cursor: pointer;
}
#socialCreateModal .table thead tr th,
.socialEditModal .table thead tr th {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  padding: 8px;
}
#socialCreateModal .table tbody tr td,
.socialEditModal .table tbody tr td {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a1629;
  padding: 8px;
}
#socialCreateModal .scroll,
.socialEditModal .scroll {
  max-height: 214px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding-right: 5px;
}
#socialCreateModal .scroll::-webkit-scrollbar,
.socialEditModal .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
#socialCreateModal .scroll::-webkit-scrollbar-thumb,
.socialEditModal .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}
#socialCreateModal .scroll::-webkit-scrollbar-track,
.socialEditModal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
#socialCreateModal .member-img,
.socialEditModal .member-img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 10px;
}
#socialCreateModal .view-more,
.socialEditModal .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12aeb4;
}
#socialCreateModal .topic,
.socialEditModal .topic {
  padding: 15px 10px;
  background-color: #ebf7f6;
}
#socialCreateModal .topic .badge,
.socialEditModal .topic .badge {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0a1629;
  padding: 6px 20px;
  border: 1px solid #12aeb4;
  border-radius: 20px;
  margin-bottom: 15px;
  word-break: break-word;
  white-space: normal;
}
#socialCreateModal .topic .badge img,
.socialEditModal .topic .badge img {
  width: 20px;
  margin-left: 10px;
}
#socialCreateModal .topic input.form-control,
.socialEditModal .topic input.form-control {
  height: 40px;
  background-color: #ebf7f6;
  border: 1px solid #12aeb4;
  border-radius: 20px;
}
#socialCreateModal .btn-custom,
.socialEditModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  width: 330px;
  height: 57px;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  margin-top: 16px;
  margin-bottom: 32px;
}

.modal-wide .modal-dialog {
  max-width: 700px;
}

.wis-modal .modal-content {
  border-radius: 31px;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}
.wis-modal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3c3d43;
  word-break: break-word;
  word-wrap: break-word;
}
.wis-modal .modal-header,
.wis-modal .modal-body {
  padding: 41px 40px;
}
.wis-modal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}
.wis-modal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bebebe;
}
.wis-modal .form-group {
  margin-bottom: 30px;
}
.wis-modal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 35px;
  height: 58px;
}
.wis-modal .upload-file {
  position: absolute;
  right: 32px;
  top: auto;
  margin-top: 25px;
}
.wis-modal .date,
.wis-modal .select {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}
.wis-modal .access {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #ebf7f6;
  color: #242424;
  border-color: #ebf7f6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding: 8px 35px 40px;
}
.wis-modal .access-level {
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
}
.wis-modal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 282px;
  height: 57px;
  margin: 20px 0px 10px 0px;
}
.wis-modal .btn-custom:active:active {
  box-shadow: none;
}
.wis-modal .scroll {
  max-height: 285px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding-right: 5px;
}
.wis-modal .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.wis-modal .scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}
.wis-modal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.sidebar-collapsed,
.sidebar-expanded {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(18, 174, 180, 0.4980392157);
  transition: width 0.3s;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-height: fit-content;
  min-height: 300px;
  margin-top: 55px;
  margin-right: 10px;
}
.sidebar-expanded {
  width: max-content;
}
.sidebar-collapsed {
  width: 40px;
}
.sidebar-header {
  display: flex;
  justify-content: flex-end;
}
.toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.collapse-sidebar-content {
  padding: 10px;
  width: max-content;
}
.collapse-sidebar-content .dropdown {
  display: none;
  margin-bottom: 20px;
  cursor: pointer;
}
.collapse-sidebar-content .dropdown > div {
  font-size: 14px;
  font-weight: 400;
  color: #333244;
  cursor: pointer;
  line-height: 40px;
}
.collapse-sidebar-content .dropdown > div .right {
  transition: transform 0.3s ease;
  font-size: 25px;
}
.collapse-sidebar-content .dropdown .right.rotated {
  transform: rotate(90deg);
  font-size: 25px;
}
.collapse-sidebar-content .dropdown .sub-menu .nav-item {
  display: none;
  padding: 0px;
}
.collapse-sidebar-content .dropdown > div .nav-link {
  font-size: 14px;
}
.sidebar-expanded .dropdown,
.sidebar-expanded .dropdown .sub-menu,
.sidebar-expanded .dropdown .sub-menu .nav-item {
  display: block;
}
.branding {
  background: #ebf7f6;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 4px 3px 10px #a8aeae;
}
@media (min-width: 1600px) {
  .wis-modal .modal-dialog {
    max-width: 696px;
  }
}
@media (min-width: 1200px) {
  .download-link {
    width: 550px;
  }
}
@media only screen and (max-width: 1440px) {
  .wis-modal .modal-dialog {
    max-width: 696px;
  }
  .wis-modal .btn-custom {
    width: 224px;
    font-size: 16px;
    line-height: 26px;
    height: 44px;
  }
  .wis-modal .modal-header,
  .wis-modal .modal-body {
    padding: 16px;
  }
  .wis-modal .upload-file {
    right: 6px;
  }
  .wis-modal label,
  .wis-modal .form-control {
    font-size: 14px;
    line-height: 23px;
  }
  .wis-modal .form-control {
    padding-left: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .wis-modal .modal-title {
    font-size: 18px;
    line-height: 35px;
    padding-left: 0px;
  }
  .wis-modal .btn-custom {
    width: 100%;
  }
}

#coursesTab .nav-item {
  cursor: pointer;
}

.swiper-button-next,
.swiper-button-prev {
  color: #12aeb4 !important;
}

.swiper-wrapper {
  width: 2544px;
  padding-left: 80px;
  padding-right: 80px;
}
.download-data {
  width: 2em !important;
  height: 2em !important;
  display: inline-block;
}
.download-data .info-tooltip {
  visibility: hidden;
  width: 150px;
  color: black;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  right: 10%;
  top: -1em;
  z-index: 1;
}

.download-data:hover .info-tooltip {
  visibility: visible;
}
.social-wall-mention {
  color: var.$color-1;
  font-weight: 500;
}
.highlight__mention {
  background-color: #86d9e6;
}
.btn-email-modal {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12aeb4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 120px;
  height: 48px;
  margin-bottom: 48px;
}
.mentions__mention {
  background-color: #5ccbcf;
}

/*# sourceMappingURL=main.css.map */
