@use "../../utilities/variables" as var;
@use "subscription-status";
@use "button";
@use "membership";

.btn-primary {
  background-color: var.$color-1 !important;
  color: white !important;
  border: none !important;
}

.return-link {
  font-family: var.$font-main;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var.$color-grey;
}

.create-sub-item-link{
  color: var.$color-1;
  &:hover{
    color: var.$color-1;
  }
}