@use '../../utilities/_variables.scss' as var;

.swiper-button-next, .swiper-button-prev{
    color: var.$color-1 !important;
}
.swiper-wrapper{
    width: 2544px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
}