.success-modal, modal-success {
  /*playlist added successfully modal*/
  .modal-content {
    border: 1px solid #dedede;
    box-shadow: none;
    border-radius: 15px;
  }

  .modal-body {
    padding-left: 44px;
    padding-right: 44px;
  }

  .success-img {
    margin-top: -80px;
    margin-left: -70px;
  }

  .title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 50px;
    color: black;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #5a5c63;
    margin-bottom: 43px;
  }
  @media (min-width: 1600px) {
    .modal-dialog {
      max-width: 608px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .modal-dialog {
      max-width: 402px;
    }
    .title {
      font-size: 22px;
      line-height: 44px;
      margin-bottom: 10px;
    }
    .text {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .modal-body {
      padding-left: 16px;
      padding-right: 16px;
    }
    .success-img {
      margin-top: -58px;
      margin-left: -35px;
      width: 300px;
    }
  }
}
