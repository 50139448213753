@use "../../utilities/variables" as var;

.load-more-button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #12aeb4;
  background: #c7ebeb;
  border-radius: 6px;
  width: 150px;
  height: 42px;
  border: none;
  text-align: center !important;
}

.edit-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;

  .btn-cancel {
    background-color: #fff;
    color: #00aeb3;
    border: 1px solid #00aeb3;
    width: 5rem;
  }

  .btn-save {
    background-color: #00aeb3;
    width: 5rem;
  }
}

.link-button {
  font-family: "Poppins", sans-serif;
  color: white;
  background: var.$color-1;
  padding: 3px 6px;
  margin-bottom: 20px;
  font-size: larger;
  font-weight: 600;
  line-height: 25px;
  border-radius: 5px;
  height: 42px;
  border: none;
  text-align: center !important;
  cursor: pointer;
  &:hover {
    color: white;
    background: var.$color-1;
  }
}
