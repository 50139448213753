@use "../../utilities/variables" as var;

.rbc-event {
  background-color: var.$color-1 !important;
  color: var.$color-white; /* White */
}

.rbc-header {
  background-color: var.$color-1;
  color: white;
  text-transform: uppercase;
}