.upcoming-event {
    background: #ffffff;
    border-radius: 24px;
    padding: 16px;
    margin-bottom: 20px;
  }
 .upcoming-event .event-list {
    border-left: 4px solid #12aeb4;
    padding-left: 20px;
    margin-bottom: 24px;
  }
.upcoming-event .events-scroll {
    max-height: 320px;
    overflow-y: scroll;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}
.upcoming-event .events-scroll::-webkit-scrollbar-thumb {
  background: #12aeb4;
  border-radius: 10px;
  height: 50px;
}
.events-scroll::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
.upcoming-event .event-list:last-child {
    margin-bottom: 0px;
  }
.upcoming-event .event-list .event-title {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0a1629;
    margin-bottom: 0px;
  }